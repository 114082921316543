import appImage from "../../Assets/Images/onboarding-mobile-screen.png";
import Image from "../../Components/Image";
import HeroCard from "../../Components/Card/HeroCard.js";
import { useWindowDimensions } from "../../CustomHooks/ScreenH-W";

const OurWorking = ({ statsArray, statsImages, heading }) => {
  const { width, height } = useWindowDimensions();

  // const Feature = ({ icon, text }) => (
  //   <div className=' flex flex-col items-center'>
  //     <div className='relative inline-block'>
  //       <img src={icon} className='h-20 w-20 lg:h-32 lg:w-32' alt='' />
  //       <div className='absolute -top-4 -right-4 '>
  //         <img className='w-10 h-10' alt='tick' src={Verified} />
  //       </div>
  //     </div>
  //     <div className='text-sm lg:text-base 3xl:text-lg font-source-sans-pro mt-8 text-center'>
  //       {text}
  //     </div>
  //   </div>
  // );
  return (
    <div className='bg-230D4B text-white overflow-hidden'>
      <div
        className={`container ${
          width >= 768 ? "w-8/10-center-without-py py-10 sm:py-24" : "mx-auto py-10 sm:py-24"
        }`}>
        <div className='font-open-sans-pro text-center'>
          <div
            className='heading font-semibold'
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        </div>

        <div className='grid grid-cols-5 items-center mt-8 lg:mt-14 gap-16'>
          <div className='col-span-5 lg:hidden w-full'>
            <Image src={appImage} height='h-auto' width='w-8/12 mx-auto' />
          </div>
          <div className='col-span-5 lg:col-span-3'>
            <div
              className={`${
                width >= 768
                  ? "w-8/10-center-without-py"
                  : "w-9/10-left-without-py"
              } flex overflow-without-scrollbar md:grid md:grid-cols-2 col-span-1 my-auto gap-y-12 gap-x-6`}>
              {statsArray.map((item, index) => (
                <div>
                  <div className='w-44 sm:w-52 md:w-auto h-full'>
                    <HeroCard
                      key={index}
                      image={statsImages[index]}
                      description={item}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='hidden lg:block md:col-span-2'>
            <Image src={appImage} height='h-auto' width='w-full mx-auto -mb-40 4xl:-mb-60' />
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurWorking;
