const FeatureCard = ({ icon, heading, description }) => {
  return (
    <div className="lg:h-auto rounded-md border-2 border-gray-300 flex align-center flex-col py-12 px-10">
      <img src={icon} className="h-12" />
      <div className="heading3 font-bold text-center mt-4">{heading}</div>
      <div className="text-sm text-center mt-2 font-source-sans-pro">{description}</div>
    </div>
  );
};

export default FeatureCard;
