import React from "react";
import { Link } from "react-router-dom";
import Aankalan from "../../Assets/Icons/Aankalan.png";
import Image from "../Image";

const Logo = ({ withTagLine = false, color = "FFA200", uppercase = false }) => {
  return (
    <Link  to='/'>
      <div className='flex items-end cursor-pointer'>
        <Image
          src={Aankalan}
          alt='logo'
          height='h-8 3xl:h-12'
          width='w-8 3xl:w-12'
        />
        <div style={{textDecoration:"none"}}
          className={`font-roboto-slab font-bold text-left text-base xl:text-lg 3xl:text-2xl text-${color} ${
            uppercase ? "uppercase" : ""
          } `}>
          ankalan {withTagLine && "- THE ASSESSMENTS"}
        </div>
      </div>
    </Link>
  );
};

export default Logo;
