import React from 'react'

 const RadioButton = (props) => {
    return (
        <div onClick={()=>props.onClick(props.value)}  
        className={`h-12 sm:h-14 3xl:h-16 flex items-center justify-center w-full cursor-pointer rounded-md shadow ${
            props.selectedValue === props.value
              ? "text-black bg-white"
              : "text-707070"
          } hover:bg-white hover:opacity-50`}>
            {props.children}
        </div>
    )
}
export default RadioButton
