import React, { useState } from "react";
import DropDown from "./DropDown";
import SelectDownArrow from "../../Assets/Icons/SelectDownArrow.svg";
import SelectUpArrow from "../../Assets/Icons/SelectUpArrow.svg";
import { handleSelect } from "../../Utility/Helper";

const SelectGroup = ({
  id,
  placeholder,
  children,
  title,
  transparent,
  selectOptionHandler,
  selectedOption,
  info,
  defaultSelect = true,
  allowType,
  dropDownPosition = "left-0 right-0"
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);

  const onOptionSelect = (value) => {
    selectOptionHandler(value);
    setDropdownActive(false);
  };

  return (
    <div>
      {title && (
        <label className='font-soure-sans-pro font-semibold text-base md:text-xl 3xl:text-2xl mb-3'>
          {title}
        </label>
      )}
      <div className='w-full relative cursor-pointer'>
        <div
          id={`selectGroup-${id}`}
          className={`w-full flex items-center justify-between capitalize ${defaultSelect
            ? `h-12 sm:h-14 3xl:h-16 mt-3 ${transparent ? 'bg-FFF5E3' : 'bg-FAFAFA'} shadow font-soure-sans-pro text-base 3xl:text-xl text-707070 px-4 py-2.5 ${dropdownActive ? "rounded-t-md" : "rounded-md"
            }`
            : ""
            } `}
          onClick={(e) => handleSelect(e, dropdownActive, setDropdownActive)}>
          <div>{selectedOption ? selectedOption : placeholder}</div>
          {dropdownActive ? (
            <div>
              <img src={SelectUpArrow} alt='up arrow' />
            </div>
          ) : (
            <div>
              <img src={SelectDownArrow} alt='down arrow' />
            </div>
          )}
        </div>
        {dropdownActive && (
          <DropDown
            leftRightTopBottom={dropDownPosition}
            onOptionSelect={onOptionSelect}
            transparent={transparent}
            selectedOption={selectedOption}
            dropdownActive={dropdownActive}
            allowType={allowType}
            setDropdownActive={setDropdownActive}>
            {children}
          </DropDown>
        )}
      </div>
      {info && <div className="mt-2 text-xs text-707070">*{info}</div>}
    </div>
  );
};

export default SelectGroup;
