import { Avtaar } from "../Avtaar";

export default ({
  index,
  image,
  name,
  date,
  city,
  examTaken,
  scholarship,
  rank,
}) => (
  <div className='flex p-4 font-source-sans-pro bg-white my-1 items-center'>
    <Avtaar image={image} />
    <div className='flex-grow px-4'>
      <div className='text-sm'>{name}</div>
      <div className='text-2xs'>
        {examTaken}-{city}-{date}
      </div>
    </div>
    <div>
      <div className='text-sm font-semibold text-FFA300'>
        &#8377;{scholarship}
      </div>
      <div className='text-2xs '>Rank-{rank}</div>
    </div>
  </div>
);
