import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

const Layout = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log("hello");
    // console.log(history.location.pathname);
  }, [history.location.pathname]);
  return (
    <div>
      <Header />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
