import React from "react";
import Image from "../../Components/Image";
import { useWindowDimensions } from "../../CustomHooks/ScreenH-W";

export const OurGallery = ({ images, heading }) => {
  const { width, height } = useWindowDimensions();

  return (
    <div className='bg-FFFFFF'>
      <div className={`py-10 sm:py-24 `}>
        <div className='heading font-source-sans-pro font-semibold text-center text-black'>
          {heading}
        </div>
        <div
          className={`${
            width >= 768
              ? "w-8/10-center-without-py"
              : "w-9/10-left-without-py "
          } flex overflow-without-scrollbar md:grid md:grid-cols-2 lg:grid-cols-3 gap-6 pt-8 sm:pt-14`}>
          {images.map((item, index) => (
            <div>
              <div className='h-48 xl:h-64 2xl:h-80 w-60 md:w-full gallery-images'>
                <Image
                  key={index}
                  src={item}
                  width='w-full'
                  height='h-full'
                  alt='gallery'
                  position='object-cover'
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
