import React from "react";
import Button from "../Button";
import Image from "../Image";

const Jumbotron = ({
  bgColor,
  heading,
  description,
  headingColor,
  descriptionColor,
  btnText,
  btnColor,
  btnTextColor,
  src,
  alt,
  height,
  width,
  position,
  buttonOnclick,
}) => {
  return (
    <div className={`bg-${bgColor}`}>
      <div className='w-8/10-center flex flex-col items-center space-y-4'>
        {src && (
          <Image
            src={src}
            height={height}
            width={width}
            alt={alt}
            position={position}
          />
        )}
        <div dangerouslySetInnerHTML={{ __html:heading }} className={`  text-center  heading text-${headingColor}`} />
           
        <div dangerouslySetInnerHTML={{ __html:description }} className={`sub-heading text-${descriptionColor} text-center`} />
        <div className='pt-8 lg:pt-10'>
          <Button
            height='h-14'
            width='w-48'
            bgColor={btnColor}
            textColor={btnTextColor}
            textSize='text-sm'
            weight='semibold'
            children={btnText}
            textTransform='uppercase'
            onClick={buttonOnclick}
          />
        </div>
      </div>
    </div>
  );
};

export default Jumbotron;
