import React, { useContext, useState } from "react";
import { ProfileContext } from "../Context/ProfileContext";
import { ProfileService } from "../Services/profile.service";
import { validPhone } from "../Utility/Helper";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import loadingIcon from "../Assets/Icons/Rolling.svg";
import Modal from "../Components/Modal";
import { Link } from "react-router-dom";

const PhoneInput = ({
  title,
  onChange = () => console.warn("Mention onChange"),
  value,
  placeholder,
  transparent,
  large,
  sendOTP,
  disabled,
}) => {
  const history = useHistory();

  const { verifiedPhoneNo, setPhoneNumber } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const [showSendOtp, setShowSendOtp] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState();

  const checkOnChange = (value) => {
    if (value.length < 11) {
      onChange(value);
      setShowSendOtp(false);
    }
    if (value.length == 10) {
      setLoading(true);
      ProfileService.validateUser(value)
        .then((res) => {
          // old user
          console.log(res);
          setLoading(false);
          setShowModal(true);
          setUserData(res);
          // history.push('/create-contest')
        })
        .catch((err) => {
          if (err === "No teacher record found with phone number") {
            setLoading(false);
            setShowSendOtp(true);
          }
        });
    }
  };

  const redirectHome = () => {
    setPhoneNumber("");
    history.replace("/home");
  };

  return (
    <div>
      {title && (
        <div
          className={`${
            large
              ? "text-base md:text-3xl 3xl:text-4xl text-9A9A9A mb-1"
              : "font-semibold text-base md:text-xl 3xl:text-2xl mb-3"
          }  `}>
          {title}
        </div>
      )}
      <div className='flex flex-col sm:flex-row'>
        <div className='flex'>
          <input
            value='+91'
            disabled
            className={`${
              large
                ? "text-4xl lg:text-5xl 3xl:text-6xl w-16 lg:w-20 3xl:w-24 px-1"
                : "3xl:text-xl w-14 px-3"
            }  ${
              transparent
                ? "bg-transparent text-black"
                : "bg-FAFAFA border-t border-l border-b"
            } border-D3D0D0 focus:outline-none  focus:border-461995 rounded-l-md h-12 sm:h-14 3xl:h-16 `}
          />
          <input
            className={`
          ${large ? "text-4xl lg:text-5xl 3xl:text-6xl" : "3xl:text-xl"} 
          w-full h-12 sm:h-14 3xl:h-16 
          ${
            transparent
              ? "bg-transparent text-black"
              : "bg-FAFAFA border-t border-r border-l border-b"
          }   
          focus:outline-none  focus:border-461995 border-D3D0D0 rounded-r-md px-4`}
            onChange={(e) => checkOnChange(e.target.value)}
            autoFocus={true}
            value={value}
            disabled={verifiedPhoneNo || disabled}
            placeholder={placeholder}
          />
        </div>
        {loading && (
          <span className='h-14 w-14 rounded-full flex-centerXY shadow cursor-pointer'>
            <img src={loadingIcon} className='h-9 w-auto' alt='loader' />
          </span>
        )}
        {sendOTP && showSendOtp && validPhone(value) && !verifiedPhoneNo && (
          <div className='flex space-x-3'>
            <button id='send-otp'
                onClick={sendOTP}
                 className='h-14 w-14 rounded-full flex-centerXY shadow cursor-pointer  focus:bg-FDEFD5 focus:ring-1 focus:ring-black'>
              <ion-icon
                
                style={{ color: "#39177E", fontSize: "30px" }}
                name='checkmark-outline'></ion-icon>
            </button>
            <button
              onClick={!disabled ? () => onChange("") : () => {}}
              className='h-14 w-14 rounded-full flex-centerXY shadow cursor-pointer focus:bg-FDEFD5 focus:ring-1 focus:ring-black'>
              <ion-icon
                style={{ color: "black", fontSize: "30px" }}
                name='close-outline'></ion-icon>
            </button>
          </div>
        )}
      </div>
      <Modal
        visible={showModal}
        isClosable={false}
        width='w-11/12 md:w-6/12 4xl:w-5/12'>
        <div className='font-open-sans-pro py-8 px-8 sm:py-8 sm:px-16  md:py-10 md:px-16'>
          <div className='text-3xl font-semibold mb-2'>Already Registered</div>
          <hr />
          <div className='font-semibold capitalize mt-4'>
            Hey {userData?.name},
          </div>
          <div className='mt-2'>{userData?.message}</div>
          <div className='mt-8'>
            <div onClick={redirectHome}>
              <button className='block border bg-FFA200 rounded-md font-source-sans-pro text-base xl:text-lg text-center text-black h-10 lg:h-12 w-40 xl:w-44'>
                Home
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default PhoneInput;
