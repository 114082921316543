import React, { useContext, useState } from "react";
import { useEffect } from "react";
import SelectGroup from "../../../../Components/CustomSelect/SelectGroup";
import SelectOption from "../../../../Components/CustomSelect/SelectOption";
import { ProfileContext } from "../../../../Context/ProfileContext";
import { ProfileService } from "../../../../Services/profile.service";
import toast from "react-hot-toast";

// const stateArray = ['UP', 'Delhi']
// const cityArray = ['Gzb', 'Noida']

const AddressDetails = () => {
  const { city, setCity, state, setState } = useContext(ProfileContext);
  const [stateArray, setStateArray] = useState([]);
  const [cityArray, setcityArray] = useState([]);
  useEffect(() => {
    console.log("useEffect running");
    ProfileService.getStates()
      .then((res) => {
        console.log("states called");
        setStateArray(res);
        if (state) {
          setCities();
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  }, []);
  useEffect(() => {
    setCities();
  }, [state]);

  const setCities = () => {
    console.log(stateArray);
    if (state) {
      let selectedState = stateArray.find(
        (stateData) => stateData.name === state
      );
      ProfileService.getCities(selectedState?.id)
        .then((res) => {
          setcityArray(res);
        })
        .catch((err) => {
          console.log("Expertise", err);
          toast.error("Something went wrong");
        });
    }
  };

  const selectState = (state) => {
    setCity("");
    setState(state);
    setcityArray([]);
  };

  return (
    <div>
      <SelectGroup
        id='state'
        placeholder='Select Your state here '
        title='Select your state'
        selectedOption={state}
        transparent
        selectOptionHandler={selectState}>
        {stateArray.map((state) => (
          <SelectOption key={state.id} value={state.name}>
            {state.name}
          </SelectOption>
        ))}
      </SelectGroup>
      <div className='mt-14'></div>
      <SelectGroup
        id='city'
        placeholder='Select Your district here '
        title='Select your city'
        selectedOption={city}
        transparent
        selectOptionHandler={setCity}
        dropDownPosition='-bottom-10 sm:bottom-0 left-4 sm:left-24 w-full'>
        {cityArray.map((city) => (
          <SelectOption key={city.id} value={city.name}>
            {city.name}
          </SelectOption>
        ))}
      </SelectGroup>
    </div>
  );
};
export default AddressDetails;
