import React, { useContext, useState , useEffect} from "react";
import Input from "../../../../Components/Input";
import { ProfileContext } from "../../../../Context/ProfileContext";
import { getAnalytics, logEvent } from "firebase/analytics";


const BankDetails = () => {

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'CREATE_PROFILE_BANK_DETAILS', {
      landed: "true"
    });
  }, [])

  const {
    accHolderName,
    setAccHolderName,
    accNumber,
    setaccNumber,
    IFSC,
    setIFSC,
    bankName,
    setBankName,
    branchName,
    setBranchName,
    panCard,
    setPanCard,
    upiID,
    setUpiID,
    step,
    bankState,
    setBankState,
  } = useContext(ProfileContext);


  const convertToUpperCase=(text)=>{
    setPanCard(text.toUpperCase())
  }

  return (
    <div>
      {/* {getComponent()} */}

      {bankState === "BANK" && (
        <div className='grid grid-cols-2 gap-6 font-source-sans-pro'>
          <div className='col-span-1'>
            <Input
              title=' Bank Name'
              placeholder='enter Bank name'
              medium
              transparent
              value={bankName}
              onChange={setBankName}
            />
          </div>
          <div className='col-span-1'>
            <Input
              title=' Branch Name'
              placeholder='enter branch name'
              medium
              transparent
              value={branchName}
              onChange={setBranchName}
            />
          </div>

          <Input
            title='Account Number'
            placeholder='enter account no. '
            medium
            transparent
            value={accNumber}
            onChange={setaccNumber}
          />
          <Input
            title='IFSC Code'
            placeholder='enter IFSC code '
            medium
            transparent
            value={IFSC}
            onChange={setIFSC}
          />
          <div className='col-span-2'>
            <Input
              title='Account Holder Name'
              placeholder="enter account holder's name "
              medium
              transparent
              value={accHolderName}
              onChange={setAccHolderName}
            />
          </div>
          <div className='text-sm sm:text-xl col-span-2'>
            Enter your{" "}
            <span onClick={() => setBankState("UPI_PAN")} className='underline cursor-pointer '>
              UPI details
            </span>
          </div>
        </div>
      )}

      {bankState === "UPI_PAN" && (
        <div className='grid grid-col-1 sm:grid-cols-2 gap-6'>
          <div>
            <Input
              title='Enter your UPI ID'
              placeholder='enter upi-id'
              medium
              transparent
              value={upiID}
              onChange={setUpiID}
            />
            <div className='mt-6'></div>
            <Input
              title='Enter your PAN'
              placeholder='enter pan number'
              medium
              transparent
              value={panCard}
              onChange={convertToUpperCase}
            />
          </div>
          <div>
            <div className='text-B1B1B1'>I prefer adding bank account details!</div>
            <div className='text-sm sm:text-xl '>
              <span onClick={() => setBankState("BANK")} className='underline cursor-pointer'>
                Enter your Bank Details {" "}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default BankDetails;
