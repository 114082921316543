import React, { useContext } from "react";
import { ProfileContext } from "../../../Context/ProfileContext";
import InActiveProfile from "../../../Assets/Icons/StepperIcons/InActiveProfile.svg";
import InActiveContact from "../../../Assets/Icons/StepperIcons/InActiveContact.svg";
import InActiveLocation from "../../../Assets/Icons/StepperIcons/InActiveLocation.svg";
import InActiveDollar from "../../../Assets/Icons/StepperIcons/InActiveDollar.svg";
import ActiveProfile from "../../../Assets/Icons/StepperIcons/ActiveProfile.svg";
import ActiveContact from "../../../Assets/Icons/StepperIcons/ActiveContact.svg";
import ActiveLocation from "../../../Assets/Icons/StepperIcons/ActiveLocation.svg";
import ActiveDollar from "../../../Assets/Icons/StepperIcons/ActiveDollar.svg";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { handleBackNavigation } from "./helper";

const Navigator = () => {
  const { stage, setStage, step, setStep, data } = useContext(ProfileContext);
  const history = useHistory();
  const imagesArray = {
    1: {
      inActive: InActiveProfile,
      active: ActiveProfile,
    },
    2: {
      inActive: InActiveContact,
      active: ActiveContact,
    },
    3: {
      inActive: InActiveLocation,
      active: ActiveLocation,
    },
    4: {
      inActive: InActiveDollar,
      active: ActiveDollar,
    },
  };
  const stageList = Object.keys(data).map((str) => parseInt(str, 10));

  const getTotalSteps = (stageNumber) => {
    let stepList = Object.keys(data[stageNumber].steps).map((str) =>
      parseInt(str, 10)
    );
    return stepList.slice(-1);
  };

  const isStageChangeValid = (stageNumber) => {
    console.log(stage, stageNumber);
    if (stage < stageNumber) {
      return toast.error("Fill details to proceed further");
    }
    setStage(stageNumber);
    setStep(1)
  };

  return (
    <div className='flex lg:space-x-4'>
      <div
        className='hidden lg:flex items-center cursor-pointer'
        onClick={() =>
          handleBackNavigation(stage, setStage, step, setStep, history)
        }>
        <ion-icon
          name='arrow-back-circle-outline'
          style={{ color: "#000000", fontSize: "36px" }}></ion-icon>
      </div>
      <div className='w-80 sm:w-96 3xl:w-112 p-4 flex items-center justify-between bg-FAFAFA border-EAEAEA shadow-md rounded-md'>
        {stageList.map((stageNumber, index) => (
          <div
            className={`flex flex-grow  justify-center sub-heading font-semibold ${
              index !== 3 ? "border-r-2 border-EAEAEA" : ""
            }`}
            onClick={() => isStageChangeValid(stageNumber)}>
            {stageNumber === stage ? (
              <div className='flex items-center space-x-2'>
                <div className=''>
                  <img
                    src={imagesArray[stage].active}
                    alt='progress'
                    height='h-10'
                    width='w-10'
                  />
                </div>
                <div className='flex flex-col'>
                  <div className='text-xs text-C1C1C1'>
                    Step {step + "/" + getTotalSteps(stageNumber)}
                    <div className='sub-heading font-semibold text-black'>
                      {data[stageNumber].stageName}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className=''>
                <img
                  src={imagesArray[stageNumber].inActive}
                  alt='progress'
                  height='h-10'
                  width='w-10'
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Navigator;
