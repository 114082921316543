import React from "react";

export default function Modal({
  visible,
  isClosable = true,
  close,
  width = "w-1/2",
  children,
}) {
  return (
    <div className={`${visible ? "block" : "hidden"}`}>
      <div onClick={close} className='fixed w-full h-full z-50 left-0 top-0 bg-black opacity-50'></div>
      <div
        className={`absolute top-20 left-1/2 transform -translate-x-1/2 ${width} z-50`}>
        <div
          className={`bg-white overflow-hidden rounded-xl shadow-xl text-gray-800 relative`}>
          {isClosable && (
            <div className='absolute top-4 right-4'>
              <svg
                onClick={close}
                className='h-8 w-8 cursor-pointer px-0.5 py-1 hover:text-black text-707070 rounded-full'
                id='close-modal'
                fill='currentColor'
                viewBox='0 0 20 20'>
                <path
                  fill-rule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clip-rule='evenodd'></path>
              </svg>
            </div>
          )}
          {visible && children}
        </div>
      </div>
    </div>
  );
}
