export default ({ bgcolor, text, stat, type, image, textColor, border }) => {
  return (
    <div
      className={`${bgcolor} h-full p-6 sm:p-10  font-source-sans-pro ${textColor} text-center rounded ${
        border ? border : ""
      }`}>
      <img src={image} className='mx-auto h-20 w-20' />
      <div className='lg:text-xl mt-6 capitalize'>{text}</div>
      {stat && <div className='text-2xl lg:text-4xl mt-1 font-bold'>{stat}</div>}
      <div className='lg:text-xl mt-1 uppercase'>{type}</div>
    </div>
  );
};
