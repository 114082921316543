import React, { useEffect, useState } from "react";
import Button from "./Button";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router";

import RightArrow from "../Assets/Icons/SmallArrow.svg";
import HamBurgerMenu from "../Assets/Icons/HamBurgerMenu.svg";
import Modal from "./Modal";
import ContactUs from "../Containers/Modals/ContactUs";
import YellowArrowUp from "../Assets/Icons/YellowArrowUp.svg";
import YellowArrowDown from "../Assets/Icons/YellowArrowDown.svg";
import WhiteDownArrow from "../Assets/Icons/WhiteDownArrow.svg";
import WhiteUpArrow from "../Assets/Icons/WhiteUpArrow.svg";
import DropDown from "./CustomSelect/DropDown";
import SelectOption from "./CustomSelect/SelectOption";
// import AankalanData from "../Data/Aankalan.json";
import { handleSelect, isLinkVisible } from "../Utility/Helper";
import Logo from "./Logo/Logo";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../Store/Actions";

let AankalanData = require('../Data/Aankalan.json')

const Header = () => {
  const { userType } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const userTypes = AankalanData["userTypes"];
  const users = Object.keys(AankalanData["userTypes"]);

  const history = useHistory();
  const [showContactModal, setShowContactModal] = useState(false);

  const [selectedUserRoute, setSelectedUserRoute] = useState(
    history.location.pathname.slice(1)
  );

  const [currentUser, setCurrentUser] = useState();
  const [dropdownActive, setDropdownActive] = useState(false);
  const [showSideNav, setShowSideNav] = useState(false);

  const onOptionSelect = (value) => {
    let val = value.replace(/ /g, "").toLowerCase();
    setSelectedUserRoute(val);
    setCurrentUser(userTypes[val]);
    history.replace("/" + val);
    setDropdownActive(false);
  };

  useEffect(() => {
    if (userTypes[selectedUserRoute]) {
      setCurrentUser(userTypes[selectedUserRoute]);
    } else if (selectedUserRoute === "register-student") {
      setCurrentUser(userTypes["students"]);
    } else if (selectedUserRoute === "register-teacher") {
      setCurrentUser(userTypes["teachers"]);
    } else setCurrentUser(userTypes["students"]);

    // Add logic if any pre set user type
    updateUserType(selectedUserRoute);
  }, [history.location.pathname]);

  const updateUserType = (val) => {
    let user;
    switch (val) {
      case "aankalan":
      case "home": {
        user = "AANKALAN";
        break;
      }
      case "students": {
        user = "STUDENTS";
        break;
      }
      case "teachers": {
        user = "TEACHERS";
        break;
      }
      case "coachinginstitutions": {
        user = "COACHINGINSTITUTIONS";
        break;
      }
      case "parents": {
        user = "PARENTS";
        break;
      }
      case "register-student": {
        user = "STUDENT";
        break;
      }
      case "register-teacher": {
        user = "TEACHER";
        break;
      }
      default: {
        user = "STUDENT";
        break;
      }
    }
    dispatch(userActions.updateUser({ userType: user }));
  };

  const getStatusOfTab = () => {
    return userType.toLowerCase() === history.location.pathname.slice(1);
  };

  return (
    <React.Fragment>
      <div className='fixed z-30 top-0 left-0 right-0  bg-230D4B py-6 md:py-6 4xl:py-10   border-b border-FFFFFF lg:border-0  '>
        <div className='flex items-end justify-between w-full px-9  sm:px-16 xl:px-28 2xl:px-36 3xl:px-44'>
          <Logo />
          <div className='hidden lg:flex font-source-sans-pro text-FFFFFF text-base xl:text-lg 3xl:text-2xl items-center lg:space-x-3 xl:space-x-4  2xl:space-x-8 3xl:space-x-7 4xl:space-x-8'>
            <div
              onClick={() => {
                setSelectedUserRoute("aankalan");
                updateUserType("aankalan");
              }}>
              <NavLink
                activeClassName='font-semibold text-FFA300 capitalize'
                to='/home'>
                Home
              </NavLink>
            </div>
            <div>|</div>
            <div>Aankalan for </div>
            <div>
              <img
                src={RightArrow}
                alt='Arrow'
                className='h-2 w-2 xl:h-3 xl:w-3'
              />
            </div>
            <div>
              <div
                className={`flex space-x-2 items-center ${
                  getStatusOfTab() ? "text-FFA300 font-semibold" : ""
                }  cursor-pointer capitalize`}
                onClick={(e) =>
                  handleSelect(e, dropdownActive, setDropdownActive)
                }>
                <div id='customSelect' to={`/${selectedUserRoute}`}>
                  {getStatusOfTab() ? currentUser : "Students"}
                </div>
                <div className='h-3 w-3'>
                  {dropdownActive ? (
                    getStatusOfTab() ? (
                      <img
                        src={YellowArrowUp}
                        alt='up arrow'
                        className='h-full w-full'
                      />
                    ) : (
                      <img
                        src={WhiteUpArrow}
                        alt='up arrow'
                        className='h-full w-full'
                      />
                    )
                  ) : getStatusOfTab() ? (
                    <img
                      src={YellowArrowDown}
                      alt='down arrow'
                      className='h-full w-full'
                    />
                  ) : (
                    <img
                      src={WhiteDownArrow}
                      alt='up arrow'
                      className='h-full w-full'
                    />
                  )}
                </div>
              </div>
              <div className='text-black'>
                {dropdownActive && (
                  <DropDown
                    onOptionSelect={onOptionSelect}
                    selectedOption={userTypes[selectedUserRoute]}
                    dropdownActive={dropdownActive}
                    setDropdownActive={setDropdownActive}>
                    {users.slice(0, users.length - 2).map((user, index) => (
                      <SelectOption value={userTypes[user]}>
                        {userTypes[user]}
                      </SelectOption>
                    ))}
                  </DropDown>
                )}
              </div>
            </div>
            <div>|</div>
            <div
              onClick={() => onOptionSelect("contact-us")}
              className='cursor-pointer capitalize'>
              Contact Us
            </div>
          </div>
          <div className='flex space-x-2 xl:space-x-4'>
            {/* {isLinkVisible("LOGIN", selectedUserRoute) ? (
              <div className='hidden lg:block'>
                <Button
                  bgColor='white'
                  width='w-20 xl:w-24'
                  height='h-9 lg:h-10'
                  textColor='000000'
                  textSize='text-base xl:text-lg'
                  weight='normal'
                  onClick={() => history.push("/login")}>
                  Login
                </Button>
              </div>
            ) : (
              <div className='hidden lg:block h-9 lg:h-10 w-20 xl:w-24'></div>
            )} */}
            <Link to='create-contest'>
              <button className='hidden lg:block border border-FFA200 rounded-md font-source-sans-pro text-base xl:text-lg text-center text-FFA200 h-9 lg:h-10 w-40 xl:w-44'>
                Create Your Contest
              </button>
            </Link>
            <Link to='create-test-series'>
              <button className='hidden lg:block border border-FFA200 rounded-md font-source-sans-pro text-base xl:text-lg text-center text-FFA200 h-9 lg:h-10 w-40 xl:w-44'>
                Submit Test Series
              </button>
            </Link>
          </div>
          <div
            className='block lg:hidden h-5 w-5 cursor-pointer'
            onClick={() => setShowSideNav(!showSideNav)}>
            <img
              src={HamBurgerMenu}
              alt='side menu'
              className='h-full w-full'
            />
          </div>
        </div>
      </div>
      <Modal
        visible={showContactModal}
        close={() => setShowContactModal(false)}
        width='w-11/12 md:w-10/12'>
        <ContactUs close={() => setShowContactModal(false)} />
      </Modal>
      <SideNav visible={showSideNav} close={() => setShowSideNav(false)} />
    </React.Fragment>
  );
};
export default Header;
