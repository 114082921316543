import React, { useState } from "react";

const RadioGroup = (props) => {
  const [state, setState] = useState("abc");
  return (
    <div className=''>
      {props.title && (
        <div className={`font-soure-sans-pro ${
          props.large?'text-base md:text-3xl 3xl:text-4xl text-9A9A9A mb-1':'font-semibold  text-base md:text-xl 3xl:text-2xl mb-3'
          }`}>
          {props.title}
        </div>
      )}
      <div className='flex space-x-4 sm:space-x-8  overflow-without-scrollbar py-1'>
        {React.Children.map(props.children, (child) =>
          React.cloneElement(child, {
            onClick: props.onClick,
            selectedValue: props.selectedValue,
          })
        )}
      </div>
    </div>
  );
};
export default RadioGroup;
