import urls, { TOKEN } from "../Urls";
import { handleResponse } from "../Utility/Helper";

const validateTeacherCode = (teacherCode) => {
  const requestOptions = {
    method: "GET",
    headers: { Token: TOKEN },
  };
  return fetch(
    urls["validateTeacherCode"] + "?teacher_code=" + teacherCode,
    requestOptions
  ).then(handleResponse);
};

const validateUser = (phone)=>{
  const requestOptions = {
    method: "GET",
    headers: { Token: TOKEN },
  }
  return fetch(
    urls["validatePhone"] + "?phone=" + phone,
    requestOptions
  ).then(handleResponse);
}

const saveTeacherDetails = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { Token: TOKEN, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(urls["saveTeacher"], requestOptions).then(handleResponse);
};

const saveContestDetails = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { Token: TOKEN, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(urls["saveContest"], requestOptions).then(handleResponse);
};

const getCoreExpertise = () => {
  const requestOptions = {
    method: "GET",
    headers: { Token: TOKEN },
  };
  return fetch(urls["coreExpertise"], requestOptions).then(handleResponse);
};

const getStates = () => {
  const requestOptions = {
    method: "GET",
    headers: { Token: TOKEN },
  };
  return fetch(urls["states"], requestOptions).then(handleResponse);
};

const getCities = (stateId) => {
  const requestOptions = {
    method: "GET",
    headers: { Token: TOKEN },
  };
  return fetch(urls["cities"] + "?state_id=" + stateId, requestOptions).then(
    handleResponse
  );
};

const getSubjects = () => {
  const requestOptions = {
    method: "GET",
    headers: { Token: TOKEN },
  };
  return fetch(urls["subjects"], requestOptions).then(handleResponse);
};

const getExams = () => {
  const requestOptions = {
    method: "GET",
    headers: { Token: TOKEN },
  };
  return fetch(urls["examination"], requestOptions).then(handleResponse);
};

const uploadFile = (data) => {
  console.log(data.get('file'))
  const requestOptions = {
    method: "POST",
    headers: {
      Token: TOKEN,
      // "Content-Type": "multipart/form-data"
    },
    body: data,
  };
  return fetch(urls["uploadFile"], requestOptions).then(handleResponse);
}
export const ProfileService = {
  validateTeacherCode,
  saveTeacherDetails,
  saveContestDetails,
  getCoreExpertise,
  getSubjects,
  getStates,
  getCities,
  getExams,
  uploadFile,
  validateUser
};
