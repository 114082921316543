import React from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../Components/Button";
import WordDownload from "../../Assets/Images/WordDownload.svg";
import PdfDownload from "../../Assets/Images/PdfDownload.svg";
import ExcelDownload from "../../Assets/Images/ExcelDownload.svg";
import Lady from "../../Assets/Images/lady-image.png";
import WordFormat from "../../Assets/Files/Test Series - WORD Format.docx"
import PdfFormat from "../../Assets/Files/Test Series - PDF Format.pdf"
import ExcelFormat from "../../Assets/Files/Excel Format ver 1.0.xlsx"


export const SubmitTestSeries = () => {
  const history = useHistory();
  return (
    <div className='mt-16 lg:mt-20 3xl:mt-24 create-test-series'>
      <div className='w-8/10-center relative '>
        <div className='absolute top-10 sm:top-24 left-0'>
          <Link to='/create-test-series'>
            <i class='fas fa-arrow-left text-white text-3xl'></i>
          </Link>
        </div>
        <div className='mx-auto  xl:w-9/12'>
          <div className='text-left font-source-sans-pro capitalize'>
            <div className='text-FFA300 text-xl lg:text-4xl 4xl:text-5xl ml-12 lg:ml-0'>
              Submit Test Series
            </div>
          </div>
        </div>
        <div className='mx-auto  xl:w-9/12 shadow px-6 md:px-16 lg:px-24 py-8 sm:py-20 mt-6 sm:mt-12 bg-white rounded-md'>
          <div className='font-open-sans-pro'>
            <div className='text-3xl font-bold'>Mail Us Your Test Series</div>
            <div className='bg-7AAFB5 p-6 sm:p-8 mt-8 sm:mt-20 bg-opacity-30'>
              <div className='grid grid-cols-10'>
                <div className='hidden sm:block col-span-4'>
                  <img src={Lady} className='w-full h-auto -mt-20' />
                </div>
                <div className='col-span-10 sm:col-span-6 sm:pl-8 self-center'>
                  <div>
                    Send us your test series in following format at
                    <br />
                    <span className='font-bold'>test@aankalan.app</span>
                  </div>
                  <div className='grid  sm:grid-cols-2 gap-1 sm:gap-4 mt-4'>



                    <a href={WordFormat} download>
                      <img src={WordDownload} className='w-full h-auto rounded' />
                    </a>
                    <a href={PdfFormat} download>
                      <img src={PdfDownload} className='w-full h-auto rounded' />
                    </a>
                    <a href={ExcelFormat} download>
                      <img
                        src={ExcelDownload}
                        className='w-full h-auto rounded'
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className='mt-4'>
                <div className='font-semibold'>
                  Basic rules of Test Series submission
                </div>
              </div>

              <div className='text-sm sm:text-lg mt-4'>
                <div>1. Every test series must have minimum 10 test papers</div>
                <div>2. Every test paper must have minimum 50 questions</div>
                <div>
                  3. Topic wise test papers are always in demand, so we would
                  prefer topic wise test papers
                </div>
                <div>
                  4. Please Keep Subject Of Your Mail To Us With Your Test
                  Series As <br />
                  <span className='font-bold'>
                    [ Your Name - Test Series Name - Total Questions - Expected
                    Fee/Test Series ]
                  </span>
                </div>
                <div>[ eg - SAGAR SINGH - IIT JEE - 1500 - RS.399/- ]</div>
              </div>
            </div>
            <div className='mt-10'>
              <Button
                onClick={() => history.push("/create-test-series")}
                width='w-48'
                textTransform='uppercase'>
                BACK TO HOME SCREEN
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
