import React from "react";
import TimePicker from "react-time-picker";

const TimeInput = ({ setTime, time }) => {
  return (
    <div>
      <TimePicker
        onChange={setTime}
        value={time}
        format='hh:mm a'
        className='time-picker'
      />
    </div>
  );
};

export default TimeInput;
