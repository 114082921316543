import React, { useState } from "react";
import Brain1 from "../../../../Assets/Icons/Brain-Text.svg";
import Performance from "../../../../Assets/Icons/Performance.png";
import Cognitive from "../../../../Assets/Icons/Cognitive.png";
import ML from "../../../../Assets/Icons/Machine-learning.svg";

const Step2 = ({ description, data, stepDescription }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabData = (index) => (
    <div className='grid grid-cols-4 mt-4 lg:mt-16'>
      <div className='col-span-3 px-3 font-roboto-slab'>
        <div className='text-xs sm:text-sm lg:text-xl'>
          {data.tabs[index].description}
        </div>
        <div className='text-xs lg:text-xl mt-6 lg:mt-9'>
          <div className='font-bold'>Advantages :</div>
          <div className='lg:mt-4 mt-2'>
            {data.tabs[index].advantages.map((advantage) => (
              <div className='text-xs sm:text-sm lg:text-base mt-1 flex items-start'>
                <i class='fas fa-check-circle mr-2 text-461995'></i>
                {advantage}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className='bg-FAFAFA '>
      {/* for desktop------------------------------------------------------------------------------------------------ */}
      <div className='w-8/10-center hidden lg:block'>
        <div className='heading3 text-center lg:w-1/2 mx-auto '>
          {description}
        </div>
        <div className=' bg-white p-10 mt-12'>
          <div className=' text-center'>
            <div className='heading'>{data.heading}</div>
            <div className='sub-heading'>{data.description}</div>
          </div>
          <div className='flex justify-arround space-x-4 mt-8 overflow-without-scrollbar'>
            <div
              onClick={() => setSelectedTab(0)}
              className={`${
                selectedTab === 0
                  ? "border-b-2 border-461995"
                  : "border-2 border-E1E1E1 opacity-30"
              } flex p-2 items-center rounded cursor-pointer `}>
              <img src={Brain1} />
              <div className='font-open-sans-pro text-base font-bold ml-2'>
                Physcometric Test
              </div>
            </div>
            <div
              onClick={() => setSelectedTab(1)}
              className={`${
                selectedTab === 1
                  ? "border-b-2 border-461995"
                  : "border-2 border-E1E1E1 opacity-30"
              } flex p-2 items-center rounded cursor-pointer `}>
              <img src={Performance} />
              <div className='font-open-sans-pro text-base font-bold ml-2'>
                Personalized Analysis
              </div>
            </div>
            <div
              onClick={() => setSelectedTab(2)}
              className={`${
                selectedTab === 2
                  ? "border-b-2 border-461995"
                  : "border-2 border-E1E1E1 opacity-30"
              } flex p-2 items-center rounded cursor-pointer `}>
              <img src={Cognitive} />
              <div className='font-open-sans-pro text-base font-bold ml-2'>
                Cognitive Psychology
              </div>
            </div>
            <div
              onClick={() => setSelectedTab(3)}
              className={`${
                selectedTab === 3
                  ? "border-b-2 border-461995"
                  : "border-2 border-E1E1E1 opacity-30"
              } flex p-2 items-center rounded cursor-pointer `}>
              <img src={ML} />
              <div className='font-open-sans-pro text-base font-bold ml-2'>
                Data science
              </div>
            </div>
          </div>
          <div className=''>{tabData(selectedTab)}</div>
        </div>
      </div>
      {/* for phone ------------------------------------------------------------------------------------------------  */}
      <div className=' block lg:hidden'>
        <div className='w-8/10-center'>
          <div className='heading3 border-b-2 border-E1E1E1 pb-4'>
            {stepDescription}
          </div>
          <div className='heading3 mt-4'>{description}</div>
        </div>
        {data.tabs.map((data, index) => (
          <div className=' bg-white px-9 py-9 my-2'>
            <div
              onClick={() => setSelectedTab(index)}
              className='flex items-center justify-between'>
              <div className='flex items-center'>
                <img src={Brain1} />
                <div className='font-open-sans-pro text-base font-bold ml-3 text-461995'>
                  {data.name}
                </div>
              </div>
              {index === selectedTab ? (
                <i class='fas fa-chevron-up sm:text-2xl'></i>
              ) : (
                <i class='fas fa-chevron-down sm:text-2xl'></i>
              )}
            </div>
            <div>{index === selectedTab && tabData(index)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Step2;
