import React from "react";
import toast from "react-hot-toast";

const ProgressContainer = ({ stage, onChange }) => {
  const navigate = (stageNumber) => {
    if (stage < stageNumber) {
      return toast.error("Fill details to proceed further");
    }
    onChange(stageNumber);
  };

  const step = (index, text) => {
    let selected = false;
    if (index <= stage) selected = true;

    return (
      <div
        class={`flex items-center ${
          selected ? "text-white" : "text-purple-500"
        } relative `}>
        <div
          onClick={() => navigate(index)}
          class={`flex-centerXY ${
            selected ? "bg-461995" : "bg-white"
          }  rounded-full transition duration-500 ease-in-out h-12 w-12 border-2 ${
            selected ? "border-white" : "border-purple-500"
          } cursor-pointer`}>
          <div>{index + 1}</div>
        </div>
        <div
          class={`absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium capitalize ${
            index === stage ? "text-461995" : "text-black"
          } font-bold`}>
          {text}
        </div>
      </div>
    );
  };
  return (
    <div className=''>
      <div className=''>
        <div class='flex items-center w-full md:w-2/3 lg:w-1/2 mx-auto text-center'>
          {step(0, "Enter Details")}
          <div
            class={` flex-auto  border-t-2  transition  duration-500  ease-in-out  ${
              stage === 0 ? "border-gray-300" : "border-purple-500"
            } `}></div>
          {step(1, "Add Question")}
          <div
            class={`flex-auto   border-t-2   transition   duration-500   ease-in-out ${
              stage === 2 ? "border-purple-500" : "border-gray-300"
            }`}></div>
          {step(2, "Review Question")}
        </div>
      </div>
    </div>
  );
};

export default ProgressContainer;
