import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import BasicDetails from "./CreateProfileForm/BasicDetails";
import { ProfileContext } from "../../../Context/ProfileContext";
import Navigator from "./Navigator";
import DownArrowBlack from "../../../Assets/Icons/StepperIcons/DownArrowBlack.svg";
import ContactDetails from "./CreateProfileForm/ContactDetails";
import AddressDetails from "./CreateProfileForm/AddressDetails";
import BankDetails from "./CreateProfileForm/BankDetails";
import { handleBackNavigation, ShowNextButton } from "./helper";
import Logo from "../../../Components/Logo/Logo";

const Stepper = () => {
  const {
    stage,
    setStage,
    step,
    setStep,
    setShowAgreementModal,
    setShowInteraction,
  } = useContext(ProfileContext);

  const [routes, setRoutes] = useState({
    setUpProfileLater: "/create-contest",
  });

  const history = useHistory();

  useEffect(() => {
    let route = history.location.pathname;
    if (route === "/create-test-series/profile") {
      setRoutes({
        setUpProfileLater: "/create-test-series",
      });
    }
  }, []);

  const getComponentStageWise = () => {
    // Returns component on basis of step and stage
    // Eg: case 1: retrun ;
    switch (stage) {
      case 1:
        return <ContactDetails />;
      case 2:
        return <BasicDetails />;
      case 3:
        return <AddressDetails />;
      case 4:
        return <BankDetails />;
      default:
        return <div>No Component</div>;
    }
  };

  const next = () => {
    switch (stage) {
      case 1:
        setStage(2);
        setShowInteraction(true);
        break;
      case 2:
        step < 5 ? setStep(step + 1) : updateStage();
        setShowInteraction(true);
        break;
      case 3:
        setStage(4);
        setShowInteraction(true);
        break;
      case 4:
        showAgreement();
        break;
    }
  };
  const updateStage = () => {
    setStage(3);
    setStep(1);
  };

  const showAgreement = () => {
    setShowAgreementModal(true);
  };

  return (
    <div className='h-calc-screen px-6 sm:px-16 py-10 space-y-4'>
      <div className='flex lg:hidden items-center space-x-4'>
        <div
          className='flex items-center cursor-pointer'
          onClick={() =>
            handleBackNavigation(stage, setStage, step, setStep, history)
          }>
          <ion-icon
            name='arrow-back-circle-outline'
            style={{ color: "#000000", fontSize: "32px" }}></ion-icon>
        </div>
        <div className='block lg:hidden font-source-sans-pro font-semibold text-lg'>
          CREATE YOUR PROFILE
        </div>
      </div>
      <div className='h-full flex flex-col justify-between'>
        <Navigator />
        <div className='w-full xl:w-10/12'>{getComponentStageWise()}</div>
        <div className='flex justify-between items-center'>
          <div className='bounce-arrow mt-6 h-16 sm:h-20 w-16 sm:w-20 relative z-20'>
            {ShowNextButton() && (
              <img
                src={DownArrowBlack}
                className='cursor-pointer'
                onClick={next}
                alt='down arrow'
                height='h-full'
                width='w-full'
              />
            )}
          </div>
          <div className='flex space-x-2 text-575757 text-2xs sm:text-xs uppercase'>
            {stage === 4 && (
              <div className='cursor-pointer' onClick={next}>
                Skip this step{" "}
              </div>
            )}
            {stage === 4 && <div>|</div>}
            <Link to={routes.setUpProfileLater}>
              <div>I will create my profile later.</div>
            </Link>
          </div>
        </div>
        {/*  <div className='absolute flex justify-between bottom-24 left-4 right-4'>
        <div className='animate-bounce h-16 w-16'>
          {ShowNextButton() && (
            <img
              src={DownArrowBlack}
              className='cursor-pointer'
              onClick={next}
              alt='down arrow'
              height='h-full'
              width='w-full'
            />
          )}
        </div>
        <div className='flex space-x-2 text-575757 text-xs uppercase'>
          <Link to='/create-contest'>
            <div>Will Setup Profile Later</div>
          </Link>
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default Stepper;
