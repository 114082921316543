import React from "react";
import Button from "../../../Components/Button";
import QuestionsList from "./QuestionsList";

const QuizPreviewer = ({
  questions,
  subject,
  updateStage,
  submit,
  loading,
}) => {
  const onEdit = () => {
    window.scrollTo(0, 0);
    updateStage(1);
  };

  return (
    <div className='sm:py-4 2xl:px-12 font-source-sans-pro'>
      <div className='font-semibold heading3 capitalize mb-8'>{subject}</div>
      <QuestionsList step={2} questions={questions} />
      {/* <div className='flex space-x-4'>
        <div
          onClick={() => setDisableSubmit(!disableSubmit)}
          className='cursor-pointer'>
          <img
            src={!disableSubmit ? CorrectAns : Option}
            alt='option'
            className='h-5 w-5'
          />
        </div>
        <div className='sub-heading'>
          I agree to{" "}
          <span className='text-461995 font-semibold'>terms & conditions</span>{" "}
          of aankalan
        </div>
      </div> */}
      <div className='flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 mt-14 w-full xl:w-1/2'>
        <Button
          onClick={() => submit(questions)}
          width='w-full lg:w-72'
          loading={loading}>
          SUBMIT
        </Button>
        <button
          className='w-full lg:w-72 h-12 sm:h-14 3xl:h-16 font-semibold text-base capitalize border-FFA300 border-2 rounded-lg text-FFA300'
          onClick={onEdit}>
          EDIT
        </button>
      </div>
    </div>
  );
};

export default QuizPreviewer;
