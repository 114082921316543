import React from "react";

const Input = ({
  title,
  onChange = () => console.warn("Mention onChange"),
  value,
  placeholder,
  onEnterPress = () => console.warn("Mention onEnterPress"),
  info,
  large,
  medium,
  transparent,
  disabled = false,
  autoFocus = false,
  maxLength = 524288,
}) => {
  const onChangeEvent = (e) => {
    if (e.key === "Enter") {
      onEnterPress(e.target.value);
      console.log("hello");
    }
  };

  let size = "3xl:text-xl px-4 w-full h-12 sm:h-14 3xl:h-16 ";
  let labelSize = "font-semibold text-base md:text-xl 3xl:text-2xl mb-3";
  if (large) {
    size = "text-2xl xl:text-5xl 3xl:text-6xl  light-placeholder";
    labelSize = "text-base md:text-3xl 3xl:text-4xl text-9A9A9A mb-1";
  }
  if (medium) {
    size =
      "text-xl md:text-2xl xl:text-3xl 3xl:text-5xl  light-placeholder w-full";
    labelSize = "font-semibold text-base md:text-xl 3xl:text-2xl mb-1";
  }

  return (
    <div className='font-soure-sans-pro'>
      {title && <div className={`${labelSize}`}>{title}</div>}
      <input
        className={`${
          transparent
            ? "bg-transparent"
            : "bg-FAFAFA focus:border-461995 border border-D3D0D0 rounded-md"
        }   ${size} focus:outline-none `}
        onChange={(e) => onChange(e.target.value)}
        onKeyUp={onChangeEvent}
        value={value}
        autoFocus={autoFocus}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
      />
      {info && <div className='mt-2 text-xs text-707070'>*{info}</div>}
    </div>
  );
};
export default Input;
