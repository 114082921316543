import Button from "./Button"

export default ({ close }) => {
    return (
        <div className="">
            <div className="container">
                <div className="row py-4 lg:py-6 font-roboto-slab">
                    <div className="col-sm-12">
                        <p className="text-2xs lg:text-base">
                            "Basic T&Cs": Following terms & conditions shall be applicable to all the Self/ National Tests on AANKALAN app.
                        </p>
                    </div>
                </div>
                <hr />
                <div className="row py-4 lg:py-6 font-open-sans-pro text-6a6a6a">
                    <div className="col-sm-12">
                        <li className="mt-2 text-2xs lg:text-base">The Self/ National Tests are open only to Indian citizens, residing in India. Citizens and/ or residents of countries other than India are ineligible to participate. Citizens and/ or residents of countries other than India residing in India are not eligible to participate.</li>
                        <li className="mt-2 text-2xs lg:text-base">A Participant can be any Indian of sound mind. S/ he must access this App and/ or participate in an Activity on her/ his own & must not take intellectual assistance from anyone else</li>
                        <li className="mt-2 text-2xs lg:text-base">An Activity shall be open for participation on the App as per the timelines specified in the terms & conditions for the Activity.</li>
                        <li className="mt-2 text-2xs lg:text-base">Employees, agents, and promoters (including their immediate family members) of AANKALAN Enterprises Pvt Ltd and any of its divisions, affiliates and subsidiaries, and others associated with the Tests in any manner, are not eligible to participate in any Live Tests</li>
                        <li className="mt-2 text-2xs lg:text-base">Every Self/ National Test has its own terms & conditions. These shall be mentioned on the app before the test. Only those who agree with the terms & conditions can participate in the Tests. AANKALAN does not force any individual/ Group to participate in the tests. The Participant agrees that by participating in the activities on the App, s/ he shall be bound by the terms and conditions governing the app.</li>
                        <li className="mt-2 text-2xs lg:text-base">The Participant shall be required to provide personal information including but not limited to name, address, city, state, telephone/ mobile number, and email address to register and participate on the App. The information provided by the Participant may be used by AANKALAN Enterprises Pvt Ltd for internal purposes and to send promotional information pertaining to the App to the Participants.</li>
                        <li className="mt-2 text-2xs lg:text-base">The specific requirements for an Activity shall be outlined in the T&Cs of the Tests.</li>
                        <li className="mt-2 text-2xs lg:text-base">For an Activity, each Participant must submit her/ his complete entry details and fees to qualify as an entrant in the Activity (“Entries”) through Specific Mode of Participation specified in Specific T&Cs stated at the end of this T&C page.</li>
                        <li className="mt-2 text-2xs lg:text-base">The manner, day, and basis of choosing the winner (defined below) shall be specified in the T&Cs of the Tests.</li>
                        <li className="mt-2 text-2xs lg:text-base">The announcement about the Winners and the Prize(s) (defined below) shall be as per the T&Cs of the Live Tests.</li>
                        <li className="mt-2 text-2xs lg:text-base">Method and time of prize distribution: Aankalan shall inform the Winners via email, SMS or phone call via the contact details provided by the Participant at the time of registering for the Test. Aankalan may require Winners to provide valid photo identity documents including but not limited to passport and ration card prior to handing over the Prize(s). AANKALAN ENTERPRISES PVT LTD may or may not announce the Winners via its Website.</li>
                        <li className="mt-2 text-2xs lg:text-base">The Prize(s) distribution mode and time shall be at the sole discretion of AANKALAN ENTERPRISES PVT LTD.</li>
                        <li className="mt-2 text-2xs lg:text-base">The Prize(s) may be either cash or vouchers or tangible goods. In case of tangible goods, the same shall be sent by post and/ or courier to the Winners on the address specified by them at the time of registering on the App. AANKALAN ENTERPRISES PVT LTD shall not be responsible if any Prize(s) is damaged during delivery through post and/ or courier.</li>
                        <li className="mt-2 text-2xs lg:text-base">A Participant shall not post any profanity or abusive comments on the App, Website/ Channel website/ or on any related platform. In the event of any such profanity or abusive comment posted on mentioned platforms, the concerned Participant(s) shall be disqualified. AANKALAN ENTERPRISES PVT LTD reserves the right to remove such posts and to take legal action against the concerned Participant.</li>
                        <li className="mt-2 text-2xs lg:text-base">After every Test, an explanation shall be provided for the solution of each question. No further, unnecessary clarifications and/ or queries shall be entertained by AANKALAN ENTERPRISES PVT LTD.</li>
                        <li className="mt-2 text-2xs lg:text-base">The Participant(s) agrees that AANKALAN ENTERPRISES PVT LTD is following <br/>
                        &ensp; 1. Strict measures to make the Tests secure.<br/>
                        &ensp; 2. Fair practices to give a uniform chance to each Participant to compete using her/ his Skills and Knowledge.</li>
                        <li className ="mt-3 text-2xs lg:text-base">By participating in an Activity on the APP, the Participant agrees to follow these Terms and Conditions and is deemed bound by any decisions made by AANKALAN ENTERPRISES PVT LTD in its sole discretion, including any interpretations of the Terms and Conditions</li>
                        <li className ="mt-3 text-2xs lg:text-base">AANKALAN ENTERPRISES PVT LTD, including its divisions, affiliates and subsidiaries, and their respective agents, representatives, and employees shall not be responsible for any loss of personal information/ Entries for any reason whatsoever including technical problems.This includes Entries received after the deadline because of smartphone service delays, interruptions, overloads, or for electronic failures of any kind, or for lost, late, misdirected, or misplaced Entries for Live Tests.The Entries are void if entry fee is not paid, or if Entries are inaccurate, incomplete, falsified, mechanically reproduced, not in prescribed format, irregular in any way or otherwise not in compliance with these Terms and Conditions.</li>
                        <li className ="mt-3 text-2xs lg:text-base">AANKALAN ENTERPRISES PVT LTD reserves the right to select and declare or not to declare the Winners if no Participant has submitted correct answers.The decision of AANKALAN ENTERPRISES PVT LTD in this regard is final and no correspondence in this regard shall be entertained.</li>
                        <li className ="mt-3 text-2xs lg:text-base">While accepting the Prize(s), the Winners may be required to sign an ’indemnity and release of claims‘ form provided by AANKALAN ENTERPRISES PVT LTD, as the case may be, which shall release AANKALAN ENTERPRISES PVT LTD of all liability.Failure to complete and sign any documents requested by AANKALAN ENTERPRISES PVT LTD may result in disqualification and selection of an alternate Winner.All decisions of AANKALAN ENTERPRISES PVT LTD are final in this regard</li>
                        <li className ="mt-3 text-2xs lg:text-base">Mere participation/ submitting Entries in an Activity does not entitle a Participant to win a Prize(s)</li>
                        <li className ="mt-3 text-2xs lg:text-base">A Prize(s) shall be awarded to a Winner(s) pursuant to verification and in accordance with these Terms and Conditions.</li>
                        <li className ="mt-3 text-2xs lg:text-base">AANKALAN ENTERPRISES PVT LTD may refuse to award a Prize(s) to a Winner(s) in the event of the participant’s fraud, or dishonesty, or non-qualification to participate in the Activity under these Terms and Conditions.</li>
                        <li className ="mt-3 text-2xs lg:text-base">In case of a Cash Prize, the same shall be electronically transferred to the Winner’s account using the platform Aankalan ties up with for a Live Test.A Participant(s) may need to download the concerned Payment gateway/ UPI app before the Live Test, if required, in accordance with the Terms and Conditions of the Activity.</li>
                        <li className ="mt-3 text-2xs lg:text-base">A Prize(s) is not transferable, assignable, or exchangeable.Only a Winner(s) may claim the Prize(s), and no other person or agent acting on behalf of the Winner(s).</li>
                        <li className ="mt-3 text-2xs lg:text-base">In case a Winner(s) is found to be in violation of any rule of an Activity, AANKALAN ENTERPRISES PVT LTD reserves the right to withdraw the Prize(s) won, having the concerned Winner(s) refund the Prize(s) won and/ or initiate legal proceedings against such person(s).</li>
                        <li className ="mt-3 text-2xs lg:text-base">AANKALAN ENTERPRISES PVT LTD has the right to substitute a Prize(s) with another prize of equal value, as determined by AANKALAN ENTERPRISES PVT LTD in its sole discretion.</li>
                        <li className ="mt-3 text-2xs lg:text-base">AANKALAN ENTERPRISES PVT LTD has the right to amend (add, delete , or modify) the Terms and Conditions governing an Activity, prospectively or retrospectively, at its discretion and without prior notice.</li>
                        <li className ="mt-3 text-2xs lg:text-base">AANKALAN ENTERPRISES PVT LTD reserves the right to disqualify a Participant if it has reasonable grounds to believe that the Participant has breached any of the Terms and Conditions.</li>
                        <li className ="mt-3 text-2xs lg:text-base">AANKALAN ENTERPRISES PVT LTD is not responsible for any errors or omissions in the terms and conditions contained herein.All information provided in an Activity/ Test is provided ’As is‘, without warranty of any kind.AANKALAN ENTERPRISES PVT LTD makes no representations and disclaims all express, implied, and statutory warranties of any kind to a Participant and/ or any third party including, without limitation, warranties as to accuracy, timelines, completeness, merchantability, or fitness for any particular purpose.</li>
                        <li className ="mt-3 text-2xs lg:text-base">Under no circumstance, unless due to wilful misconduct or gross negligence, shall AANKALAN ENTERPRISES PVT LTD and/ or its directors, employees, officers, affiliates, or subsidiaries, be liable to a Participant and/ or any third party for any lost profits or lost opportunities, or indirect, special, consequential, incidental, or punitive damages whatsoever, even if AANKALAN ENTERPRISES PVT LTD has been advised of the possibility of such damages.The Participant specifically agrees not to file in person/ through any family member and/ or any third party any applications, criminal and/ or civil proceedings in any courts or forum in India against AANKALAN ENTERPRISES PVT LTD, its directors, employees, officers, affiliates, or subsidiaries to claim any damages or relief in connection with an Activity.</li>
                        <li className ="mt-3 text-2xs lg:text-base">By registering on the AANKALAN App, the Winner(s)/ Participant(s) waives all copyrights including but not limited to Intellectual Property Rights (IPR), moral rights, rights of publicity and any related rights.A Participant(s) consents to AANKALAN ENTERPRISES PVT LTD’s right to use the pictures uploaded by her/ him, the captions posted for the Activity, picture, tape or portray her/ him as an Activity Winner(s).A Participant(s) further consents to any of this material being exhibited on any media platform now existing or hereinafter created, including without limitation television, film, radio, and print, without any compensation whatsoever for advertising and publicity purposes, except where prohibited by law.A Participant(s) also consents to AANKALAN ENTERPRISES PVT LTD’s right to use her/ his name, voice or picture, or the content of her/ his Activity Entry (collectively, the “Publicity Rights”).AANKALAN ENTERPRISES PVT LTD is free to allow its agencies to exercise the Publicity Rights in conjunction with AANKALAN ENTERPRISES PVT LTD.AANKALAN ENTERPRISES PVT LTD shall also be free to use any ideas, concepts, know-how or techniques contained in the entries received for an Activity for any purpose</li>
                        <li className ="mt-3 text-2xs lg:text-base">AANKALAN ENTERPRISES PVT LTD does not make any commitment, express or implied, to respond to any feedback, suggestion and, or queries of a Participant(s) or furnish any reason or explanation for inclusion and/ or exclusion of any Entry at any stage of an Activity.</li>
                        <li className ="mt-3 text-2xs lg:text-base">Non-conformance by a Participant(s) and/ or Winner(s) shall result in immediate disqualification from participation or winning a Prize(s), as the case may be.</li>
                        <li className ="mt-3 text-2xs lg:text-base">AANKALAN ENTERPRISES PVT LTD reserves the right to withdraw/ discontinue / terminate an Activity/ Test at any stage without prior notice and without any liability whatsoever to a Participant(s).</li>
                        <li className ="mt-3 text-2xs lg:text-base">The decisions of AANKALAN ENTERPRISES PVT LTD and its representatives shall be final and binding on all aspects of an Activity/ Test.</li>
                        <li className ="mt-3 text-2xs lg:text-base">Your participation in an Activity/ Test by posting an Entry shall be deemed as acceptance of the above Terms and Conditions.</li>
                        <li className ="mt-3 text-2xs lg:text-base">All taxes, levies, and duties due and owing under applicable and statutory laws in connection with all Prizes if any, and all other costs, including insurance, incidental costs, gifts, gratuities, and taxes, are the sole responsibility of the Winner(s).</li>
                        <li className ="mt-3 text-2xs lg:text-base">AANKALAN ENTERPRISES PVT LTD shall keep all the information collected from a Participant confidential.AANKALAN ENTERPRISES PVT LTD shall ensure that the collected information is not shared with any third party, save for the purpose of the present Activity.A Participant, by providing the aforesaid sensitive personal information, hereby agrees that AANKALAN ENTERPRISES PVT LTD shall have the right to share the information so collected with such other third party, as required for the purpose of the Activity/ Test.The Participant further agrees that s/ he shall not file any claim against AANKALAN ENTERPRISES PVT LTD for sharing such personal information.</li>
                        <li className ="mt-3 text-2xs lg:text-base">Refund & Cancellation Policy: Membership Cancellation and Refund are not available.However, if any paid Activity has a technical error, then the Activity fee will be refunded in full within 7 days after receiving a request for the refund and the technical team's approval on the claim.</li>
                        <li className ="mt-3 text-2xs lg:text-base">Please refer to the Privacy Policy of the App that governs the use of your personal information.A Participant entering an Activity/ Test on the App agrees and acknowledges the Terms and Conditions, Privacy Policy and Terms of Use as mentioned on the website www.in-aankalan.com in addition to the conditions governing the Activity.</li>
                        <li className ="mt-3 text-2xs lg:text-base">In exercise of the powers conferred by clause (ob) of sub-section (2) of section 87 read with section 43A of the Information Technology Act, 2000 (21 of 2000), the Central Government has framed the Information Technology (reasonable security practices and procedures and sensitive personal data or information) Rules, 2011("Privacy Rules") which makes it mandatory for any concern to deal with any personal information including sensitive personal information as per the said Privacy Rules.In course of providing the aforesaid Entries in the APP, AANKALAN ENTERPRISES PVT LTD shall treat the information and pictures as sensitive and confidential and as per the Privacy Rules, such information shall be dealt with as per the provisions specified in the said Rules.</li>
                        </div>
                    </div>
                    <div className="row py-4 lg:py-6">
                        <div className="col-sm-12">
                            <p className="text-2xs lg:text-base">
                                Please refer to App's Privacy Policy that governs the use of your personal information. Participant entering into this Activity/Test on the app agree and acknowledge the Terms and Conditions, Privacy Policy and Terms of Use as put up on the website www.in-aankalan.com in addition to the conditions set out in this document which shall govern the Activity;
                            </p>
                            <br/>
                            <p className="text-2xs lg:text-base">
                                In exercise of the powers conferred by clause (ob) of sub-section (2) of section 87 read with section 43A of the Information Technology Act, 2000 (21 of 2000), the Central Government has framed the Information Technology (reasonable security practices and procedures and sensitive personal data or information) Rules, 2011("Privacy Rules") which makes it mandatory for any concern to deal with any personal information including sensitive personal information as per the said Privacy Rules. In course of providing the aforesaid Entries in the APP, AANKALAN ENTERPRISES PVT LTD shall treat the information and pictures as sensitive and confidential and as per the aforementioned Privacy Rules such information has to be now dealt with as per the provisions specified in the said Rules
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            )
}
