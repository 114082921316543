import React from 'react';

export const RangeInput = ({
    title ,value, min , max , step, onChange 

}) => {
    return <div class="relative pt-1">
        {title && (
            <div className="font-semibold text-base md:text-xl 3xl:text-2xl mb-3">
                {title}
            </div>
        )}
        <input
            type="range"
            class="form-range appearance-none text-black w-full h-4 p-0 bg-transparent rounded-full focus:outline-none focus:ring-0 shadow"
            min={min}
            onChange={(e) => onChange(e.target.value)}
            max={max}
            value={value}
            step={step}
            id="customRange3"
        />
        <div>{value} Years</div>
    </div>;
};
