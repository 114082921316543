import React, { useState, useEffect } from "react";
import DropDown from "./CustomSelect/DropDown";
import SelectOption from "./CustomSelect/SelectOption";
import Input from "./Input";
import { handleSelect } from "../Utility/Helper";
import SelectDownArrow from "../Assets/Icons/SelectDownArrow.svg";
import SelectUpArrow from "../Assets/Icons/SelectUpArrow.svg";

export const TagsInput = ({
  title,
  id,
  placeholder,
  tagsArray,
  info,
  setTagsArray,
  transparent,
  optionsList,
  dropDownPosition,
  allowType,
  selectedOption,
  defaultSelect = true,
}) => {
  const NO_RESULT = "No other result found";
  const [tag, setTag] = useState("");
  const [dropdownActive, setDropdownActive] = useState(false);
  const [similarTags, setSimilarTags] = useState([]);

  useEffect(() => {
    setSimilarTags(optionsList);
  }, []);

  const addCoreSubject = (newSub) => {
    setTagsArray([...tagsArray, newSub]);
    setTag("");
    console.log(similarTags.filter((tag) => tag !== newSub));
    setSimilarTags([]);
  };

  const removeCoreSubject = (index) => {
    let newArray = tagsArray;
    newArray.splice(index, 1);
    setTagsArray([...newArray]);
    setTag("");
  };

  const onOptionSelect = (value) => {
    if (value !== NO_RESULT) {
      addCoreSubject(value);
    }
    setDropdownActive(false);
    setTag("");
  };

  // const searchTag = (val) => {
  //   setTag(val);
  //   setDropdownActive(true);
  //   let similarTags = optionsList.filter(
  //     (option, index) =>
  //       option.toLowerCase().includes(val.toLowerCase()) &&
  //       !tagsArray.includes(option)
  //   );
  //   return similarTags.length === 0
  //     ? setSimilarTags([NO_RESULT])
  //     : setSimilarTags(similarTags);
  // };

  return (
    <div>
      {title && (
        <label className='font-soure-sans-pro font-semibold text-base md:text-xl 3xl:text-2xl mb-3'>
          {title}
        </label>
      )}
      <div className='w-full relative cursor-pointer'>
        <div
          id={`selectGroup-${id}`}
          className={`w-full flex items-center justify-between capitalize ${
            defaultSelect
              ? `h-12 sm:h-14 3xl:h-16 mt-3 ${
                  transparent ? "bg-FFF5E3" : "bg-FAFAFA"
                } shadow font-soure-sans-pro text-base 3xl:text-xl text-707070 px-4 py-2.5 ${
                  dropdownActive ? "rounded-t-md" : "rounded-md"
                }`
              : ""
          } `}
          onClick={(e) => handleSelect(e, dropdownActive, setDropdownActive)}>
          <div>{selectedOption ? selectedOption : placeholder}</div>
          {dropdownActive ? (
            <div>
              <img src={SelectUpArrow} alt='up arrow' />
            </div>
          ) : (
            <div>
              <img src={SelectDownArrow} alt='down arrow' />
            </div>
          )}
        </div>
        {dropdownActive && (
          <DropDown
            leftRightTopBottom={dropDownPosition}
            onOptionSelect={onOptionSelect}
            transparent={transparent}
            selectedOption={selectedOption}
            dropdownActive={dropdownActive}
            allowType={allowType}
            setDropdownActive={setDropdownActive}>
            {similarTags.map((tag, index) => (
              <SelectOption key={index} value={tag}>
                {tag}
              </SelectOption>
            ))}
          </DropDown>
        )}
        <div className='mt-2 flex flex-wrap'>
          {tagsArray.map((tag, index) => (
            <div className='py-1 px-2 flex justify-between items-center space-x-4 shadow rounded mr-3 mt-2 cursor-pointer'>
              <div>{tag}</div>
              <span onClick={() => removeCoreSubject(index)}>
                <i class='fas fa-times text-gray-600 '></i>
              </span>
            </div>
          ))}
        </div>
      </div>
      {info && <div className='mt-2 text-xs text-707070'>*{info}</div>}
    </div>
  );
};
