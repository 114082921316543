import React, { useState } from "react";
import Input from "../Input";
import GreenTick from "../../Assets/Icons/Create-Contest/green-tick.svg";
import toast from "react-hot-toast";

const DropDown = ({
  leftRightTopBottom,
  children,
  onOptionSelect,
  selectedOption,
  dropdownActive,
  transparent,
  setDropdownActive,
  allowType,
}) => {
  if (dropdownActive) {
    document.addEventListener("click", function (event) {
      setDropdownActive(false);
    });
  }

  const [customInput, setCustomInput] = useState("");

  const save = () => {
    if (customInput) {
      onOptionSelect(customInput);
    } else {
      toast.error("Enter a valid tag");
    }
  };

  return (
    <div id='customDropdown'>
      <div
        className={`shadow-lg absolute ${leftRightTopBottom} max-h-96 ${
          transparent ? "bg-transparent" : "bg-FFFFFF"
        } rounded-md z-10 overflow-y-auto border border-E5E5E5`}>
        {allowType && (
          <div
            onClick={(e) => e.stopPropagation()}
            className='flex justify-between items-center bg-FFF5E3'>
            <div className='flex-grow'>
              <Input
                value={customInput}
                onChange={setCustomInput}
                transparent
                placeholder='If not mentioned, type here'
              />
            </div>
            <div className='flex items-center pr-4'>
              <ion-icon
                onClick={save}
                style={{ color: "#39177E", fontSize: "24px" }}
                name='checkmark-circle-outline'></ion-icon>
            </div>
          </div>
        )}
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            onClick: onOptionSelect,
            value: child.props.value,
            selectedValue: selectedOption,
          })
        )}
      </div>
    </div>
  );
};

export default DropDown;
