import React from "react";
import Image from "../../../../Components/Image";
import Revenue from "../../../../Assets/Icons/RevenueIncrease.svg";
import Popularity from "../../../../Assets/Icons/Popularity.svg";
import Education from "../../../../Assets/Icons/Education.svg";
import IndiaMap from "../../../../Assets/Images/IndiaMap.svg";

const TeacherStep3 = ({ description, dataArray }) => {
  const imagesArray = [Popularity, Education, Revenue];
  return (
    <div className='bg-FCFCFC'>
      <div className='container w-8/10-center py-20 space-y-16'>
        {description && (
          <div className='heading3 text-center lg:w-1/2 mx-auto text-black'>
            {description}
          </div>
        )}
        <div className='grid grid-cols-3 gap-8 items-center'>
          <div className='lg:row-start-1 col-span-3 lg:col-span-1 flex flex-col items-center space-y-4 md:flex-row md:space-x-4 md:space-y-0 lg:space-x-0 lg:flex-col lg:space-y-8 font-source-sans-pro font-semibold text-center text-black text-sm md:text-base capitalize'>
            {dataArray.map((item, index) => (
              <div className='w-full bg-F5F5F5 rounded-md flex flex-col space-y-4 items-center px-2 py-6 lg:w-3/4'>
                <div className='px-4'>{item}</div>
                <Image
                  src={imagesArray[index]}
                  alt='people'
                  height=''
                  width=''
                />
              </div>
            ))}
          </div>
          <div className='relative row-start-1 col-span-3 lg:col-span-2 space-y-4'>
            <div className='w-3/4'>
              <img
                src={IndiaMap}
                alt='india map'
                height='h-auto'
                width='w-full'
              />
            </div>
            <div className='absolute top-0 right-4 xl:right-20 font-source-sans-pro font-semibold text-center text-sm sm:text-xl lg:text-2xl xl:text-3xl 3xl:text-4xl text-black capitalize'>
              How Technology Helps
              <br /> Teacher / Institutes?
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherStep3;
