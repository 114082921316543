import React from "react";
import Image from "../../Components/Image";
import appImage from "../../Assets/Images/onboarding-mobile-screen-2.svg";
import TableHeadLarge from "../../Components/Table/TableHeadLarge";
import TableRowLarge from "../../Components/Table/TableRowLarge";
import TableRowSmall from "../../Components/Table/TableRowSmall";
// import ScholarshipData from "../../Data/Scholarship.json";

let ScholarshipData = require("../../Data/Scholarship.json");

const ScholarshipSection = ({ heading }) => {
  return (
    <div className='md:bg-240D4C bg-E9E9FF'>
      <div className='pt-10 sm:pt-24'>
        <div
          className='heading font-source-sans-pro font-semibold text-center text-black md:text-white'
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <div className=' space-y-10 lg:space-y-20 w-full relative  md:w-11/12 ml-auto pt-16 overflow-y-hidden'>
          <div className='grid grid-cols-2 '>
            <div className='hidden xl:block  col-span-1 h-full '>
              <div className='absolute left-0 -bottom-30 h-full '>
                <Image src={appImage} height='h-auto' width='w-full' />
              </div>
            </div>
            <div className='col-span-2 xl:col-span-1 bg-E9E9FF md:bg-white  rounded-md md:py-8 px-8 3xl:p-12  mb-24'>
              <div className='text-xl font-roboto-slab font-bold text-center md:text-left mb-8'>
                Scholarship Leader-Board
              </div>
              <div className='hidden md:block'>
                {/* table-heading */}
                <TableHeadLarge />
                {ScholarshipData["data"].map((student, index) => (
                  <TableRowLarge
                    image={student.image}
                    key={index}
                    index={index}
                    name={student.name}
                    examTaken={student.examTaken}
                    scholarship={student.cash}
                    city={student.city}
                    rank={student.rank}
                  />
                ))}
              </div>
              <div className='mt-6 md:hidden '>
                {ScholarshipData["data"].map((student, index) => (
                  <TableRowSmall
                    image={student.image}
                    key={index}
                    index={index}
                    date={student.date}
                    name={student.name}
                    examTaken={student.examTaken}
                    scholarship={student.cash}
                    city={student.city}
                    rank={student.rank}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipSection;
