import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { ProfileContext } from "../../../Context/ProfileContext";
import { ProfileService } from "../../../Services/profile.service";
import Button from "../../../Components/Button";

import toast from "react-hot-toast";

import { getAnalytics, logEvent } from "firebase/analytics";


export const AgreementModal = ({ close }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const {
    agreeAankalnAgreement,
    setAgreeAankalnAgreement,
    data,
    setStage,
    setStep,
    setMessage,
    setDescription,
    setActiveHandle,
    setBankState,
    setVerifiedPhoneNo,

    name,
    setName,
    email,
    setEmail,
    gender,
    setGender,
    profession,
    setProfession,
    profileImage,
    setProfileImage,
    logoImage,
    setLogoImage,
    promoteUser,
    setPromoteUser,
    promoteUserSocialHandle,
    setPromoteUserSocialHandle,
    youtubeLink,
    setYoutubeLink,
    instaLink,
    setInstaLink,
    fbLink,
    setFbLink,
    twitterLink,
    setTwitterLink,
    teachingExp,
    setTeachingExp,
    qualification,
    setQualification,
    coreSubjects,
    setCoreSubjects,
    subjects,
    setSubjects,

    aboutUser,
    setAboutUser,

    phoneNumber,
    setPhoneNumber,
    city,
    setCity,
    state,
    setState,
    accHolderName,
    setAccHolderName,
    accNumber,
    setaccNumber,
    IFSC,
    setIFSC,
    branchName,
    setBranchName,
    bankName,
    setBankName,
    panCard,
    setPanCard,
    upiID,
    setUpiID,
  } = useContext(ProfileContext);

  const uploadImage = async (file) => {
    let payload = new FormData();
    payload.append("file", file, "image.png");
    let res = await ProfileService.uploadFile(payload);
    return res;
  };

  const submitForm = async () => {
    const data = {
      iam: profession,
      email,
      gender,
      state,
      city,
      name,
      about_me: aboutUser,
      phone: phoneNumber,
      highest_qualification: qualification,
      teaching_subject: subjects.join(),
      core_expertise: coreSubjects.join(),
      branch_name: branchName,
      account_number: accNumber,
      account_holder_name: accHolderName,
      bank_name: bankName,
      ifsc_code: IFSC,
      pan_card: panCard,
      upi_id: upiID,
      twitter: twitterLink,
      facebook: fbLink,
      instagram: instaLink,
      youtube: youtubeLink,
      experience: teachingExp,
      teacher_image: "",
      logo_image: "",
      allow_picture_use: promoteUser ? 1 : 0,
      allow_social_media: promoteUserSocialHandle ? 1 : 0,
    };

    if (profession !== "teacher" && profession !== "youtuber") {
      data["iam"] = "institute";
      data["institute_name"] = profession;
    }

    // saveDate(data);
    setLoading(true);

    if (profileImage) {
      data.teacher_image = await uploadImage(profileImage[0]);
    }
    if (logoImage) {
      data.logo_image = await uploadImage(logoImage[0]);
    }
    saveDate(data);
  };

  const saveDate = (data) => {
    ProfileService.saveTeacherDetails(data)
      .then((res) => {
        console.log(res);
        setLoading(false);

        const analytics = getAnalytics();
        logEvent(analytics, 'CREATE_PROFILE_COMPETE', {
          created: "true",
          phone:phoneNumber,
          name:name,
        });
        // Close model and re-initialise state of form fields
        emptyState();
        close();
        if (history.location.pathname === "/create-test-series/profile")
          history.replace("/create-test-series/congratulations");
        else history.replace("/create-contest/congratulations");
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const emptyState = () => {
    setAgreeAankalnAgreement(false);
    setStage(1);
    setStep(1);
    setMessage(data[1].steps[1].message);
    setDescription(data[1].steps[1].description);
    setActiveHandle("youtubeLink");
    setAboutUser("");
    setBankState("UPI_PAN");
    setName("");
    setEmail("");
    setGender("Male");
    setProfession("teacher");
    setProfileImage("");
    setLogoImage("");
    setPromoteUser(true);
    setPromoteUserSocialHandle(true);
    setYoutubeLink("");
    setInstaLink("");
    setFbLink("");
    setTwitterLink("");
    setTeachingExp(10);
    setQualification("");
    setCoreSubjects([]);
    setSubjects([]);
    setPhoneNumber("");
    setCity("");
    setState("");
    setAccHolderName("");
    setaccNumber("");
    setIFSC("");
    setBranchName("");
    setBankName("");
    setPanCard("");
    setUpiID("");
    setVerifiedPhoneNo(false);
  };

  return (
    <div className='font-open-sans-pro py-8 px-8 sm:py-8 sm:px-16  md:py-10 md:px-24'>
      <div className='text-3xl font-semibold'>Terms & Conditions</div>
      <div className='overflow-y-scroll mt-4' style={{ height: "45vh" }}>
        <p class='p1'>
          <strong>AGREEMENT</strong>
        </p>
        <p class='p2'>&nbsp;</p>
        <p class='p3'>
          This Agreement (hereinafter "Agreement"), is made effective as of
          Today by and between the following parties:
        </p>
        <p class='p4'>
          Aankalan Enterprise Pvt Limited, a company incorporated under Indian
          Companies Act, UP, India 201301 (hereinafter referred to as the
          &ldquo;Company&rdquo;)
        </p>
        <p class='p5'>
          <span class='Apple-converted-space'>&nbsp;</span>
        </p>
        <p class='p6'>
          {" "}
          {name} r/o of {city}, {state} (hereinafter referred to as the
          &ldquo;Client&rdquo;).
          <span class='Apple-converted-space'>&nbsp;</span>
        </p>
        <p class='p3'>
          The parties shall be individually referred to as "Party" and
          collectively as the "Parties."
        </p>
        <p class='p3'>
          <em>
            <br /> RECITALS:
          </em>
        </p>
        <p class='p3'>
          <em>WHEREAS Company </em>
          <span class='s1'>
            is one of the fastest growing National Testing Platform, having its
            presence over 1100 Cities in India and caters to more than 1 lakhs
            students every day
          </span>
          <em>
            {" "}
            online through its Mobile Application &ndash; Aankalan (hereinafter
            referred to as &ldquo;Aankalan App&rdquo;) and Client is engaged in
            teaching his/her subjects with focus on educating the students.
          </em>
        </p>
        <p class='p3'>
          <em>
            WHEREAS the Mobile Application &ndash; Aankalan is configured and
            support to conduct third-party Tests, advertisements and promotional
            activities and Client wishes to utilize Company's space on the
            Mobile app for the doing its test papers, online advertising and
            other promotional activities.
          </em>
        </p>
        <p class='p3'>
          <em>
            WHEREAS the Parties each desire to memorialize their understanding
            through this Agreement.
          </em>
        </p>
        <p class='p3'>
          <em>
            NOW, THEREFORE, in consideration of the mutual covenants, terms, and
            conditions set forth herein, and for other good and valuable
            consideration, the receipt and sufficiency of which are hereby
            acknowledged, the Parties agree as follows
          </em>
          :
        </p>
        <p class='p7'>&nbsp;</p>
        <ul class='ul1'>
          <li class='li8'>
            <strong>SCOPE &amp; SERVICES</strong>:
          </li>
        </ul>
        <p class='p7'>&nbsp;</p>
        <p class='p8'>
          The Company shall provide the following services to its Clients and
          the Client may at its discretion choose all or any of the these:
        </p>
        <p class='p7'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              Conducting Live Test, Selling Test Series of the teacher &amp;
              Provide Mock Tests:
            </li>
          </ul>
        </ul>
        <p class='p9'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              Company will give a separate section to the Client on Aankalan App
              where the Client can conduct online test over the Aankalan App for
              its students and shall also encourage its students who are
              enrolled with them as well as other students to join or
              participate in such tests which can be Live Tests or Mock Tests as
              well. Students of App Users can get their internal assessment on
              tests by paying a specific participation / joining fee (the total
              of such fees shall be referred to as &ldquo;Total fees&rdquo;,
              which shall be cumulative of the joining fees of all the students
              who have enrolled for that test).
            </li>
          </ul>
        </ul>
        <p class='p10'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              By this promotional arrangement, Client students will get the
              exposure of National Content of Testing as Aankalan App will bring
              the biggest question bank for any examination over any mobile
              application.
            </li>
          </ul>
        </ul>
        <p class='p11'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              Company will give a space to the Client on Aankalan App where the
              Client can put his/her Test series for Sales for the App users.
              <span class='Apple-converted-space'>&nbsp;</span>
            </li>
          </ul>
        </ul>
        <p class='p11'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              Client and Company shall also agree on a revenue sharing on any
              national test/ live Contest for all users conducted by Aankalan
              App, where the Client would like to participate by providing their
              Question Paper for the same or also on providing their Test Series
              for Sales over App.
              <span class='Apple-converted-space'>&nbsp;</span>
            </li>
          </ul>
        </ul>
        <p class='p11'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              Parties agree that the Total fees under this clause shall be
              distributed as follows:
            </li>
          </ul>
        </ul>
        <p class='p12'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <ul class='ul1'>
              <li class='li3'>
                Company shall retain minimum 30% of the Total fees as its
                service fees.<span class='Apple-converted-space'>&nbsp;</span>
              </li>
            </ul>
          </ul>
        </ul>
        <p class='p13'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <ul class='ul1'>
              <li class='li3'>
                Client shall at its option retain 1-29% of the Total fees as
                their reference fees and there is no limit of the earning on
                this, even if they can earn 1 Crore for just one test paper
                also.
              </li>
            </ul>
          </ul>
        </ul>
        <p class='p13'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <ul class='ul1'>
              <li class='li3'>
                For National tests/ Live Contests the remaining Total fees shall
                be distributed among the students who have participated in these
                tests by paying joining fees in proportion to their ranking in
                such test results.
              </li>
            </ul>
          </ul>
        </ul>
        <p class='p11'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <ul class='ul1'>
              <li class='li3'>
                The Client can also take separate test for his/her students
                inside their separate container and can keep up to 70% of the
                revenue generated from it and Aankalan can keep maximum up to
                30% as Platform fee.
              </li>
            </ul>
          </ul>
        </ul>
        <p class='p11'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <ul class='ul1'>
              <li class='li3'>
                Company will keep 30% as the platform fee for any Test Series
                sold over App and remain 70% will be given to Teacher whose test
                series being sold. The GST amount of the Test Series is born by
                the teacher only.{" "}
                <span class='Apple-converted-space'>&nbsp;</span>
              </li>
            </ul>
          </ul>
        </ul>
        <p class='p12'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              <span class='Apple-converted-space'>&nbsp; </span>Advertising
              Services: <strong>(Optional)</strong>
            </li>
          </ul>
        </ul>
        <p class='p12'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              General advertisement in free space: Company agrees to publish the
              advertisements listed below, in Table A, (the "Advertisements"),
              on the Mobile App, for the period and payment terms described
              herein. Should Client wish to remove the Advertisements from
              Mobile App, prior to their natural expiration as listed in Table
              A, Client may request the Company to do so in writing. Table A:
            </li>
          </ul>
        </ul>
        <table class='t1' cellspacing='0' cellpadding='0'>
          <tbody>
            <tr>
              <td class='td1' valign='middle'>
                <p class='p3'>
                  <span class='s2'>
                    <strong>Type of Advertisement</strong>
                  </span>
                </p>
              </td>
              <td class='td1' valign='middle'>
                <p class='p3'>
                  <span class='s2'>
                    <strong>Description</strong>
                  </span>
                </p>
              </td>
              <td class='td1' valign='middle'>
                <p class='p3'>
                  <span class='s2'>
                    <strong>Run Dates</strong>
                  </span>
                </p>
              </td>
              <td class='td1' valign='middle'>
                <p class='p3'>
                  <span class='s2'>
                    <strong>Price</strong>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td class='td1' valign='middle'>
                <p class='p4'>Poster Placement</p>
              </td>
              <td class='td1' valign='middle'>
                <p class='p4'>
                  At every question over the app, Client can put their Poster
                  for specific advertisement
                </p>
              </td>
              <td class='td1' valign='middle'>
                <p class='p4'>Min 7 Days</p>
              </td>
              <td class='td1' valign='middle'>
                <p class='p4'>
                  Introductory Offer- Rs 50000 + GST ( it may wary on time to
                  time basis)
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p class='p14'>&nbsp;</p>
        <p class='p14'>&nbsp;</p>
        <table class='t1' cellspacing='0' cellpadding='0'>
          <tbody>
            <tr>
              <td class='td1' valign='middle'>
                <p class='p15'>&nbsp;</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p class='p16'>
          Provided that this advertisement should be general advertisements
          placed on the Aankalan App and shall be available to all people who
          log in to Aankalan App. Provided further that Client is solely
          responsible for all such:&nbsp;(i)&nbsp;Ads,&nbsp;(ii)
          Ads&nbsp;trafficking or targeting decisions (e.g., keywords)
          (&ldquo;Targets&rdquo;),&nbsp;(iii)&nbsp;destinations&nbsp;to
          which&nbsp;Ads&nbsp;direct viewers&nbsp;(e.g., landing
          pages,&nbsp;mobile applications) along with the related URLs,
          waypoints,&nbsp;and
          redirects&nbsp;(&ldquo;Destinations&rdquo;),&nbsp;and (iv) services
          and products advertised on Destinations (collectively, &ldquo;Services
          <span class='s3'>&rdquo;).&nbsp;</span>
        </p>
        <p class='p17'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              Specific advertisement for targets audience: Company agrees to
              publish the advertisements listed below, in Table B, (the
              "Advertisements"), on the Mobile App, for the period and payment
              terms described herein. Should Client wish to remove the
              Advertisements from Mobile App, prior to their natural expiration
              as listed in Table A, Client may request the Company to do so in
              writing
            </li>
          </ul>
        </ul>
        <p class='p12'>&nbsp;</p>
        <p class='p12'>&nbsp;</p>
        <p class='p3'>Table B:</p>
        <table class='t1' cellspacing='0' cellpadding='0'>
          <tbody>
            <tr>
              <td class='td1' valign='middle'>
                <p class='p3'>
                  <span class='s2'>
                    <strong>Type of Advertisement</strong>
                  </span>
                </p>
              </td>
              <td class='td1' valign='middle'>
                <p class='p3'>
                  <span class='s2'>
                    <strong>Description</strong>
                  </span>
                </p>
              </td>
              <td class='td1' valign='middle'>
                <p class='p3'>
                  <span class='s2'>
                    <strong>Run Dates</strong>
                  </span>
                </p>
              </td>
              <td class='td1' valign='middle'>
                <p class='p3'>
                  <span class='s2'>
                    <strong>Price</strong>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td class='td1' valign='middle'>
                <p class='p18'>
                  Emailers, Whatsapp Messages, Posters
                  <span class='Apple-converted-space'>&nbsp;</span>
                </p>
              </td>
              <td class='td1' valign='middle'>
                <p class='p18'>
                  Client can use the specific audience to send the specific
                  messages.
                </p>
              </td>
              <td class='td1' valign='middle'>
                <p class='p4'>
                  Min 7 Days<span class='Apple-converted-space'>&nbsp;</span>
                </p>
              </td>
              <td class='td1' valign='middle'>
                <p class='p4'>INR 100000/- + GST</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p class='p14'>&nbsp;</p>
        <p class='p19'>&nbsp;</p>
        <p class='p19'>&nbsp;</p>
        <p class='p19'>&nbsp;</p>
        <p class='p20'>
          Provided that this advertisement should be placed at specific parts /
          space of the Aankalan App and shall be exclusively available to the
          Client and its members / students so no one except the Client or its
          members/ students shall have access to the same. Provided further that
          Client is solely responsible for all
          such:&nbsp;(i)&nbsp;Ads,&nbsp;(ii) Ads&nbsp;trafficking or targeting
          decisions (e.g., keywords)
          (&ldquo;Targets&rdquo;),&nbsp;(iii)&nbsp;destinations&nbsp;to
          which&nbsp;Ads&nbsp;direct viewers&nbsp;(e.g., landing
          pages,&nbsp;mobile applications) along with the related URLs,
          waypoints,&nbsp;and
          redirects&nbsp;(&ldquo;Destinations&rdquo;),&nbsp;and (iv) services
          and products advertised on Destinations (collectively, &ldquo;Services
          <span class='s3'>&rdquo;).&nbsp;</span>
        </p>
        <p class='p21'>
          <span class='Apple-converted-space'>&nbsp; </span>Company will provide
          national visibility for the Client by displaying their advertisement
          on Aankalan App and the Company will also do national advertisement
          for the Client if students that take Company&rsquo;s subscription
          exceed 250.
        </p>
        <p class='p10'>&nbsp;</p>
        <ul class='ul1'>
          <li class='li3'>
            <strong>POLICIES:</strong>
          </li>
        </ul>
        <p class='p22'>
          Client is solely responsible for its use of the Aankalan App and
          authorizes Company to modify Ads as per their discretion.
          <span class='Apple-converted-space'>&nbsp; </span>Customer will not,
          and will not authorize any third party to, (i) generate automated,
          fraudulent, or otherwise invalid impressions, inquiries, clicks or
          conversions, (ii) conceal conversions for Mobile Applications where
          they are required to be disclosed, or (iii) attempt to&nbsp;interfere
          with the functioning of&nbsp;the Mobile Application. &nbsp;Customer
          will direct communications regarding Mobile Applications on the
          platform to Company directly.
          <span class='Apple-converted-space'>&nbsp;</span>
        </p>
        <ul class='ul1'>
          <li class='li3'>
            <strong>PAYMENT TERMS:</strong>
          </li>
        </ul>
        <p class='p23'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              All the Test Papers earning to the client will be done on T+4 Days
              (Bank Holiday not included), into the bank account of the teacher.
              (Here T means, Transaction Day or Test Paper Day.)
            </li>
            <li class='li3'>
              All the Test Series earning (after GST &amp; Aankalan Fee) will be
              transferred to the client on monthly basis. Aankalan is committing
              it to disburse on or before 5<sup>th</sup> of every month for
              previous month.<span class='Apple-converted-space'>&nbsp;</span>
            </li>
            <li class='li3'>
              Client shall be responsible for full payment of the Advertisements
              as listed on Table A, or Table B or as defined for Total Fees
              either in advance or within 15 days of receipt of invoice from
              Company or on such terms as agreed between the Parties.
              <span class='Apple-converted-space'>&nbsp;</span>
            </li>
          </ul>
        </ul>
        <p class='p19'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              For any fees left unpaid five (10) days past their due date,
              Company reserves the right to suspend all Advertisements on the
              Aankalan App or take any other measures at it seem appropriate.
              The Company may choose to additionally terminate this Agreement,
              at its sole and exclusive discretion.
            </li>
          </ul>
        </ul>
        <p class='p11'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              Client shall be responsible for not to sell his/her test series
              lower than the final cost of their Test Series over the Platform.
              <span class='Apple-converted-space'>&nbsp;</span>
            </li>
          </ul>
        </ul>
        <p class='p11'>&nbsp;</p>
        <p class='p19'>&nbsp;</p>
        <ul class='ul1'>
          <li class='li3'>
            <strong>CLIENT DELIVERY &amp; RESPONSIBILITIES:</strong>
          </li>
        </ul>
        <p class='p23'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              DELIVERY: Client shall be responsible for delivering all Test
              Content and ensure Quality of the tests, if due to any Tests have
              multiple errors which may lead to Brands negative image, then
              company have a full right to remove the client from the Panel,
              without any notice. Any advertising content (the "Content") to the
              following email address: <strong>contact@aankalan.app</strong>{" "}
              within 15 days of this Agreement or as agreed between the Parties.
            </li>
          </ul>
        </ul>
        <p class='p19'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              CONTENT REQUIREMENTS: Client shall be solely responsible for
              providing the Content in the formats required by Company. Company
              must individually approve all Content for Tests &amp;
              Advertisements and is not bound to approve any individual piece of
              Content. Company is not liable for any tests or advertisement or
              portion of any Advertisement which may not conform to the required
              criteria.
            </li>
          </ul>
        </ul>
        <p class='p11'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              <strong>ERRORS</strong>: Errors and omissions in Content are the
              sole and exclusive responsibility of the Client. Client shall
              immediately notify Company of any error or omission and Company
              will act with reasonable speed to correct such error or omission.
            </li>
          </ul>
        </ul>
        <p class='p11'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              Company may reject, remove, or modify any Tests or advertisement
              in its sole and exclusive discretion and is under no obligation to
              approve any Content submitted.
            </li>
          </ul>
        </ul>
        <p class='p11'>&nbsp;</p>
        <p class='p19'>&nbsp;</p>
        <ul class='ul1'>
          <li class='li3'>
            <strong>MODIFICATION AND CANCELATION REQUESTS:</strong>
          </li>
        </ul>
        <p class='p3'>
          Client may request modification of the Content in writing to Company.
          Company is not obligated to modify any Content or Advertisement, but
          requests to do so will not be unreasonably denied.
        </p>
        <ul class='ul1'>
          <li class='li3'>
            <strong>INTELLECTUAL PROPERTY:</strong>
          </li>
        </ul>
        <p class='p23'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li3'>
              Client represents to Company and unconditionally guarantees that
              all Content delivered by Client to Company for the Advertisements
              is the sole and exclusive intellectual property of Client, there
              are no encumbrances on the Content, and that Client has the right
              to license the Content for Company&rsquo;s use. Client hereby
              represents and warrants that the Content will not violate the
              intellectual property rights of any third party.
            </li>
            <li class='li3'>
              Client also represents and unconditionally guarantees that all
              Content is not defamatory, discriminatory, violent, politically
              inclined, or obscene, does not constitute false advertising,
              solicit unlawful behaviour, or violate any applicable laws, rules,
              or regulations and that all Client has the unencumbered to right
              to sell the products or services as listed in the Advertisements.
            </li>
            <li class='li3'>
              Should any Content be (1) adjudicated to be infringing,
              defamatory, discriminatory, violent, politically inclined,
              obscene, false advertising, or any other violation of applicable
              law, rules, or regulations by a competent court of law or
              judiciary authority, including a neutral mediator or arbitrator;
              or (2) questioned as infringing by a or letter or notice from a
              purported rightsholder, Client will specifically indemnify and
              hold Company, including all of its employees, contractors, agents,
              and assigns, harmless from all legal claims and demands, including
              attorney's fees, which may arise from or relate to any
              infringement claim by any third party. Such indemnification
              includes the cost responding to any such rightsholder and all
              costs involved in removing the Advertisements. Client agrees such
              indemnification shall specifically include the payment of
              Publisher's actual attorney's fees in defending any such action.
              Client agrees that the Company shall be able to select its own
              legal counsel and may participate in its own defence if it wishes.
            </li>
            <li class='li3'>
              LICENSE: Client hereby grants to Company a limited, non-exclusive,
              non-transferable, non-assignable, royalty-free license to use any
              Content for the limited purposes of producing and running or
              displacing the services offered on Mobile Application.
            </li>
            <li class='li3'>
              The license granted through this Agreement will automatically
              terminate at the termination of this Agreement.
            </li>
          </ul>
        </ul>
        <p class='p23'>&nbsp;</p>
        <ul class='ul1'>
          <li class='li3'>
            <strong>TERM &amp; TERMINATION:</strong>
          </li>
        </ul>
        <p class='p20'>
          7.1 The Agreement is effective as of the Effective Date and shall be
          valid until mutual consent of the Parties.
        </p>
        <p class='p20'>
          7.2 The Parties may each terminate this Agreement for material breach
          of any of its terms, immediately and without notice. Such material
          breach includes, but is not limited to, Client's failure to pay fees
          due.
        </p>
        <p class='p20'>
          7.3 This Agreement will also immediately terminate, without notice,
          upon the liquidation, dissolution or discontinuance of the business of
          the Company in any manner, the filing of any petition by or against
          the Company or Client under the bankruptcy or insolvency laws of
          India, if any Party is convicted of any crime or offense, fails or
          refuses to comply with the written policies or reasonable directives
          of the other Party, or is guilty of serious misconduct in connection
          with its performance under this Agreement.
        </p>
        <p class='p20'>
          7.4 Company may terminate this Agreement at any time, for any reason,
          by providing written notice to Client and upon termination for any
          reason, Company shall return to all Content to Client.
        </p>
        <p class='p19'>&nbsp;</p>
        <ul class='ul1'>
          <li class='li3'>
            <strong>CONFIDENTIALITY:</strong>
          </li>
        </ul>
        <p class='p3'>
          Each Party agrees, during the Term and for a period of three (3) years
          thereafter, to hold in strictest confidence and not to use, except for
          the benefit of the Parties or as required by law, or to disclose to
          any person, firm, or corporation without the prior written
          authorization of the other Party, any Confidential Information.
          "Confidential Information" means any of the Parties' proprietary
          information, technical data, trade secrets, or know-how, including,
          but not limited to, reports, research, product plans, products,
          services, customer lists, markets, software, developments, inventions,
          processes, formulas, technology, designs, drawings, engineering,
          hardware configuration information, marketing, finances, or other
          business information disclosed to one Party by the other Party, either
          directly or indirectly. Each Party may use the Confidential
          Information to the extent necessary for negotiations, discussions, and
          consultations with the other Party's personnel or authorized
          representatives or for any other purpose each Party may hereafter
          authorize in writing. At the request of either Party or at the
          termination of this Agreement, the other Party must promptly return
          all copies of Confidential Information received from such Party and
          must promptly destroy all other Confidential Information prepared by
          it in connection with this Agreement, including, without limitation,
          any notes, reports, or other documents.
        </p>
        <p class='p12'>&nbsp;</p>
        <ul class='ul1'>
          <li class='li3'>
            <strong> INDEMNITY:</strong>
          </li>
        </ul>
        <p class='p3'>
          Client shall indemnify and hold harmless Company and its officers,
          members, managers, employees, agents, contractors, sublicensees,
          affiliates, subsidiaries, successors, and assigns from and against any
          and all damages, liabilities, costs, expenses, claims, and/or
          judgments, including, without limitation, reasonable attorneys' fees
          and disbursements (collectively, the "Claims") that Company may suffer
          from or incur and that arise or result primarily from (i) any gross
          negligence or willful misconduct of the Client arising from or
          connected with the Client's carrying out of its duties under this
          Agreement, or (ii) the Client's breach of any of its obligations,
          agreements, or duties under this Agreement.
        </p>
        <p class='p12'>&nbsp;</p>
        <ul class='ul1'>
          <li class='li3'>
            <strong>LIMITATION OF LIABILITY:</strong>
          </li>
        </ul>
        <p class='p20'>
          10.1 The Parties acknowledge and agree that the following provisions
          are material conditions of this Agreement and reflect a fair
          allocation of risk between the parties:
        </p>
        <p class='p20'>
          <span class='s1'>10.2 </span>Company makes no express or implied
          warranty or representation to Client that operation of the Mobile
          Application will be uninterrupted, always have full functionality, or
          be error-free. Company will not be liable for consequences resulting
          from any interruption of service, malfunction, or error.
        </p>
        <p class='p20'>
          <span class='s1'>10.3 </span>Except as expressly provided for in this
          Agreement, Company makes no warranty, express or implied, and
          disclaims all implied warranties of merchantability, title, accuracy,
          integration, and fitness for a particular purpose with respect to the
          Mobile Application.
        </p>
        <p class='p20'>
          <span class='s1'>10.4 </span>Except for the indemnification provision
          listed above, in no event shall either party be liable under this
          agreement to the other Party for any incidental, consequential,
          indirect, statutory, special, exemplary or punitive damages,
          including, but not limited to, lost profits, loss of use, loss of
          time, inconvenience, lost business opportunities, damage to good will
          or reputation, and costs of cover, regardless of whether such
          liability is based on breach of contract, tort, strict liability or
          otherwise, and even if advised of the possibility of such damages.
        </p>
        <p class='p20'>
          10.5 Each party's aggregate liability for any claims relating to this
          agreement will be limited to an amount equal to the sum of the amounts
          paid by Client to Company hereunder or 1 lac (whichever is higher).
        </p>
        <p class='p20'>
          10.6 Any claims made pursuant to this section must be made within one
          year of the incident to which they relate or forever be barred.
        </p>
        <ul class='ul1'>
          <li class='li3'>
            <strong>GENERAL PROVISIONS:</strong>
          </li>
          <ul class='ul1'>
            <li class='li3'>
              GOVERNING LAW: This Agreement shall be governed in all respects by
              the laws of India and both Parties consent to jurisdiction under
              the Delhi courts.
            </li>
          </ul>
        </ul>
        <p class='p23'>&nbsp;</p>
        <ul class='ul1'>
          <ul class='ul1'>
            <li class='li4'>
              CONSENT: All the inputs updated by client about self over the
              platform can be used by the company for promotion or commercial
              purpose for Aankalan Business.{" "}
              <span class='Apple-converted-space'>&nbsp;</span>
            </li>
          </ul>
        </ul>
        <p class='p20'>
          <span class='s1'>11.3 </span>LANGUAGE: All communications made, or
          notices given pursuant to this Agreement shall be in the English
          language.
        </p>
        <p class='p20'>
          <span class='s1'>11.4 </span>ASSIGNMENT: This Agreement, or the rights
          granted hereunder, may not be assigned, sold, leased, or otherwise
          transferred in whole or part by either Party.
        </p>
        <p class='p20'>
          <span class='s1'>11.5 </span>AMENDMENTS: This Agreement may only be
          amended in writing signed by both Parties.
        </p>
        <p class='p20'>
          <span class='s1'>11.6 </span>NO WAIVER: None of the terms of this
          Agreement shall be deemed to have been waived by any act or
          acquiescence of either Party. Only an additional written agreement can
          constitute waiver of any of the terms of this Agreement between the
          Parties. No waiver of any term or provision of this Agreement shall
          constitute a waiver of any other term or provision or of the same
          provision on a future date. Failure of either Party to enforce any
          term of this Agreement shall not constitute waiver of such term or any
          other term.
        </p>
        <p class='p20'>
          <span class='s1'>11.7 </span>SEVERABILITY: If any provision or term of
          this Agreement is held to be unenforceable, then this Agreement will
          be deemed amended to the extent necessary to render the otherwise
          unenforceable provision, and the rest of the Agreement, valid and
          enforceable. If a court declines to amend this Agreement as provided
          herein, the invalidity or unenforceability of any provision of this
          Agreement shall not affect the validity or enforceability of the
          remaining terms and provisions, which shall be enforced as if the
          offending term or provision had not been included in this Agreement.
        </p>
        <p class='p20'>
          <span class='s1'>11.8 </span>ENTIRE AGREEMENT: This Agreement
          constitutes the entire agreement between the Parties and supersedes
          any prior or contemporaneous understandings, whether written or oral.
        </p>
        <p class='p20'>
          <span class='s1'>11.9 </span>HEADINGS: Headings to this Agreement are
          for convenience only and shall not be construed to limit or otherwise
          affect the terms of this Agreement.
        </p>
        <p class='p20'>
          <span class='s1'>11.10 </span>COUNTERPARTS: This Agreement may be
          executed in counterparts, all of which shall constitute a single
          agreement. If the dates set forth at the end of this document are
          different, this Agreement is to be considered effective as of the date
          that both Parties have signed the agreement, which may be the later
          date.
        </p>
        <p class='p20'>
          <span class='s1'>11.11 </span>FORCE MAJEURE: Publisher is not liable
          for any failure to perform due to causes beyond its reasonable control
          including, but not limited to, acts of God, acts of civil authorities,
          acts of military authorities, riots, embargoes, acts of nature and
          natural disasters, and other acts which may be due to unforeseen
          circumstances.
        </p>
        <p class='p20'>
          <span class='s1'>11.12 </span>NOTICES ELECTRONIC COMMUNICATIONS
          PERMITTED: Any notice to be given under this Agreement shall be in
          writing and shall be sent by first class mail, airmail, or e-mail, to
          the address of the relevant Party set out at the head of this
          Agreement, or to the relevant email address set out above or other
          email address as that Party may from time to time notify to the other
          Party in accordance with this clause.
        </p>
        <p class='p20'>
          <span class='s1'>11.13 </span>Notices sent as above shall be deemed to
          have been received 3 working days after the day of posting (in the
          case of inland first-class mail), or 7 working days after the date of
          posting (in the case of air mail), or next working day after sending
          (in the case of e-mail).
        </p>
        <p class='p20'>
          In proving the giving of a notice, it shall be sufficient to prove
          that the notice was left, or that the envelope containing the notice
          was properly addressed and posted, or that the applicable means of
          telecommunication was addressed and dispatched, and dispatch of the
          transmission was confirmed and/or acknowledged.
        </p>
        <p class='p24'>
          <span class='s1'>11.14 </span>RELATION OF PARTIES: Each party is
          independent of each other and nothing in this Agreement will create or
          imply an agency relationship between the Referrer and Company, nor
          will this Agreement be deemed to constitute a joint venture or
          partnership between the parties.
        </p>
        <p class='p20'>
          11.15 At any point, if Client is not agreed with any condition of this
          agreement with the company, then client can leave the platform
          immediately and company is not liable for any claim.
          <span class='Apple-converted-space'>&nbsp;</span>
        </p>
        <p class='p11'>&nbsp;</p>
        <p class='p11'>&nbsp;</p>
      </div>
      <div className='mt-8 flex justify-start items-center'>
        <div
          onClick={() => {
            setAgreeAankalnAgreement(!agreeAankalnAgreement);
          }}
          className='inline-flex'>
          {agreeAankalnAgreement ? (
            <div className='flex justify-center items-center w-6 h-6 border-2 border-blue-500'>
              <ion-icon
                style={{ color: "rgb(59, 130,246)" }}
                name='checkmark'></ion-icon>
            </div>
          ) : (
            <div className='flex justify-center items-center w-6 h-6 border border-gray-500'></div>
          )}
        </div>
        <div className='ml-4'>
          I read about{" "}
          <span className='text-39177E font-semibold'>terms & conditions</span>{" "}
          of Aankalan and agree with it.
        </div>
      </div>
      {agreeAankalnAgreement && (
        <div className='mt-4'>
          <Button loading={loading} onClick={submitForm} width='w-48'>
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};
