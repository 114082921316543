import React from "react";

const theme = {
  WHITE: {
    bgColor: "bg-F7F7F7",
    borderColor: "border-FAFAFA",
  },
  PINK: {
    bgColor: "bg-FAD6D6",
    borderColor: "border-FFB5B5",
  },
  BLUE: {
    bgColor: "bg-B5DCEA",
    borderColor: "border-B5DCEA",
  },
  PURPLE: {
    bgColor: "bg-EBCFEB",
    borderColor: "border-786AB5",
  },
};

const HeroCard = ({
  image,
  description,
  index = -1,
  grid = 4,
  fontSize = "CARD",
}) => {
  let selectedTheme;

  if (grid % 2 === 0) {
    if (index <= grid - 1) {
      if (index % 2 === 0) selectedTheme = theme["PINK"];
      else if (index % 2 === 1) selectedTheme = theme["BLUE"];
      else selectedTheme = theme["WHITE"];
    } else {
      if (index % 2 === 0) selectedTheme = theme["BLUE"];
      else if (index % 2 === 1) selectedTheme = theme["PINK"];
    }
  } else {
    if (index % 2 === 0) selectedTheme = theme["PINK"];
    else if (index % 2 === 1) selectedTheme = theme["BLUE"];
  }

  return (
    <div
      className={`h-full w-full border ${selectedTheme.borderColor} rounded-lg bg-FFFFFF overflow-hidden shadow`}>
      <div
        className={`${
          fontSize === "CARD"
            ? "h-36 md:h-48 lg:h-44 xl:h-48"
            : "h-40 md:h-52 lg:h-48 xl:h-60"
        } flex items-center justify-center ${selectedTheme.bgColor}`}>
        <div className='h-20 w-20'>
          <img
            src={image}
            alt='score and analytics'
            className="h-full w-full"
          />
        </div>
      </div>
      <div
        className={`font-source-sans-pro text-center ${
          fontSize === "CARD" ? "card-content" : "heading2"
        } text-1A1A1A px-2 sm:px-4 2xl:px-8 py-2 sm:py-4 md:py-8 lg:py-4 xl:py-6 2xl:py-6 3xl:py-8 capitalize`}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

export default HeroCard;

// import React from "react";
// import Image from "../Image";

// const theme = {
//   WHITE: {
//     bgColor: "bg-FFFFFF",
//   },
//   PINK: {
//     bgColor: "bg-FAD6D6",
//     borderColor: "border-FFB5B5",
//   },
//   BLUE: {
//     bgColor: "bg-B5DCEA",
//     borderColor: "border-919DD6",
//   },
//   PURPLE: {
//     bgColor: "bg-EBCFEB",
//     borderColor: "border-786AB5",
//   },
// };

// const HeroCard = ({ image, description, index = -1 }) => {
//   let selectedTheme;

//   if (index % 2 === 0) selectedTheme = theme["PINK"];
//   else if (index % 2 === 1) selectedTheme = theme["BLUE"];
//   else selectedTheme = theme["WHITE"];

//   return (
//     <div
//       className={`h-full w-full border rounded-lg lg:border-0 bg-FFFFFF overflow-hidden shadow-md`}>
//       <div>
//         <Image
//           src={image}
//           alt='score and analytics'
//           height='h-32 md:h-48 lg:h-44 xl:h-auto'
//           width='w-full'
//           position='cover'
//         />
//       </div>
//       <div className='font-source-sans-pro text-center card-content text-1A1A1A px-2 sm:px-4 2xl:px-8 py-2 sm:py-4 md:py-8 lg:py-4 2xl:py-6 3xl:py-8 capitalize'>
//         {description}
//       </div>
//     </div>
//   );
// };

// export default HeroCard;
