import React from "react";
// import aankalanData from "../../Data/Aankalan.json";
import Aankalan from "../../Assets/Icons/Aankalan.png";
import Button from "../../Components/Button";
import Image from "../../Components/Image";
import Input from "../../Components/Input";
import { useHistory } from "react-router";


let aankalanData = require('../../Data/Aankalan.json')

const Step2 = () => {
  const { companyName, tagLine } = aankalanData;

  const history = useHistory();
  return (
    <div className='w-10/12 sm:w-2/3 mx-auto'>
      <div className='py-7 md:py-10'>
        <div className='flex items-center'>
          <Image
            src={Aankalan}
            alt='logo'
            height='h-8 md:h-12'
            width='w-8 md:w-12'
          />
          <div className='font-roboto-slab font-bold text-left text-xl md:text-3xl text-black'>
            {companyName.slice(1)}
          </div>
        </div>
        <div className='font-source-sans-pro font-semibold  text-3xs md:text-sm  uppercase tracking-wider'>
          {tagLine}
        </div>
      </div>
      <div>
        <div className='mt-3'>
          <div className='heading2 font-bold'>Verify your Mobile Number</div>
        </div>
        <div className='py-10'>
          <div className=''>Register via Phone Number</div>
          <form>
            <div className='mt-4'>
              <Input
                title={`OTP will be sent to 98******78`}
                placeholder='Enter Your OTP'
              />
            </div>
            <div className='flex justify-between font-source-sans-pro mt-4'>
              <div className='text-left text-sm text-707070'>
                Didn't recieve the code?
              </div>
              <div className='font-semibold text-left text-sm text-black'>
                Re-Send Code
              </div>
            </div>
            <div className='mt-11'>
              <Button
                onClick={() => history.push("/register-student")}
                width='w-full'>
                Verify OTP
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Step2;
