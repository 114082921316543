import ColoredFeatureCard from "../../Components/Card/ColoredFeatureCard";
import HeroCard from "../../Components/Card/HeroCard";
import { useWindowDimensions } from "../../CustomHooks/ScreenH-W";

const OurJourney = ({ statsArray, statsImages }) => {
  const { width, height } = useWindowDimensions();

  return (
    <div className='bg-FFFFFF'>
      <div className={`py-10 sm:py-24`}>
        <div className='heading font-source-sans-pro font-semibold text-center'>
          Our Journey
        </div>
        <div
          className={`${
            width >= 768 ? "w-8/10-center-without-py" : "w-9/10-left-without-py"
          } flex overflow-without-scrollbar md:grid md:grid-cols-2 lg:grid-cols-3 gap-6 pt-8 sm:pt-14`}>
          {statsArray.map((item, index) => (
            <div>
              <div className='h-full w-60 md:w-full'>
                {/*  <ColoredFeatureCard
                  key={index}
                  image={statsImages[index]}
                  bgcolor={index % 2 === 0 ? "bg-E6D8FF" : "bg-FFF3DE"}
                  textColor='text-black'
                  border={
                    index % 2 === 0
                      ? "border border-461995"
                      : "border border-DB8B00"
                  }
                  text={item.text}
                  stat={item.stat}
                  type={item.type}
                /> */}
                <HeroCard
                  key={index}
                  index={index}
                  image={statsImages[index]}
                  grid={3}
                  fontSize={"JOURNEY"}
                  description={`${item.text} ${item.stat} ${item.type}`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurJourney;
