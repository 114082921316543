import React from 'react'

export default ({
    onClick, 
    selectedItem,
    item,
    close
}) => {
    return (
        <React.Fragment>
            <div onClick={() =>{onClick(item);close()}} class={`block px-4 py-2 text-sm capitalize ${selectedItem===item?"bg-FFF1CA":"bg-white"} hover:bg-FFF1CA`}>
                {item}
            </div>
        </React.Fragment>
    )
}
