import React, { useState } from "react";
import { TitleWrapper } from "../../Components/TitleWrapper";
import DownloadAppSection from "../Sections/DownloadAppSection";
import OfferSection from "../Sections/OfferSection";
import FaqQues from "./FaqQues";
// import AankalanData from "../../Data/Aankalan.json";
import Dropdown from "../../Components/Dropdown";
import DropdownItem from "../../Components/Dropdown/DropdownItem";


let AankalanData = require('../../Data/Aankalan.json')


const Faq = () => {
  const {
    aankalanHeading: headingDA,
    aankalanDescription: descriptionDA,
    adImage,
    qrImage,
  } = AankalanData["DOWNLOAD_APP"];
  const faq = AankalanData["FAQ"];

  const [selectedStep, setSelectedStep] = useState("general");
  return (
    <TitleWrapper title='Frequently Asked Question'>
      <div className='flex flex-col space-y-4 bg-white mx-7 lg:mx-28 -mt-28 rounded p-6 lg:p-16 '>
        <div className='hidden xl:flex space-x-4 sm:space-x-12'>
          {Object.keys(faq).map((item, index) => {
            return (
              <div>
                <div
                  onClick={() => setSelectedStep(item)}
                  className={`hidden xl:block ${selectedStep === item
                    ? "border-461995 border-b-4"
                    : "border-E1E1E1"
                    } font-roboto-slab p-1 sm:p-4 cursor-pointer bg-FFFFFF capitalize`}>
                  <div
                    className={`${selectedStep === index ? "text-black" : "text-2C2C2C"
                      } text-xs xl:text-lg 4xl:text-xl`}>
                    {item}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="xl:hidden">
          <Dropdown 
          selectedItem={selectedStep} 
          onClick={(item) => setSelectedStep(item)}>
            {Object.keys(faq).map((item, index) => {
              return <DropdownItem key={index} item={item} />
            })}
          </Dropdown>
        </div>
        {faq[selectedStep].map((faq, index) => (
          <FaqQues key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
      {/* <OfferSection /> */}
      <DownloadAppSection
        heading={headingDA}
        description={descriptionDA}
        adImage={adImage}
        qrImage={qrImage}
      />
    </TitleWrapper>
  );
};

export default Faq;