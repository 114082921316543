import React, { useState } from "react";
import { useHistory } from "react-router";
import FeatureFrame from "../FeatureFrame";
import Step2 from "./StepperData/Step2";
import Step3 from "./StepperData/Step3";
import { TeacherStep1 } from "./StepperData/TeacherStep1";
import TeacherStep2 from "./StepperData/TeacherStep2";
import TeacherStep3 from "./StepperData/TeacherStep3";

const Stepper = ({ heading, description, stepsArray, teacherData }) => {
  const [selectedStep, setSelectedStep] = useState(0);
  const history = useHistory();
  const flow = history.location.pathname.slice(1);

  return (
    <div className='py-10 sm:py-24'>
      <div className='text-center w-8/10-center-without-py'>
        <div dangerouslySetInnerHTML={{ __html:heading }} className='heading' />
        <div dangerouslySetInnerHTML={{ __html:description }} className='sub-heading text-8E8E8E mt-8'/>
      </div>
      {/* steps  */}
      <div className='flex justify-center space-x-8 sm:space-x-16 mt-8 lg:mt-12'>
        {stepsArray.map((step, index) => (
          <div
            onClick={() => setSelectedStep(index)}
            className={`${
              selectedStep === index
                ? "border-461995 border-b-4"
                : "border-E1E1E1"
            }   rounded-t-md w-20 lg:w-1/5 p-4 cursor-pointer bg-FFFFFF`}>
            <div
              className={`font-source-sans-pro font-bold text-sm 3xl:text-lg ${
                selectedStep === index
                  ? "text-2C2C2C"
                  : "text-8E8E8E lg:text-2C2C2C"
              } text-xs lg:block`}>
              STEP {index + 1}
            </div>
            <div
              className={`${
                selectedStep === index ? "text-2C2C2C" : "text-8E8E8E"
              } text-xs 3xl:text-base hidden lg:block`}>
              {step.stepDescription}
            </div>
          </div>
        ))}
      </div>
      {selectedStep === 0 && flow !== "coachinginstitutions" && flow!=="teachers" && (
        <FeatureFrame
          id='stepperTeacherList'
          heading={stepsArray[0].heading}
          description={stepsArray[0].description}
          cardsList={teacherData.teachersArray}
          btnTextArr={["Our Partner Teachers", "Our Partner Youtubers"]}
          theme='LIGHT'
          bgImage={false}
          btnOnClickArr={[
            () => history.push("find-teacher"),
            () => history.push("find-youtuber"),
          ]}
        />
      )}
      {selectedStep === 0 && flow === "teachers" && (
        <TeacherStep1/>
      )}
      {selectedStep === 0 && flow === "coachinginstitutions" && (
        <FeatureFrame
          id='stepperTeacherList'
          heading={stepsArray[0].heading}
          description={stepsArray[0].description}
          cardsList={teacherData.teachersArray}
          btnTextArr={["Our Partner Institutes", "Our Partner Youtubers"]}
          theme='LIGHT'
          bgImage={false}
          btnOnClickArr={[
            () => history.push("find-teacher"),
            () => history.push("find-youtuber"),
          ]}
        />
      )}

      {selectedStep === 1 &&
        flow !== "teachers" &&
        flow !== "coachinginstitutions" && (
          <Step2
            stepDescription={stepsArray[1].stepDescription}
            description={stepsArray[1].description}
            heading={stepsArray[1].data.heading}
            data={stepsArray[1].data}
          />
        )}
      {selectedStep === 1 &&
        (flow === "teachers" || flow === "coachinginstitutions") && (
          <TeacherStep2
            description={stepsArray[1].description}
            dataArray={stepsArray[1].data}
          />
        )}
      {selectedStep === 2 &&
        flow !== "teachers" &&
        flow !== "coachinginstitutions" && (
          <Step3
            stepDescription={stepsArray[2].stepDescription}
            description={stepsArray[2].description}
            data={stepsArray[2].data}
          />
        )}

      {selectedStep === 2 &&
        (flow === "teachers" || flow === "coachinginstitutions") && (
          <TeacherStep3
            description={stepsArray[2].description}
            dataArray={stepsArray[2].data}
          />
        )}
    </div>
  );
};

export default Stepper;
