import React from "react";
import BookCover from "../../Assets/Icons/BookCover.svg";
import YoutubeCover from "../../Assets/Icons/YoutubeCover.svg";
import Image from "../Image";

const AssociatedPersonCard = ({ type, category, name, designation, image }) => {
  return (
    <div className='w-full h-full relative font-source-sans-pro '>
      <div className='absolute -top-2 inset-x-0 mx-auto w-7/12 bg-FFB9B9 py-1 px-1 rounded '>
        <div className='font-semibold text-center text-2xs 3xl:text-xs text-461995 capitalize '>
          {category}
        </div>
      </div>
      <div className='mt-2 bg-61349F border border-FFB9B9 rounded flex flex-col items-center'>
        <div className='bg-white border-4 border-white mt-8 h-24 w-24 rounded-full overflow-hidden'>
          <Image
            src={image}
            alt={name}
            height='h-full'
            width='w-full'
            position='object-cover object-top'
          />
        </div>
        <div className='mt-2 mb-4'>
          <div className='font-source-sans-pro font-semibold text-center text-FFFFFF text-base line-clamp-1'>
            {name}
          </div>
          <div className='flex items-center justify-center space-x-2 font-source-sans-pro font-semibold text-center text-FFFFFF text-2xs'>
            <div>
              <img
                src={type === "YOUTUBE" ? YoutubeCover : BookCover}
                alt='assessor'
                height='h-3'
                width='w-3'
              />
            </div>
            <div className="line-clamp-1">{designation}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssociatedPersonCard;

{
  /* <div className='h-full w-full bg-461995 rounded-md px-2 relative'>
      <div className='absolute -top-4 inset-x-0 mx-auto w-1/2 bg-green-500 font-source-sans-pro font-semibold text-center text-2xs 3xl:text-xs text-461995 capitalize pt-3 pb-2'>
        {category}
      </div>
      <div>
        <div className='bg-FFFFFF mt-4'>
          <Image src={image} alt={name} height='h-20' width='w-20' />
        </div>
        <div className='pt-2 pb-4'>
          <div className='font-source-sans-pro font-semibold text-center text-FFFFFF text-base'>
            {name}
          </div>
          <div className='flex items-center justify-center space-x-2 font-source-sans-pro font-semibold text-center text-FFFFFF text-2xs'>
            <div>
              <img
                src={type === "YOUTUBE" ? YoutubeCover : BookCover}
                alt='assessor'
                height='h-3'
                width='w-3'
              />
            </div>
            <div>{designation}</div>
          </div>
        </div>
      </div>
    </div> */
}
