import React, { useState, useContext, useEffect } from "react";
import Logo from "../../../Components/Logo/Logo";
import Modal from "../../../Components/Modal";

import Interaction from "./Interaction";
import Stepper from "./Stepper";
import InteractionGirl from "../../../Assets/Images/InteractionGirl.svg";
import InteractionGirlPhone from "../../../Assets/Icons/Create-Contest/InteractionGirlPhone.png";
import { ProfileContext } from "../../../Context/ProfileContext";
import { AgreementModal } from "./AgreementModal";
import Image from "../../../Components/Image";
import { useHistory } from "react-router-dom";

const ProfileDetailTemplate = () => {
  const {
    data,
    stage,
    step,
    name,
    bankState,
    showAgreementModal,
    setShowAgreementModal,
    showInteraction,
    setShowInteraction,
  } = useContext(ProfileContext);
  const history = useHistory();
  const [backgroundColor, setBackgroundColor] = useState("461995");
  const [messageBubbleColor, setMessageBubbleColor] = useState("442485");
  const [textColor, setTextColor] = useState("B798F7");

  useEffect(() => {
    let route = history.location.pathname;
    if (route === "/create-test-series/profile") {
      setBackgroundColor("228F95");
      setMessageBubbleColor("2E959B");
      setTextColor("FFFFFF");
    }
  }, []);

  // useEffect(() => {
  //   let interactionTimer = setTimeout(() => {
  //     setShowInteraction(false);
  //     console.log("will remove timer");
  //     clearTimeout(interactionTimer);
  //     console.log("timer removed");
  //   }, 8000);
  // }, [step, stage]);

  return (
    <div className='h-calc-screen grid grid-cols-10 font-source-sans-pro'>
      <div
        className={`pt-14 col-span-0 lg:col-span-3 hidden lg:flex flex-col items-center justify-between bg-${backgroundColor}`}>
        <div className='flex flex-col items-center'>
          <Logo />
          <div className={`sub-heading text-${textColor} uppercase`}>
            CREATE YOUR PROFILE
          </div>
        </div>
        <div className='px-10'>
          <Interaction
            message={
              stage === 2 && step === 2
                ? data[stage].steps[step].message + " " + name + " !"
                : stage === 4
                ? bankState === "UPI_PAN"
                  ? data[stage].steps[step].messageA
                  : data[stage].steps[step].messageB
                : data[stage].steps[step].message
            }
            description={data[stage].steps[step].description}
            image={InteractionGirl}
            imageWidth='w-48 3xl:w-56 4xl:w-72'
            imageHeight='h-112 3xl:h-auto'
            messageBubbleColor={messageBubbleColor}
          />
        </div>
      </div>
      <div className='col-span-10 lg:col-span-7 bg-FFF5E3'>
        <Stepper />
      </div>
      {/* {showInteraction && (
        <div className='bounce-interaction block lg:hidden absolute bottom-5 left-4 right-4 sm:left-10 sm:right-10 bg-FDEFD5 rounded-md border-2 border-FFFFFF shadow-md p-5'>
          <div className='flex items-center space-x-6'>
            <div className='h-16 max-h-20 w-24 rounded-full overflow-hidden'>
              <Image
                src={InteractionGirlPhone}
                alt='girl'
                width='w-full'
                height='h-full'
                position='object-contain'
              />
            </div>
            <div className='text-sm sm:text-lg text-black'>
              {stage === 1 && step === 2
                ? data[stage].steps[step].message + " " + name + " !"
                : stage === 4
                ? bankState === "UPI_PAN"
                  ? data[stage].steps[step].messageA
                  : data[stage].steps[step].messageB
                : data[stage].steps[step].message}{" "}
              {data[stage].steps[step].description}
            </div>
          </div>
        </div>
      )} */}
      <Modal
        visible={showAgreementModal}
        close={() => setShowAgreementModal(false)}
        width='w-11/12 md:w-10/12'>
        <AgreementModal close={() => setShowAgreementModal(false)} />
      </Modal>
    </div>
  );
};

export default ProfileDetailTemplate;
