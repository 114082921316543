import React, { useState } from "react";
import downArrow from "../../Assets/Icons/arrow-dropdown.svg"

const FaqQues = ({ question, answer }) => {
  const [showContent, setShowContent] = useState(false);
  return (
    <div
      className='px-0 pb-4 lg:px-16 lg:py-7 lg:rounded-md border-b-2 lg:border border-E4E4E4 font-source-sans-pro relative cursor-pointer lg:hover:bg-FAFAFA'
      onClick={() => setShowContent(!showContent)}>
      <div
        className={`text-sm sm:text-base lg:text-2xl mr-10`}>
        {question}
      </div>
      <div className={`text-ACACAC text-2xs sm:text-sm lg:text-lg w-11/12 ${showContent ? "max-h-screen" : "max-h-0"}  transition-all overflow-hidden duration-300`}>
        <div className="py-2 xl:py-4">{answer}</div>
      </div>
      <div className='absolute top-0 right-2 lg:top-6 lg:right-4'>
        <img  src={downArrow} className={`h-4 sm:h-auto ${showContent?" rotate-180":"rotate-0"} transform transition-all`} />
      </div>
    </div>
  );
};
export default FaqQues;
