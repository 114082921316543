import React, { useState } from "react";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import RadioButton from "../../Components/Radio/Radio.Button";
import RadioGroup from "../../Components/Radio/Radio.Group";
import RadioTabs from "../../Components/Radio/Radio.Tab";
import { TitleWrapper } from "../../Components/TitleWrapper";

import Tution from "../../Assets/Icons/Tution.svg";
import SelfStudy from "../../Assets/Icons/SelfStudy.svg";
import College from "../../Assets/Icons/College.svg";
import DigitalClasses from "../../Assets/Icons/DigitalClasses.svg";
import others from "../../Assets/Icons/Registration/others.svg";

import knowledge from '../../Assets/Icons/Registration/knowledge.png'
import teach from '../../Assets/Icons/Registration/teach.png'
import police from '../../Assets/Icons/Registration/police.png'
import engineering from '../../Assets/Icons/Registration/engineering.png'
import ssc from '../../Assets/Icons/Registration/ssc.png'
import medicine from '../../Assets/Icons/Registration/medicine.png'





import Modal from "../../Components/Modal";
import Congrats from "../Modals/Congrats";
import { Link } from "react-router-dom";
import SelectGroup from "../../Components/CustomSelect/SelectGroup";
import SelectOption from "../../Components/CustomSelect/SelectOption";
// import StateCityData from "../../Data/StateCity.json";

let StateCityData = require('../../Data/StateCity.json')

const redundantStates = StateCityData.map((item, index) => item.state);
const states = Array.from(new Set(redundantStates));
const cities = StateCityData.map((item, index) => item.name);

const StudentRegistration = () => {
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [exam, setExam] = useState('engg')
  const [prepMode, setPrepMode] = useState("Self-Study");
  const [prepModeSec, setPrepModeSec] = useState("Self-Study");

  // Form Data State Management
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [gender, setGender] = useState("male");

  return (
    <div className='bg-F7F7FF'>
      <TitleWrapper title='Student Registration'>
        <div className='py-10 sm:py-24 mx-7 lg:mx-16 xl:mx-28 -mt-28 rounded bg-white px-6 lg:px-16 xl:px-32'>
          <div className='text-center'>
            <div className='heading'>Complete Your Registration</div>
            <div className='sub-heading mt-4'>
              Enter your details to set up your student account
            </div>
          </div>
          <div className='grid grid-cols-2 gap-8 mt-20'>
            <div className='col-span-2 sm:col-span-1'>
              <Input placeholder='Enter Your First Name' title='First Name' />
            </div>
            <div className='col-span-2 sm:col-span-1'>
              <Input placeholder='Enter Your Last Name' title='Last Name' />
            </div>
            <div className='col-span-2 sm:col-span-1'>
              <Input placeholder='DD/MM/YYYY' title='Date Of Birth' />
            </div>
            <div className='col-span-2 sm:col-span-1'>
              <RadioGroup
                selectedValue={gender}
                title='Gender'
                onClick={(value) => setGender(value)}>
                <RadioButton value='male'>male</RadioButton>
                <RadioButton value='female'>female</RadioButton>
              </RadioGroup>
            </div>
            <div className='col-span-2 sm:col-span-1'>
              <SelectGroup
                id='state'
                placeholder='Select Your State'
                title='State'
                selectedOption={selectedState}
                selectOptionHandler={(value) => {
                  setSelectedState(value);
                }}>
                {states.map((state, index) => (
                  <SelectOption value={state}>{state}</SelectOption>
                ))}
              </SelectGroup>
            </div>
            <div className='col-span-2 sm:col-span-1'>
              <SelectGroup
                id='city'
                placeholder='Select Your City'
                title='City'
                selectedOption={selectedCity}
                selectOptionHandler={(value) => {
                  setSelectedCity(value);
                }}>
                {cities.map((city, index) => (
                  <SelectOption value={city}>{city}</SelectOption>
                ))}
              </SelectGroup>
            </div>
            <div className='col-span-2'>
              <RadioGroup
                selectedValue={exam}
                title='What Competitive Examination Are You Preparing For?'
                onClick={(value) => setExam(value)}>
                <RadioTabs icon={engineering} value='engg'>
                  Engg.
                </RadioTabs>
                <RadioTabs icon={medicine} value='medical'>
                  Medical
                </RadioTabs>
                <RadioTabs icon={ssc} value='ssc'>
                  SSC
                </RadioTabs>
                <RadioTabs icon={knowledge} value='banking'>
                  Banking
                </RadioTabs>
                <RadioTabs icon={teach} value='teaching'>
                  Teaching
                </RadioTabs>
                <RadioTabs icon={ssc} value='VDO'>
                  VDO
                </RadioTabs>
                <RadioTabs icon={knowledge} value='railway'>
                  Railway
                </RadioTabs>
                <RadioTabs icon={police} value='police'>
                  Police
                </RadioTabs>
                <RadioTabs icon={police} value='defence'>
                  Defence
                </RadioTabs>
              </RadioGroup>
            </div>
            <div className='col-span-2'>
              <RadioGroup
                selectedValue={prepMode}
                title='What is your Primary mode of preparation ?'
                onClick={setPrepMode}>
                <RadioTabs icon={SelfStudy} value='Self-Study'>
                  Self Study
                </RadioTabs>
                <RadioTabs icon={Tution} value='Tution'>
                  Tution
                </RadioTabs>
                <RadioTabs icon={College} value='Institutional-Coaching'>
                  Institutional Coaching
                </RadioTabs>
                <RadioTabs icon={DigitalClasses} value='Digital-Classes'>
                  Digital Classes Online / App
                </RadioTabs>

                <RadioTabs icon={others} value='others'>
                  Others
                </RadioTabs>
              </RadioGroup>
            </div>
            <div className='col-span-2'>
              <RadioGroup
                selectedValue={prepModeSec}
                title='What is your Secondary mode of preparation ? (Optional)'
                onClick={setPrepModeSec}>
                <RadioTabs icon={SelfStudy} value='Self-Study'>
                  Self Study
                </RadioTabs>
                <RadioTabs icon={Tution} value='Tution'>
                  Tution
                </RadioTabs>
                <RadioTabs icon={College} value='Institutional-Coaching'>
                  Institutional Coaching
                </RadioTabs>
                <RadioTabs icon={DigitalClasses} value='Digital-Classes'>
                  Digital Classes Online / App
                </RadioTabs>
                <RadioTabs icon={others} value='others'>
                  Others
                </RadioTabs>
              </RadioGroup>
            </div>
            <div className='col-span-2 font-soure-sans-pro font-semibold text-xl'>
              Parent's Details (Optional)
            </div>
            <div className='col-span-2 sm:col-span-1'>
              <Input placeholder='Enter Your First Name' title='First Name' />
            </div>
            <div className='col-span-2 sm:col-span-1'>
              <Input placeholder='Enter Your Last Name' title='Last Name' />
            </div>
            <div className='col-span-2 sm:col-span-1'>
              <Input placeholder="Enter Your Parent's Email" title='Email Id' />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row justify-center mt-20'>
            <Button
              onClick={() => {
                window.scrollTo(0, 0);
                setShowCongratsModal(true);
              }}
              width='w-full sm:w-60'
              height='h-12 sm:h-16'
              textTransform='capitalize'>
              Register As a student
            </Button>
            <Link to='/students'>
              <Button
                className=''
                bgColor='white'
                textColor='black'
                width='w-full sm:w-60'
                height='h-12 sm:h-16'
                textTransform='capitalize'>
                Take Me Back To Homepage
              </Button>
            </Link>
          </div>
        </div>
        <Modal
          visible={showCongratsModal}
          isClosable={false}
          width='w-11/12 md:w-10/12'>
          <Congrats close={() => setShowCongratsModal(false)} />
        </Modal>
      </TitleWrapper>
    </div>
  );
};

export default StudentRegistration;
