import React from "react";

const RadioTag = (props) => {
  return (
    <div
      onClick={() => props.onClick(!props.selected)}
      className={`flex items-center space-x-2 `}>
      <div
        className={`cursor-pointer ${
          props.selected ? "border-158900" : "border-gray-900"
        }    bg-E9E9FF border rounded-full py-0.5 px-3`}>
        <i
          className={`fas fa-check ${
            props.selected ? "text-158900" : "text-gray-400"
          } text-sm lg:text-base`}></i>
      </div>
      <div className='text-sm lg:text-base'>{props.children}</div>
    </div>
  );
};
export default RadioTag;
