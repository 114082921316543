import React from "react";
import { useWindowDimensions } from "../../CustomHooks/ScreenH-W";
import SoftwareEnggWithBg from "../../Assets/Icons/SoftwareEnggWithBg.svg";

const OurFeatures = ({
  heading,
  statsArray,
  statsImages,
  bordered = false,
}) => {
  const { width, height } = useWindowDimensions();

  const Card = ({ heading, description, image, bordered = false }) => {
    return (
      <div
        className={`w-full h-full bg-FFFFFF pt-10 3xl:pt-16 pb-20 3xl:pb-24 px-10 2xl:px-16 flex flex-col items-center space-y-4 ${
          width >= 992
            ? bordered
              ? "border"
              : "border-t border-l border-r"
            : "border"
        }  border-DDDDDD  rounded-t-sm`}>
        <div>
          <img src={image} alt='software engg' height='' width='' />
        </div>
        <div className='font-source-sans-pro font-semibold text-center text-lg md:text-xl lg:text-2xl 3xl:text-3xl  text-FF9F00 capitalize'>
          {heading}
        </div>
        <div className='font-source-sans-pro text-center text-base md:text-lg lg:text-xl 3xl:text-2xl text-black'>
          {description}
        </div>
      </div>
    );
  };
  return (
    <div
      className={`bg-FFFFFF ${
        width >= 992
          ? "w-8/10-center-without-py pt-20 sm:pt-24 3xl:pt-28"
          : "py-10 sm:py-24"
      } space-y-8 lg:space-y-20 3xl:space-y-24`}>
      {heading ? (
        <div
          dangerouslySetInnerHTML={{ __html: heading }}
          className='heading font-source-sans-pro font-semibold text-center'
        />
      ) : (
        <div className='w-3/4 xl:lg-2/3 mx-auto font-source-sans-pro font-bold text-center text-black text-xl sm:text-2xl lg:text-3xl xl:text-4xl 3xl:text-5xl'>
          " Aankalan is a one-stop-shop for all things
          <br /> Competitive Examination -{" "}
          <span className='text-461A95'>
            Third Party Assessments, a Marketplace for Tests, and a Student -
            Teacher Community!
          </span>
          "
        </div>
      )}
      <div className={`${width >= 992 ? "" : "w-9/10-left-without-py"}`}>
        <div className='flex space-x-4 overflow-without-scrollbar'>
          {statsArray.map((item, index) => (
            <div className='xl:w-full'>
              <div className='w-72 lg:w-80 xl:w-full h-full'>
                <Card
                  key={index}
                  heading={item.heading}
                  description={item.description}
                  image={statsImages[index]}
                  bordered={bordered}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurFeatures;
