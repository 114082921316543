import React from "react";
import { useHistory } from "react-router";

export const TitleWrapper = ({ title, children }) => {
  const history = useHistory();
  return (
    <div className='mt-16 lg:mt-20'>
      <div className='px-7 lg:px-28 bg-gray-900 h-56 lg:h-80'>
        <div className='flex pt-9 lg:pt-32 items-center'>
          <i
            onClick={() => history.goBack()}
            class='fas fa-arrow-left text-lg sm:text-2xl text-white cursor-pointer'></i>
          <div className='heading text-white ml-4'>{title}</div>
        </div>
      </div>
      {children}
    </div>
  );
};
