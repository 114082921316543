import React, { useState } from "react";
import HeroSection from "../Sections/HeroSection";
import Jumbotron from "../../Components/Jumbotron/Jumbotron";
import StatsSection from "../Sections/StatsSection";
// import JumbotronData from "../../Data/Jumbotron.json";
// import TeachersData from "../../Data/Teacher.json";
// import TestimonialData from "../../Data/TestimonialData.json";
// import YoutuberData from "../../Data/YoutuberData.json";
import ScoreAnalysis from "../Sections/ScoreAnalysis";
// import teacherData from "../../Data/TeachersData.json";
import Stepper from "../Sections/StepSection/Stepper";
import CoachingInstituteFeatureSection from "../Sections/CoachingInstituteFeatureSection";

import Cloud from "../../Assets/Icons/Cloud.svg";
// import YoutubeWhite from "../../Assets/Icons/YoutubeWhite.svg";
import FeatureSection from "../Sections/FeatureSection";
// import FunSection from "../Sections/FunSection";
import OfferSection from "../Sections/OfferSection";
import DownloadAppSection from "../Sections/DownloadAppSection";
import Modal from "../../Components/Modal";
import ContactUs from "../Modals/ContactUs";
import { useHistory } from "react-router";
import TestimonialSection from "../Sections/TestimonialSection";
import FeatureFrame from "../Sections/FeatureFrame";
// import AankalanData from "../../Data/Aankalan.json";
import graduateUser from "../../Assets/Icons/graduateUser.svg";
import setting from "../../Assets/Icons/Teacher-Coaching-Flow/setting.svg";
import school from "../../Assets/Icons/Teacher-Coaching-Flow/school.svg";
import higherStudies from "../../Assets/Icons/Teacher-Coaching-Flow/higher-studies.svg";

import coachingCommunity from "../../Assets/Icons/Teacher-Coaching-Flow/coaching-community.svg";
import teacherCommunity from "../../Assets/Icons/Teacher-Coaching-Flow/teacher-community.svg";

import BrainLogo from "../../Assets/Icons/BrainLogo.svg";
import RankLogo from "../../Assets/Icons/RankLogo.svg";
import BrainBookLogo from "../../Assets/Icons/BrainBookLogo.svg";
import ScholarshipLogo from "../../Assets/Icons/ScholarshipLogo.svg";
import TeacherStat1 from "../../Assets/Images/TeacherStat1.png";
import TeacherStat2 from "../../Assets/Images/TeacherStat2.svg";
import TeacherStat3 from "../../Assets/Images/TeacherStat3.svg";


let AankalanData = require('../../Data/Aankalan.json')
let JumbotronData = require('../../Data/Jumbotron.json')
let TeachersData = require('../../Data/Teacher.json')
let TestimonialData = require('../../Data/TestimonialData.json')
let teacherData = require('../../Data/TeachersData.json')



const Teacher = () => {
  const history = useHistory();
  const [showContactModal, setShowContactModal] = useState(false);
  const {
    heading: headingFAQ,
    teacherDescription: descriptionFAQ,
    btnText: btnTextFAQ,
  } = JumbotronData["FAQ"];
  const {
    heading: headingCU,
    teacherDescription: descriptionCU,
    btnText: btnTextCU,
  } = JumbotronData["CONTACT_US"];
  const { heading, heading2, description, statsArray } =
    TeachersData["STATS_SECTION"];
  const {
    heading: headingRTC,
    description: descriptionRTC,
    statsArray: statsArrayRTC,
  } = TeachersData["REASON_TO_CHOOSE"];

  const { text: textHS, statsArray: statsarrayHS } =
    TeachersData["HERO_SECTION"];
  const {
    heading: headingSS,
    stepsArray,
    description: descriptionSS,
  } = TeachersData["STEP_SECTION"];
  const {
    heading: headingFS,
    description: descriptionFS,
    image: imageFS,
  } = TeachersData["FEATURE_SECTION"];

  const {
    heading: headingTS,
    description: descriptionTS,
    testimonials,
  } = TestimonialData;
  const {
    teacherHeading: headingDA,
    teacherDescription: descriptionDA,
    adImage,
    qrImage,
  } = AankalanData["DOWNLOAD_APP"];

  const {
    heading: headingUSP,
    description: descriptionUSP,
    statsArray: statsArrayUSP,
  } = TeachersData["USP_SECTION"];

  // const percentageColorArray = ["EADA7CE5", "FD757D", "77DDB9"];
  const uspImageArray = [
    graduateUser,
    school,
    setting,
    higherStudies,
    teacherCommunity,
    coachingCommunity,
  ];

  const statsImagesRTC = [BrainBookLogo, BrainLogo, RankLogo, ScholarshipLogo];

  const statSectionImages = [TeacherStat1, TeacherStat2, TeacherStat3];

  return (
    <div className='mt-16 lg:mt-20 3xl:mt-24'>
      <HeroSection text={textHS} data={statsarrayHS} />
      <CoachingInstituteFeatureSection
        heading={headingUSP}
        description={descriptionUSP}
        statsArray={statsArrayUSP}
        uspImageArray={uspImageArray}
      />
      <StatsSection
        bgColor='gradient'
        textColor='FFFFFF'
        heading={heading}
        heading2={heading2}
        description={description}
        statsArray={statsArray}
        statsImages={statSectionImages}
        // percentageColor={percentageColorArray}
        statCardColor='FFFFFF'
        statsCardHeadingColor='000000'
        statsCardDescriptionColor='8B8B8B'
      />
      <Stepper
        heading={headingSS}
        stepsArray={stepsArray}
        description={descriptionSS}
        teacherData={teacherData}
      />
      <FeatureFrame
          id='stepperTeacherList'
          heading={stepsArray[0].heading}
          description={stepsArray[0].description}
          cardsList={teacherData.teachersArray}
          btnTextArr={["Our Partner Institutes", "Our Partner Youtubers"]}
          theme='LIGHT'
          bgImage={false}
          btnOnClickArr={[
            () => history.push("find-teacher"),
            () => history.push("find-youtuber"),
          ]}
      />
      <ScoreAnalysis
        heading={headingRTC}
        description={descriptionRTC}
        isDividerVisibile={false}
        statsArray={statsArrayRTC}
        statsImages={statsImagesRTC}
        bgColor='gradient'
        textColor='FFFFFF'
      />
      <FeatureSection
        heading={headingFS}
        image={imageFS}
        description={descriptionFS}
      />
      <TestimonialSection
        heading={headingTS}
        description={descriptionTS}
        testimonialsList={testimonials}
      />
      {/*  <OfferSection /> */}
      <DownloadAppSection
        heading={headingDA}
        description={descriptionDA}
        adImage={adImage}
        qrImage={qrImage}
      />
      <Jumbotron
        bgColor='F5F5F5'
        heading={headingFAQ}
        description={descriptionFAQ}
        btnText={btnTextFAQ}
        headingColor='000000'
        descriptionColor='000000'
        src={Cloud}
        alt='cloud'
        height='11'
        width='11'
        position=''
        buttonOnclick={() => {
          window.scrollTo(0, 0);
          history.push("/faq");
        }}
      />
      <Jumbotron
        bgColor='230D4B'
        heading={headingCU}
        description={descriptionCU}
        btnText={btnTextCU}
        headingColor='FFFFFF'
        descriptionColor='FFFFFF'
        btnColor='FFFFFF'
        btnTextColor='000000'
        src={""}
        alt=''
        height=''
        width=''
        position=''
        buttonOnclick={() => {
          window.scrollTo(0, 0);
          setShowContactModal(true);
        }}
      />
      <Modal
        visible={showContactModal}
        close={() => setShowContactModal(false)}
        width='w-11/12 md:w-10/12'>
        <ContactUs close={() => setShowContactModal(false)} />

      </Modal>
    </div>
  );
};

export default Teacher;
