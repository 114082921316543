import React from "react";
import GooglePlayButton from "../../Components/GooglePlayButton";
import { useWindowDimensions } from "../../CustomHooks/ScreenH-W";
import AssociatedPeople from "./AssociatedPeople";

const AboutUsHeroSection = ({
  heading,
  description,
  features,
  associatedPeople,
}) => {
  const { width, height } = useWindowDimensions();

  const scrollDown = () => {
    window.scrollTo(0, height - 100);
  };
  return (
    <div className='bg-gradient'>
      <div className='w-8/10-center-without-py py-12 md:py-20 xl:py-9 2xl:py-20 3xl:py-32 xl:h-90vh text-center space-y-8 md:space-y-16 xl:space-y-7 2xl:space-y-10 3xl:space-y-20 font-source-sans-pro'>
        <div className='space-y-2'>
          <div
            dangerouslySetInnerHTML={{ __html: heading }}
            className='font-black text-center text-2xl md:text-3xl xl:text-5xl 3xl:text-6xl text-FFA300 capitalize'
          />
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className='text-center text-sm md:text-base lg:text-xl xl:text-2xl 3xl:text-3xl text-FFFFFF'
          />
        </div>
        <div className='w-3/4 sm:w-full mx-auto'>
          <div className='flex flex-col md:flex-row space-y-4 md:space-x-4 md:space-y-0 justify-center'>
            {features.map((feature, index) => (
              <div className='bg-230d4b border-2 border-FFA200 p-3 sm:py-4 sm:px-4 lg:px-6 font-semibold text-center text-sm sm:text-xs lg:text-sm xl:text-xl 3xl:text-2xl text-FFA200 rounded-md'>
                {feature}
              </div>
            ))}
          </div>
        </div>
        {/**Slider section */}
        <div className='w-3/4 xl:w-10/12 2xl:w-9/12 mx-auto'>
          <AssociatedPeople associatedPeople={associatedPeople} />
        </div>
        <div className='flex items-center justify-center'>
          {/* <div className='text-xs md:text-base lg:text-xl xl:text-2xl 3xl:text-3xl text-FFFFFF'>
            Download the app now
          </div>
          <GooglePlayButton /> */}
          <div
            onClick={() => scrollDown()}
            className='flex-centerXY w-10 2xl:w-16 h-10 2xl:h-16 rounded-full bg-230d4b cursor-pointer border-2 border-white'>
            <ion-icon
              className='down-arrow'
              style={{
                fontSize: width > 768 ? "40px" : "25px",
                color: "white",
              }}
              name='arrow-down'></ion-icon>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHeroSection;
