const SocialInput = ({
  onChange = () => console.warn("Mention onChange"),
  value,
  onClick,
  placeholder,
  info,
  selected,
  icon
}) => {
  return (
    <div className={`font-soure-sans-pro p-1 ${selected?" w-full":"w-16 sm:w-20"} flex items-center ${selected?"bg-white":"bg-transparent"} space-x-2 sm:space-x-4 rounded-lg shadow`}>
      <img onClick={onClick} src={icon} className="h-8 sm:h-12 w-auto cursor-pointer"/>
      {selected && <input
        className={`3xl:text-xl w-full focus:outline-none h-8 sm:h-12`}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={placeholder}
      />}
    </div>
  );
};
export default SocialInput;
