import React from "react";
import ScoreAnalysis from "../Sections/ScoreAnalysis";

const OurSolution = ({ heading, statsArray, statsImages }) => {
  return (
    <div>
      <ScoreAnalysis
        heading={heading}
        description=''
        isDividerVisibile={false}
        statsArray={statsArray}
        statsImages={statsImages}
      />
    </div>
  );
};

export default OurSolution;
