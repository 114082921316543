import React from 'react'
import PrivacyPolicy from '../Components/PrivacyPolicy'
import { TitleWrapper } from '../Components/TitleWrapper'

export default () => {
    return (
        <TitleWrapper title='Privacy Policy'>
            <div className='flex flex-col space-y-4 bg-white mx-7 lg:mx-28 -mt-28 rounded p-6 lg:p-16 '>
                <PrivacyPolicy />
            </div>
        </TitleWrapper>
    )
}
