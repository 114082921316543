import React from "react";
import Button from "../../Components/Button";
import Image from "../../Components/Image";
import PhoneInput from "../../Components/PhoneInput";
// import aankalanData from "../../Data/Aankalan.json";

export default function DownloadAppSection({
  heading,
  description,
  adImage,
  qrImage,
}) {
  return (
    <div className='w-8/10-center'>
      <div className='text-center font-open-sans'>
        {heading && (
          <div
            dangerouslySetInnerHTML={{ __html: heading }}
            className='heading capitalize'
          />
        )}
      </div>
      <div className='grid grid-cols-2 items-center gap-8 mt-8 lg:mt-14'>
        <div className='col-span-2 lg:col-span-1 rounded-md overflow-hidden'>
          <a
            href={"/"}
            target='_blank'
            rel='noopener noreferrer'>
            <Image src={adImage} width='full' height='full' />
          </a>
        </div>
        <div className='col-span-2 lg:col-span-1 rounded-md overflow-hidden h-full w-full bg-FAD6D6 flex flex-col justify-center items-center'>
          <div className='rounded-lg overflow-hidden h-2/3 w-2/3'>
            <Image src={qrImage} width='w-full' height='h-full' />
          </div>

          <div className='mt-8'>Scan QR code to download the app</div>
        </div>
      </div>
      {/* <div className='hidden lg:block mt-32 mx-auto'>
        <div dangerouslySetInnerHTML={{ __html: description }} className='heading font-semibold text-center' />
        <div className='lg:flex justify-center mt-8 space-x-3'>
          <PhoneInput placeholder='Enter Your Number' />
          <Button width='w-60' textTransform='uppercase'>
            Send
          </Button>
        </div>
      </div> */}
      <div className='flex justify-center mt-8 lg:mt-16'>
        <a
          href={"/"}
          target='_blank'
          rel='noopener noreferrer'>
          <Button width='w-60' textTransform='uppercase'>
            Know More
          </Button>
        </a>
      </div>
    </div>
  );
}
