'use strict';
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import AboutUs from "./about";
import AboutUsHeroSection from "./AboutUsHeroSection";
import OurJourney from "./OurJourney";
// import OurService from "./OurService";
import OurSolution from "./OurSolution";
// import OurTeam from "./OurTeam";
import OurWorking from "./OurWorking";
import Jumbotron from "../../Components/Jumbotron/Jumbotron";
import DownloadAppSection from "../Sections/DownloadAppSection";
// import AankalanData from "../../Data/Aankalan.json";
// import JumbotronData from "../../Data/Jumbotron.json";
// import TeachersData from "../../Data/TeachersData.json";


import SoftwareEngg from "../../Assets/Images/SoftwareEngg.svg";
import SoftwareEnggWithBg from "../../Assets/Icons/SoftwareEnggWithBg.svg";
import DataScientist from "../../Assets/Images/DataScientist.svg";
import CurriculumTheorist from "../../Assets/Images/CurriculumTheorist.svg";
import Cognitive from "../../Assets/Images/Cognitive.svg";
import Cloud from "../../Assets/Icons/Cloud.svg";
import InstructionalResearchers from "../../Assets/Images/InstructionalResearchers.svg";
import BrainBookLogo from "../../Assets/Icons/BrainBookLogo.svg";
import ContentLogo from "../../Assets/Icons/ContentLogo.svg";
import HumanSettingLogo from "../../Assets/Icons/HumanSettingLogo.svg";
import BrainLogo from "../../Assets/Icons/BrainLogo.svg";
import RankLogo from "../../Assets/Icons/RankLogo.svg";
import MockTestLogo from "../../Assets/Icons/MockTestLogo.svg";
import MonitorLogo from "../../Assets/Icons/MonitorLogo.svg";
import ScholarshipLogo from "../../Assets/Icons/ScholarshipLogo.svg";

import digital from "../../Assets/Icons/AboutUs/digital-withoutBG.svg";
import map from "../../Assets/Icons/AboutUs/map-withoutBG.svg";
import subscribers from "../../Assets/Icons/AboutUs/subscribers-withoutBG.svg";
import contest from "../../Assets/Icons/AboutUs/national-withoutBG.svg";
import multipleTest from "../../Assets/Icons/AboutUs/multiple-test-withoutBG.svg";
import QA from "../../Assets/Icons/AboutUs/QA-withoutBG.svg";
import CirriculumTheorist from "../../Assets/Icons/CirriculumTheorist.svg";
import CognitivePhysc from "../../Assets/Icons/CognitivePhysc.svg";

import assessment from "../../Assets/Icons/AboutUs/assessment-withoutBG.svg";
import skill from "../../Assets/Icons/AboutUs/skill-withoutBG.svg";
import growth from "../../Assets/Icons/AboutUs/growth-withoutBG.svg";
import bright from "../../Assets/Icons/AboutUs/bright-withoutBG.svg";

import Modal from "../../Components/Modal";
import ContactUs from "../Modals/ContactUs";
// import OurCollaborators from "./OurCollaborators";
// import Timeline from "./Timeline";
// import AppScreenSlider from "./AppScreenSlider";
import OurSquad from "./OurSquad";
import { OurGallery } from "./OurGallery";
import OurFeatures from "./OurFeatures";
import ScholarshipSection from "../Sections/ScholarshipSection";

import { getAnalytics, logEvent } from "firebase/analytics";

let AankalanData = require('../../Data/Aankalan.json')
let JumbotronData = require('../../Data/Jumbotron.json')
let TeachersData = require('../../Data/TeachersData.json')





const Aankalan = () => {
  const history = useHistory();
  const [showContactModal, setShowContactModal] = useState(false);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'HOME', {
      landed: "true"
    });


  }, [])

  const {
    heading: headingFAQ,
    aankalanDescription: descriptionFAQ,
    btnText: btnTextFAQ,
  } = JumbotronData["FAQ"];
  const {
    heading: headingCU,
    aankalanDescription: descriptionCU,
    btnText: btnTextCU,
  } = JumbotronData["CONTACT_US"];

  // const { companyName } = AankalanData;
  const {
    heading: headingHS,
    description: descriptionHS,
    features,
  } = AankalanData["HERO_SECTION"];

  const {teachersArray:associatedPeople} = TeachersData;

  const { statsArray: statsArrayOF } = AankalanData["OUR_FEATURE"];
  const { statsArray: statsArrayOJ } = AankalanData["OUR_JOURNEY"];
  const { heading: headingOU, statsArray: statsArrayOU } =
    AankalanData["OUR_USP"];
  const {
    heading: headingAS,
    description: descriptionAS,
    statsArray: statsArrayAS,
  } = AankalanData["ABOUT_SECTION"];

  const { heading: headingOS, statsArray: statsArrayOS } =
    AankalanData["OUR_SERVICE"];
  const { heading: headingOSS, statsArray: statsArrayOSS } =
    AankalanData["OUR_SOLUTIONS"];
  // const { heading: headingOT, members } = AankalanData["OUR_TEAM"];
  // const { heading: headingOC, collaborators } = AankalanData["OUR_COLLABORATORS"];

  const { heading: headingSCL } = AankalanData["SCHOLARSHIP"];
  const { heading: headingG, images: imagesG } = AankalanData["GALLERY"];
  const {
    aankalanHeading: headingDA,
    adImage,
    qrImage,
    aankalanDescription: descriptionDA,
  } = AankalanData["DOWNLOAD_APP"];

  const featureImagesOS = [
    SoftwareEngg,
    DataScientist,
    CurriculumTheorist,
    Cognitive,
    InstructionalResearchers,
  ];

  const ourSolutionImages = [
    BrainBookLogo,
    ContentLogo,
    HumanSettingLogo,
    BrainLogo,
    MockTestLogo,
    MonitorLogo,
    RankLogo,
    ScholarshipLogo,
  ];

  const statsImagesOJ = [digital, map, subscribers, QA, contest, multipleTest];
  const statsImagesOF = [
    SoftwareEnggWithBg,
    SoftwareEnggWithBg,
    SoftwareEnggWithBg,
  ];
  const statsImagesOS = [
    SoftwareEnggWithBg,
    CognitivePhysc,
    CirriculumTheorist,
  ];

  const statsImagesOU = [assessment, skill, growth, bright];

  return (
    <div className='mt-16 lg:mt-20 3xl:mt-24'>
      <AboutUsHeroSection
        heading={headingHS}
        description={descriptionHS}
        features={features}
        associatedPeople={associatedPeople}
      />
      <OurFeatures statsArray={statsArrayOF} statsImages={statsImagesOF} />
      <AboutUs
        heading={headingAS}
        description={descriptionAS}
        statsArray={statsArrayAS}
      />
      <OurSolution
        heading={headingOSS}
        statsArray={statsArrayOSS}
        statsImages={ourSolutionImages}
      />
      <OurWorking
        heading={headingOU}
        statsArray={statsArrayOU}
        statsImages={statsImagesOU}
      />
      {/* <OurSquad
        heading={headingOS}
        features={featuresOS}
        featureImages={featureImagesOS}
      /> */}
      <OurFeatures
        heading={headingOS}
        statsArray={statsArrayOS}
        statsImages={statsImagesOS}
        bordered={true}
      />

      {/* <AppScreenSlider heading='Our Products' /> */}

      {/* <Timeline /> */}
      <OurJourney statsArray={statsArrayOJ} statsImages={statsImagesOJ} />
      <ScholarshipSection heading={headingSCL} />
      <OurGallery heading={headingG} images={imagesG} />
      {/* <OurCollaborators heading={headingOC} collaborators={collaborators} /> */}
      {/* <OurTeam heading={headingOT} members={members} /> */}
      <DownloadAppSection
        heading={headingDA}
        adImage={adImage}
        qrImage={qrImage}
        description={descriptionDA}
      />
      <Jumbotron
        bgColor='F5F5F5'
        heading={headingFAQ}
        description={descriptionFAQ}
        btnText={btnTextFAQ}
        headingColor='000000'
        descriptionColor='000000'
        src={Cloud}
        alt='cloud'
        height='11'
        width='11'
        position=''
        buttonOnclick={() => {
          window.scrollTo(0, 0);
          history.push("/faq");
        }}
      />
      <Jumbotron
        bgColor='230D4B'
        heading={headingCU}
        description={descriptionCU}
        btnText={btnTextCU}
        headingColor='FFFFFF'
        descriptionColor='FFFFFF'
        btnColor='FFFFFF'
        btnTextColor='000000'
        src={""}
        alt=''
        height=''
        width=''
        position=''
        buttonOnclick={() => {
          window.scrollTo(0, 0);
          setShowContactModal(true);
        }}
      />
      <Modal
        visible={showContactModal}
        close={() => setShowContactModal(false)}
        width='w-11/12 md:w-10/12'>
        <ContactUs close={() => setShowContactModal(false)} />

      </Modal>
    </div>
  );
};

export default Aankalan;
