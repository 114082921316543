import React, { useState, useEffect, useContext } from "react";
import ContestDetails from "./Forms/ContestDetails";
import QuizEditor from "./Forms/QuizEditor";
import QuizPreviewer from "./Forms/QuizPreviewer";
import ProgressContainer from "./Forms/ProgressContainer";
import { Link, useHistory } from "react-router-dom";
import { ProfileService } from "../../Services/profile.service";
import { ProfileContext } from "../../Context/ProfileContext";
import { formatDate } from "../../Utility/Helper";
import Modal from "../../Components/Modal";
import Button from "../../Components/Button";
// import BackArrow from "../../Assets/Icons/Create-Contest/back.svg";
import Tick from "../../Assets/Icons/Create-Contest/voilet-tick.svg";
import icon1 from "../../Assets/Icons/Create-Contest/sparkle-left.svg";
import icon2 from "../../Assets/Icons/Create-Contest/sparkle-right.svg";
import toast from "react-hot-toast";

const Form = () => {
  const history = useHistory();
  const { userID } = useContext(ProfileContext);
  const [stage, setStage] = useState(0);
  const [contestName, setContestName] = useState("");
  const [date, setDate] = useState();
  const [time, setTime] = useState("10:00");
  const [fee, setFee] = useState("");
  const [noOfParticipants, setNoOfParticipants] = useState("");
  const [commission, setCommission] = useState("");
  const [questions, setQuestions] = useState([
    {
      title: "",
      answer_1: "",
      answer_2: "",
      answer_3: "",
      answer_4: "",
      explaination: "",
      correct_answer: [],
    },
  ]);

  const [exam, setExam] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectsList, setSubjectsList] = useState([]);
  const [examsList, setExamsList] = useState([]);

  const [level, setLevel] = useState("");
  const [cashPoints, setCashPoints] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userID) {
      history.push("/create-contest");
    }
    window.onbeforeunload = function () {
      if (history.location.pathname === "/create-contest/form") {
        return "If you reload this page, your previous action will be repeated";
      } else {
        //Don't return anything
      }
    };

    // Fetch subjects
    ProfileService.getSubjects()
      .then((res) => {
        setSubjectsList([...res]);
      })
      .catch((err) => {
        console.log("Subjects", err);
        toast.error("Something went wrong");
      });

    // Fetch Exams
    ProfileService.getExams()
      .then((res) => {
        setExamsList([...res]);
      })
      .catch((err) => {
        console.log("Exams", err);
        toast.error("Something went wrong");
      });
  }, []);

  const submitForm = (ques) => {
    setLoading(true);
    // Extraction of Date and time in needed formats
    let extractedDate = formatDate(date, "-");
    // let extractedTime = date.toLocaleTimeString();

    let selectedSubject, selectedExam;
    // Extraction of subject id
    for (let index = 0; index < subjectsList.length; index++) {
      if (subjectsList[index].name === subject) {
        selectedSubject = subjectsList[index].id;
      }
    }
    // Extraction of exam id
    for (let index = 0; index < examsList.length; index++) {
      if (examsList[index].exam_name === exam) {
        selectedExam = examsList[index].id;
      }
    }

    let contest_details = {
      teacher_id: userID,
      contest_name: contestName,
      examination_id: selectedExam,
      subject_id: selectedSubject,
      contest_level: level,
      quiz_date: extractedDate,
      quiz_time: time,
      max_participants_allowed: noOfParticipants,
      entry_fee: fee,
      commision: commission,
      cash_points: cashPoints,
    };
    let questions = [...ques];
    let data = {
      contest_details,
      questions,
    };
    ProfileService.saveContestDetails(data)
      .then((res) => {
        setLoading(false);
        window.scrollTo(0, 0);
        setShowSuccessModal(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const createAnotherContest = () => {
    setShowSuccessModal(false);
    history.replace("/create-contest/form");
    emptyState();
  };

  const emptyState = () => {
    setStage(0);
    setContestName("");
    setDate(null);
    setFee("");
    setNoOfParticipants("");
    setCommission("");
    setExam("");
    setSubject("");
    setLevel("");
    setCashPoints("");
    setQuestions([
      {
        title: "",
        answer_1: "",
        answer_2: "",
        answer_3: "",
        answer_4: "",
        explaination: "",
        correct_answer: [],
      },
    ]);
  };

  const closeModal = () => {
    setShowSuccessModal(false);
    history.replace("/create-contest");
  };

  return (
    <div className='mt-16 lg:mt-20 3xl:mt-24 create-contest-form'>
      <div className='w-8/10-center relative '>
        <div className='absolute top-10 sm:top-24 left-0'>
          <Link to='/create-contest'>
            <i class='fas fa-arrow-left text-white text-3xl'></i>
          </Link>
        </div>
        <div className='mx-auto  xl:w-9/12'>
          <div className='text-left font-source-sans-pro capitalize'>
            <div className='text-FFA300 text-xl lg:text-4xl 4xl:text-5xl ml-12 lg:ml-0'>
              Create A New Contest
            </div>
          </div>
        </div>
        <div className='mx-auto  xl:w-9/12 shadow px-8 md:px-16 lg:px-24 py-20 mt-12 bg-white rounded-md'>
          <ProgressContainer onChange={setStage} stage={stage} />
          {/* <hr className="mt-20" /> */}
          <div className='mt-24'>
            {stage === 0 && (
              <ContestDetails
                updateStage={(stage) => setStage(stage)}
                contestName={contestName}
                setContestName={setContestName}
                exam={exam}
                setExam={setExam}
                examsList={examsList}
                subjectsList={subjectsList}
                subject={subject}
                setSubject={setSubject}
                level={level}
                setLevel={setLevel}
                date={date}
                setDate={setDate}
                time={time}
                setTime={setTime}
                fee={fee}
                setFee={setFee}
                noOfParticipants={noOfParticipants}
                setNoOfParticipants={setNoOfParticipants}
                commission={commission}
                setCommission={setCommission}
                cashPoints={cashPoints}
                setCashPoints={setCashPoints}
              />
            )}

            {stage === 1 && (
              <QuizEditor
                quizQuestions={questions}
                updateQuestionSet={(questions) => setQuestions(questions)}
                updateStage={(stage) => setStage(stage)}
              />
            )}
            {stage === 2 && (
              <QuizPreviewer
                questions={questions}
                updateStage={(stage) => setStage(stage)}
                submit={submitForm}
                subject={subject}
                loading={loading}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={showSuccessModal}
        isClosable={false}
        close={closeModal}
        width='w-11/12 md:w-10/12'>
        <div className='w-2/3  mx-auto py-20  '>
          <div className='relative'>
            <div className='absolute top-0 left-0'>
              <img src={icon1} alt='icon' className='h-full w-full' />
            </div>
            <div className='absolute top-0 right-0'>
              <img src={icon2} alt='icon' className='h-full w-full' />
            </div>
            <div className='text-center font-source-sans-pro'>
              <div className='pt-10 md:pt-20 heading'>
                Your Contest is successfully posted !
              </div>
              <div className='mt-4 text-8E8E8E'>Its Live Now</div>
            </div>
            <div className='mt-10 flex justify-center'>
              <img src={Tick} alt='icon' className='w-24 h-24' />
            </div>
            <div className='flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 mt-14 w-full'>
              <div className='w-full' onClick={createAnotherContest}>
                <Button width='w-full'>Create Another</Button>
              </div>
              <div className='w-full'>
                <Link to='/create-contest'>
                  <button className='w-full h-12 sm:h-14 3xl:h-16 font-semibold text-base capitalize border-FFA300 border-2 rounded-lg text-FFA300'>
                    Done
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Form;
