import React, { useState } from "react";
import HeroSection from "../Sections/HeroSection";
import Jumbotron from "../../Components/Jumbotron/Jumbotron";
import StatsSection from "../Sections/StatsSection";
// import JumbotronData from "../../Data/Jumbotron.json";
// import StudentData from "../../Data/Student.json";
// import TestimonialData from "../../Data/TestimonialData.json";
// import ExamData from "../../Data/ExamData.json";
// import teacherData from "../../Data/TeachersData.json";
// import AankalanData from "../../Data/Aankalan.json";
import ScoreAnalysis from "../Sections/ScoreAnalysis";
import ExamsSection from "./ExamsSection";
import Stepper from "../Sections/StepSection/Stepper";

import Cloud from "../../Assets/Icons/Cloud.svg";
import FeatureSection from "../Sections/FeatureSection";
import FunSection from "../Sections/FunSection";
// import OfferSection from "../Sections/OfferSection";
import DownloadAppSection from "../Sections/DownloadAppSection";
import Modal from "../../Components/Modal";
import ContactUs from "../Modals/ContactUs";
import { useHistory } from "react-router";
import TestimonialSection from "../Sections/TestimonialSection";
import BrainLogo from "../../Assets/Icons/BrainLogo.svg";
import RankLogo from "../../Assets/Icons/RankLogo.svg";
import BrainBookLogo from "../../Assets/Icons/BrainBookLogo.svg";
import ScholarshipLogo from "../../Assets/Icons/ScholarshipLogo.svg";
import StatsSection1 from "../../Assets/Images/StatSec1.svg";
import StatsSection2 from "../../Assets/Images/StatSec2.svg";
import StatsSection3 from "../../Assets/Images/StatSec3.svg";
import ScholarshipSection from "../Sections/ScholarshipSection";


let AankalanData = require('../../Data/Aankalan.json')
let JumbotronData = require('../../Data/Jumbotron.json')
let StudentData = require('../../Data/Student.json')
let TestimonialData = require('../../Data/TestimonialData.json')
let ExamData = require('../../Data/ExamData.json')
let teacherData = require('../../Data/TeachersData.json')


const StudentLanding = () => {
  const history = useHistory();
  const [showContactModal, setShowContactModal] = useState(false);
  const {
    heading: headingFAQ,
    studentDescription: descriptionFAQ,
    btnText: btnTextFAQ,
  } = JumbotronData["FAQ"];
  const {
    heading: headingCU,
    studentDescription: descriptionCU,
    btnText: btnTextCU,
  } = JumbotronData["CONTACT_US"];
  const { heading, heading2, description, statsArray } =
    StudentData["STATS_SECTION"];
  const {
    heading: headingRTC,
    description: descriptionRTC,
    statsArray: statsArrayRTC,
  } = StudentData["REASON_TO_CHOOSE"];

  const { heading: headingES, exams, examMapping } = ExamData;
  const { text: textHS, statsArray: statsarrayHS } =
    StudentData["HERO_SECTION"];
  const {
    heading: headingSS,
    stepsArray,
    description: descriptionSS,
  } = StudentData["STEP_SECTION"];

  const {
    heading: headingTS,
    description: descriptionTS,
    testimonials,
  } = TestimonialData;
  const {
    studentHeading: headingDA,
    studentDescription: descriptionDA,
    adImage,
    qrImage,
  } = AankalanData["DOWNLOAD_APP"];

  const {
    heading: headingFS,
    description: descriptionFS,
    image: imageFS,
  } = StudentData["FEATURE_SECTION"];
  const { heading: headingSCL } = AankalanData["SCHOLARSHIP"];

  // const percentageColorArray = ["EADA7CE5", "FD757D", "77DDB9"];
  const statsImagesRTC = [BrainBookLogo, BrainLogo, RankLogo, ScholarshipLogo];

  const statSectionImages = [StatsSection1, StatsSection2, StatsSection3];

  return (
    <div className='mt-16 lg:mt-20 3xl:mt-24'>
      <HeroSection text={textHS} data={statsarrayHS} />
      <ExamsSection heading={headingES} exams={exams} examMap={examMapping} />
      <StatsSection
        bgColor='gradient'
        textColor='FFFFFF'
        heading={heading}
        heading2={heading2}
        description={description}
        statsArray={statsArray}
        statsImages={statSectionImages}
        // percentageColor={percentageColorArray}
        statCardColor='FFFFFF'
        statsCardHeadingColor='000000'
        statsCardDescriptionColor='8B8B8B'
      />
      <Stepper
        heading={headingSS}
        stepsArray={stepsArray}
        description={descriptionSS}
        teacherData={teacherData}
      />

      <ScoreAnalysis
        heading={headingRTC}
        description={descriptionRTC}
        isDividerVisibile={false}
        statsArray={statsArrayRTC}
        statsImages={statsImagesRTC}
        bgColor='gradient'
        textColor='FFFFFF'
      />
      <FeatureSection
        heading={headingFS}
        image={imageFS}
        description={descriptionFS}
      />
      <TestimonialSection
        heading={headingTS}
        description={descriptionTS}
        testimonialsList={testimonials}
      />
      <ScholarshipSection heading={headingSCL} />
      <FunSection />
      {/* <OfferSection /> */}
      <DownloadAppSection
        heading={headingDA}
        description={descriptionDA}
        adImage={adImage}
        qrImage={qrImage}
      />
      <Jumbotron
        bgColor='F5F5F5'
        heading={headingFAQ}
        description={descriptionFAQ}
        btnText={btnTextFAQ}
        headingColor='000000'
        descriptionColor='000000'
        src={Cloud}
        alt='cloud'
        height='11'
        width='11'
        position=''
        buttonOnclick={() => {
          window.scrollTo(0, 0);
          history.push("/faq");
        }}
      />
      <Jumbotron
        bgColor='230D4B'
        heading={headingCU}
        description={descriptionCU}
        btnText={btnTextCU}
        headingColor='FFFFFF'
        descriptionColor='FFFFFF'
        btnColor='FFFFFF'
        btnTextColor='000000'
        src={""}
        alt=''
        height=''
        width=''
        position=''
        buttonOnclick={() => {
          window.scrollTo(0, 0);
          setShowContactModal(true);
        }}
      />
      <Modal
        visible={showContactModal}
        close={() => setShowContactModal(false)}
        width='w-11/12 md:w-10/12'>
        <ContactUs close={() => setShowContactModal(false)} />

      </Modal>
    </div>
  );
};

export default StudentLanding;
