import React, { useContext } from "react";
import toast from "react-hot-toast";
import GreenTick from "../../Assets/Icons/StepperIcons/GreenTick.svg";
import { ProfileContext } from "../../Context/ProfileContext";

const OtpInput = ({ checkOtpHandler }) => {
  const { verifiedPhoneNo } = useContext(ProfileContext);
  const autoFocusHandler = (e, now, next) => {
    document.getElementById(now).value = e.target.value;
    if (document.getElementById(now).value.length) {
      document.getElementById(next).focus();
    }
  };
  const autoDeleteHandler = (e, now, prev) => {
    if (e.key === "Enter") {
      submitOtp();
      return;
    }
    if (e.keyCode === 8 && !document.getElementById(now).value) {
      document.getElementById(now).value = null;
      document.getElementById(prev).focus();
      return;
    }
    document.getElementById(now).value = null;
  };

  const submitOtp = () => {
    console.log("Submit called");
    let otp =
      document.getElementById("firstOtpInput").value +
      document.getElementById("secondOtpInput").value +
      document.getElementById("thirdOtpInput").value +
      document.getElementById("fourthOtpInput").value +
      document.getElementById("fifthOtpInput").value +
      document.getElementById("sixthOtpInput").value;
    console.log(otp);
    if (otp.length === 6) {
      checkOtpHandler(otp);
    }
  };

  return (
    <div className='flex space-x-2 font-source-sans-pro'>
      <input
        className='w-10 sm:w-16 md:w-20 xl:w-24 h-10 sm:h-16 md:h-20 xl:h-24 bg-FFF7EA shadow-md border border-FFFFFF focus:border-461995 rounded-md text-lg sm:text-5xl text-black text-center focus:outline-none caret-transparent'
        type='number'
        maxLength='1'
        placeholder='X'
        onKeyUp={(e) => autoFocusHandler(e, "firstOtpInput", "secondOtpInput")}
        onKeyDown={(e) =>
          autoDeleteHandler(e, "firstOtpInput", "firstOtpInput")
        }
        id='firstOtpInput'
      />
      <input
        className='w-10 sm:w-16 md:w-20 xl:w-24 h-10 sm:h-16 md:h-20 xl:h-24 bg-FFF7EA shadow-md border border-FFFFFF focus:border-461995 rounded-md text-lg sm:text-5xl text-black text-center focus:outline-none caret-transparent'
        type='number'
        maxLength='1'
        placeholder='X'
        onKeyUp={(e) => autoFocusHandler(e, "secondOtpInput", "thirdOtpInput")}
        onKeyDown={(e) =>
          autoDeleteHandler(e, "secondOtpInput", "firstOtpInput")
        }
        id='secondOtpInput'
      />
      <input
        className='w-10 sm:w-16 md:w-20 xl:w-24 h-10 sm:h-16 md:h-20 xl:h-24 bg-FFF7EA shadow-md border border-FFFFFF focus:border-461995 rounded-md text-lg sm:text-5xl text-black text-center focus:outline-none caret-transparent'
        type='number'
        placeholder='X'
        maxLength='1'
        onKeyUp={(e) => autoFocusHandler(e, "thirdOtpInput", "fourthOtpInput")}
        onKeyDown={(e) =>
          autoDeleteHandler(e, "thirdOtpInput", "secondOtpInput")
        }
        id='thirdOtpInput'
      />
      <input
        className='w-10 sm:w-16 md:w-20 xl:w-24 h-10 sm:h-16 md:h-20 xl:h-24 bg-FFF7EA shadow-md border border-FFFFFF focus:border-461995 rounded-md text-lg sm:text-5xl text-black text-center focus:outline-none caret-transparent'
        type='number'
        placeholder='X'
        maxLength='1'
        onKeyUp={(e) => autoFocusHandler(e, "fourthOtpInput", "fifthOtpInput")}
        onKeyDown={(e) =>
          autoDeleteHandler(e, "fourthOtpInput", "thirdOtpInput")
        }
        id='fourthOtpInput'
      />
      <input
        className='w-10 sm:w-16 md:w-20 xl:w-24 h-10 sm:h-16 md:h-20 xl:h-24 bg-FFF7EA shadow-md border border-FFFFFF focus:border-461995 rounded-md text-lg sm:text-5xl text-black text-center focus:outline-none caret-transparent'
        type='number'
        placeholder='X'
        maxLength='1'
        onKeyUp={(e) => autoFocusHandler(e, "fifthOtpInput", "sixthOtpInput")}
        onKeyDown={(e) =>
          autoDeleteHandler(e, "fifthOtpInput", "fourthOtpInput")
        }
        id='fifthOtpInput'
      />
      <input
        className='w-10 sm:w-16 md:w-20 xl:w-24 h-10 sm:h-16 md:h-20 xl:h-24 bg-FFF7EA shadow-md border border-FFFFFF focus:border-461995 rounded-md text-lg sm:text-5xl text-black text-center focus:outline-none caret-transparent'
        type='number'
        maxLength='1'
        placeholder='X'
        onKeyUp={submitOtp}
        onKeyDown={(e) =>
          autoDeleteHandler(e, "sixthOtpInput", "fifthOtpInput")
        }
        id='sixthOtpInput'
      />
      {verifiedPhoneNo && (
        <img className='h-8 sm:h-20  w-auto' alt='green tick' src={GreenTick} />
      )}
    </div>
  );
};

export default OtpInput;
