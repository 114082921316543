import React from "react";
import StatsCard from "../../Components/Card/StatsCard";
import { useWindowDimensions } from "../../CustomHooks/ScreenH-W";

const StatsSection = ({
  bgColor,
  textColor,
  heading,
  heading2,
  description,
  statsArray,
  statsImages,
  // percentageColor,
  statCardColor,
  statsCardHeadingColor,
  statsCardDescriptionColor,
}) => {
  const { width, height } = useWindowDimensions();
  return (
    <div className={`bg-${bgColor}`}>
      <div
        className={`space-y-7 md:space-y-12  md:space-y-20 lg:space-y-24 3xl:space-y-28 py-10 sm:py-10 lg:pt-24 lg:py-0`}>
        <div className='space-y-4 text-center w-8/10-center-without-py'>
          <div dangerouslySetInnerHTML={{ __html: heading }} className={`heading  text-${textColor}`} />
          <div dangerouslySetInnerHTML={{ __html: heading2 }} className={`heading2  text-${textColor}`} />
          <div dangerouslySetInnerHTML={{ __html: description }} className={` hidden lg:block sub-heading  text-${textColor}`} />
        </div>
        <div
          className={`${width >= 992 ? "w-8/10-center-without-py" : "w-9/10-left-without-py"
            } flex space-x-8 lg:space-x-4 2xl:space-x-8 overflow-without-scrollbar`}>
          {statsArray.map((stats, index) => (
            <div className='lg:w-full'>
              <div className='w-60 lg:w-full h-full'>
                <StatsCard
                  key={index}
                  heading={stats.heading}
                  description={stats.description}
                  // percentage={stats.percentage}
                  image={statsImages[index]}
                  // percentageColor={percentageColor[index]}
                  bgColor={statCardColor}
                  headingColor={statsCardHeadingColor}
                  descriptionColor={statsCardDescriptionColor}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
