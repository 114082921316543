import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "../../Components/Button";
import Image from "../../Components/Image";
import Logo from "../../Components/Logo/Logo";
import WelcomeGirl from "../../Assets/Images/WelcomeGirl.png";
import { useWindowDimensions } from "../../CustomHooks/ScreenH-W";

import { getAnalytics, logEvent } from "firebase/analytics";


const Welcome = () => {
  const history = useHistory();
  const { width, height } = useWindowDimensions();
  const [backgroundColor, setBackgroundColor] = useState("461995");
  const [textColor, setTextColor] = useState("FFA300");
  const [buttonColor, setButtonColor] = useState("FFA300");
  const [logoColor, setLogoColor] = useState("FFA200");
  const [routes, setRoutes] = useState({
    profile: "/create-contest/profile",
    back: "/create-contest",
  });

  useEffect(() => {
    let route = history.location.pathname;
    if (route === "/create-test-series/welcome") {
      setBackgroundColor("228F95");
      setTextColor("FFFFFF");
      setButtonColor("FFFFFF");
      setLogoColor("FFFFFF");
      setRoutes({
        profile: "/create-test-series/profile",
        back: "/create-test-series",
      });
    }

    const analytics = getAnalytics();
    logEvent(analytics, 'CREATE_PROFILE_WELCOME', {
      landed: "true"
    });

  }, []);

  return (
    <div className='h-screen grid grid-cols-10 font-source-sans-pro'>
      <div
        className={`create-contest-padding-left flex flex-col justify-between col-span-10 md:col-span-7 bg-${backgroundColor}`}>
        <Logo color={logoColor} />
        <div className='heading3 3xl:text-3xl font-semibold text-FFFFFF uppercase'>
          Create Your Profile
        </div>
        {width >= 576 ? (
          <div className='font-bold text-4xl lg:text-5xl 3xl:text-7xl  text-FFFFFF lowercase'>
            we&rsquo;re very excited to <br /> have you on-board
            <br /> with aankalan
          </div>
        ) : (
          <div className='font-bold text-5xl  text-FFFFFF lowercase'>
            we&rsquo;re very excited to have you on-board with aankalan
          </div>
        )}
        <div>
          <div
            className={`text-base sm:text-xl md:text-2xl xl:text-4xl 3xl:text-5xl text-${textColor}`}>
            Set up your profile in just a few steps
          </div>
          <div className='heading3 3xl:text-3xl text-FFFFFF'>
            A one - time activity taking less than a minute!
          </div>
        </div>
        <div className='flex flex-col sm:flex-row sm:items-center space-y-8 sm:space-y-0 xl:space-x-4'>
          <Button
            onClick={() => history.replace(routes.profile)}
            textTransform='normal'
            bgColor={buttonColor}
            textSize='text-base 3xl:text-2xl 4xl:text-3xl'
            width='w-48 xl:w-56 3xl:w-80'
            height='h-12 sm:h-14 3xl:h-20'>
            Let's begin!
          </Button>
          <Link to={routes.back}>
            <button className='hidden sm:block sub-heading 3xl:text-2xl 4xl:text-3xl  text-FFFFFF w-48 xl:w-56'>
              Go Back
            </button>
            <div className='sm:hidden sub-heading 3xl:text-2xl 4xl:text-3xl  text-FFFFFF w-48 xl:w-56 pl-2'>
              Go Back
            </div>
          </Link>
        </div>
      </div>
      <div className='create-contest-padding-right hidden md:col-span-3 bg-FFF5E3'></div>
      <div className='hidden md:block absolute -bottom-8 right-0 lg:right-32 xl:right-28 2xl:right-48  4xl:right-72 bounce-girl'>
        <Image
          src={WelcomeGirl}
          alt='welcome girl'
          height='h-auto'
          width='w-80 xl:w-112 3xl:w-auto'
        />
      </div>
    </div>
  );
};

export default Welcome;
