import { updateLocalStorage } from "../../Utility/Helper";
import { userConstants } from "../Constants";

function update(payload) {
  return { type: userConstants.USER_UPDATE, payload };
}

export const updateUser = (user) => {
  return (dispatch, getState) => {
    updateLocalStorage("user", { ...getState().user, ...user });
    dispatch(update(user));
  };
};

export const userActions = {
  updateUser,
};
