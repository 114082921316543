import React from "react";
import BlackBgTick from "../../../../Assets/Icons/BlackBgTick.svg";
import TeacherStep2Img from "../../../../Assets/Images/TeacherStep2.svg";

const TeacherStep2 = ({ description, dataArray }) => {
  return (
    <div className='bg-FCFCFC'>
      <div className='container w-8/10-center py-20 space-y-16'>
        <div className='heading3 text-center lg:w-1/2 mx-auto text-black'>
          Aankalan has <b>20K+</b> & has administered <b>15K+</b> tests till
          date.
        </div>
        <div className='grid grid-cols-1 items-center justify-center gap-y-8'>
          <div className='col-span-1 mx-auto'>
            <img
              src={TeacherStep2Img}
              alt='student solving question'
              height='h-auto'
              width='w-full'
            />
          </div>
          <div className='col-span-1 space-y-10'>
            <div className='font-semibold heading2 text-black'>
              {description}
            </div>
            <div className='space-y-4'>
              {dataArray.map((item, index) => (
                <div className='flex items-start space-x-4'>
                  <div className='h-auto w-5 mt-1'>
                    <img
                      src={BlackBgTick}
                      alt='tick'
                      className='h-full w-full'
                    />
                  </div>
                  <div className='w-11/12 font-source-sans-pro font-semibold text-sm md:text-lg text-black'>
                    {item}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherStep2;
