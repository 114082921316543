import React, { useEffect, useState } from "react";
import RightArrowBgWhite from "../../Assets/Icons/RightArrowBgWhite.svg";
import disabled2 from "../../Assets/Icons/disabled-arrow-2.svg";
import disabled1 from "../../Assets/Icons/disabled-arrow-1.svg";


import LeftArrowBgWhite from "../../Assets/Icons/LeftArrowBgWhite.svg";
import AssociatedPersonCard from "../../Components/Card/AssociatedPersonCard";
import { getCardWidth, sideScroll } from "../../Utility/Helper";

const AssociatedPeople = ({ associatedPeople }) => {
  const containerId = "associatedPeople";
  const [scrollableWidth, setScrollableWidth] = useState(0);
  const [contentScolled, setContentScolled] = useState(528);
  const [disableLeftArrow, setDisableLeftArrow] = useState(true);

  useEffect(() => {
    let element = document.getElementById(containerId);
    let scrollableContent = element.scrollWidth;
    let cardWidth = getCardWidth(`${containerId}-card-0`);

    setScrollableWidth(scrollableContent);
    setContentScolled(contentScolled + cardWidth * 5);
  }, []);

  const scrollLeft = () => {
    sideScroll("LEFT", 50, containerId);

    let cardWidth = getCardWidth(`${containerId}-card-0`);
    let temp = contentScolled - cardWidth;
    setContentScolled(temp);
    if (temp <= 528 + cardWidth * 5) {
      setDisableLeftArrow(true);
    }
  };

  const scrollRight = () => {
    sideScroll("RIGHT", 50, containerId);

    let cardWidth = getCardWidth(`${containerId}-card-0`);
    let temp = contentScolled + cardWidth;
    setContentScolled(temp);
    if (temp > 528 + cardWidth * 5) {
      setDisableLeftArrow(false);
    }
  };

  return (
    <div className='flex justify-center items-center relative'>
      <div
        id='rightArrow'
        className={`h-10 w-10 4xl:h-12 4xl:w-12 absolute top-1/2 -left-5 z-10 ${
          !disableLeftArrow ? "cursor-pointer" : ""
        }`}
        onClick={scrollLeft}>
        <img
          src={!disableLeftArrow ?LeftArrowBgWhite:disabled1}
          alt='right arrow'
          className='w-full h-full'
        />
      </div>
      <div
        id={containerId}
        className='w-full flex space-x-4 xl:space-x-6 overflow-without-scrollbar'>
        {associatedPeople.map((person, index) => (
          <div id={`${containerId}-card-${index}`} key={index}>
            <div className='w-40 3xl:w-48 h-full'>
              <AssociatedPersonCard
                type='ASSESSOR'
                category='Our Assessor'
                name={person.name}
                designation={person.title}
                image={person.image}
              />
            </div>
          </div>
        ))}
      </div>
      <div
        id='rightArrow'
        className={`h-10 w-10 4xl:h-12 4xl:w-12 absolute top-1/2 -right-5 z-10 ${
          contentScolled < scrollableWidth ? "cursor-pointer" : ""
        }`}
        onClick={scrollRight}>
        <img
          src={contentScolled < scrollableWidth ?RightArrowBgWhite:disabled2}
          alt='right arrow'
          className='w-full h-full'
        />
      </div>
    </div>
  );
};

export default AssociatedPeople;
