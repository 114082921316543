import React from "react";
import { enGB } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";

import Calender from "../Assets/Icons/Calender.png";

const DateInput = ({
  title,
  date,
  setDate,
  placeholder,
  info,
  large,
  transparent,
}) => {
  return (
    <div className='font-soure-sans-pro '>
      {title && (
        <div
          className={`${
            large
              ? "text-base md:text-3xl 3xl:text-4xl text-9A9A9A mb-1"
              : "font-semibold text-base md:text-xl 3xl:text-2xl mb-3"
          }  `}>
          {title}
        </div>
      )}
      <DatePicker
        date={date}
        onDateChange={setDate}
        locale={enGB}
        format='dd/MM/yyyy'>
        {({ inputProps, focused }) => (
          <div
            className={`flex items-center justify-between ${
              transparent
                ? "bg-transparent"
                : "bg-FAFAFA focus:border-461995 border border-D3D0D0 rounded-md"
            }   ${
              large
                ? "text-2xl xl:text-5xl 3xl:text-6xl  light-placeholder"
                : "3xl:text-xl  px-4"
            }   w-full h-12 sm:h-14 3xl:h-16 focus:outline-none `}>
            <input
              className={`
              flex-grow bg-FAFAFA
            input +
            ${focused ? " -focused" : ""} outline-none`}
              {...inputProps}
              placeholder={placeholder}
            />
            <img src={Calender} alt='calender' className='h-5 w-5' />
          </div>
        )}
      </DatePicker>
      {info && <div className='mt-2 text-xs text-707070'>*{info}</div>}
    </div>
  );
};

export default DateInput;
