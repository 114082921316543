import React from "react";
import { Redirect, Route, Switch } from "react-router";
import StudentLanding from "./Containers/Student";
import TeachersLanding from "./Containers/Teacher";
import CoachingInstitutionsLanding from "./Containers/CoachingInstitute";
import ParentsLanding from "./Containers/Parent";
import AankalanLanding from "./Containers/Aankalan";
import OnboardingLayout from "./Containers/OnBoarding/Layout";
import Layout from "./Containers/Layout/Layout";
import Faq from "./Containers/Faq/Faq";
import Teachers from "./Containers/Teacher/Teachers";
import Login from "./Containers/Login/LoginLayout";
import StudentRegistration from "./Containers/Student/StudentRegistration";
import Youtubers from "./Containers/Youtuber/Youtubers";
import TeacherRegistration from "./Containers/Parent/TeacherRegistration";
import TermsAndConditions from "./Containers/TermsAndConditions";
import PrivacyPolicy from "./Containers/PrivacyPolicy";
import CreateContest from "./Containers/CreateContest";
import CreateContestFrom from "./Containers/CreateContest/form";
import CreateTestSeries from "./Containers/CreateTestSeries";

import Footer from "./Components/Footer";
import { CreateContestHeader } from "./Components/CreateContestHeader";
import Welcome from "./Containers/CreateContest/Welcome";
import ProfileDetailTemplate from "./Containers/CreateContest/ProfileDetailTemplate/ProfileDetailTemplate";
import Congratulations from "./Containers/CreateContest/Congratulations";
import { SubmitTestSeries } from "./Containers/CreateTestSeries/SubmitTestSeries";
import Download from "./Components/Download/Download";
import ContactUs from "./Containers/Modals/ContactUs";
export const PublicRoutes = () => {
  // NOTE removed onboarding route and redirected to home page
  return (
    <Switch>
      <Route
        path='/students'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <StudentLanding {...props} />
            </Layout>
          </React.Fragment>
        )}
      />
      <Route
        path='/teachers'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <TeachersLanding {...props} />
            </Layout>
          </React.Fragment>
        )}
      />
      <Route
        path='/parents'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <ParentsLanding {...props} />
            </Layout>
          </React.Fragment>
        )}
      />
      <Route
        path='/coachinginstitutions'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <CoachingInstitutionsLanding {...props} />
            </Layout>
          </React.Fragment>
        )}
      />
      <Route
        path='/home'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <AankalanLanding {...props} />
            </Layout>
          </React.Fragment>
        )}
      />
      <Route
        path='/faq'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <Faq {...props} />
            </Layout>
          </React.Fragment>
        )}
      />
      <Route
        path='/login'
        render={(props) => (
          <React.Fragment>
            <Login {...props} />
          </React.Fragment>
        )}
      />
      <Route
        path='/register-student'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <StudentRegistration {...props} />
            </Layout>
          </React.Fragment>
        )}
      />
      <Route
        path='/register-teacher'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <TeacherRegistration {...props} />
            </Layout>
          </React.Fragment>
        )}
      />
      <Route
        path='/find-teacher'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <Teachers {...props} />
            </Layout>
          </React.Fragment>
        )}
      />
      <Route
        path='/find-youtuber'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <Youtubers {...props} />
            </Layout>
          </React.Fragment>
        )}
      />
      {/* <Route
        path='/'
        render={(props) => (
          <React.Fragment>
            <OnboardingLayout {...props} />
          </React.Fragment>
        )}
      /> */}
      <Route
        path='/create-contest/form'
        render={(props) => (
          <React.Fragment>
            <CreateContestHeader />
            <CreateContestFrom />
            <Footer />
          </React.Fragment>
        )}
      />
      <Route
        path='/create-contest/congratulations'
        render={(props) => (
          <React.Fragment>
            <Congratulations />
          </React.Fragment>
        )}
      />
      <Route
        path='/create-contest/profile'
        render={(props) => (
          <React.Fragment>
            <ProfileDetailTemplate />
          </React.Fragment>
        )}
      />
      <Route
        path='/create-contest/welcome'
        render={(props) => (
          <React.Fragment>
            <Welcome />
          </React.Fragment>
        )}
      />
      <Route
        path='/create-contest'
        render={(props) => (
          <React.Fragment>
            <CreateContestHeader
              bgColor='bg-7150AD xl:bg-gradient-to-r from-786AB5 via-786AB5 to-9386CE'
              logoColor='FFFFFF'
              buttonColor='FFFFFF'
            />
            <CreateContest />
            <Footer />
          </React.Fragment>
        )}
      />
      <Route
        path='/create-test-series/congratulations'
        render={(props) => (
          <React.Fragment>
            <Congratulations />
          </React.Fragment>
        )}
      />
      <Route
        path='/create-test-series/profile'
        render={(props) => (
          <React.Fragment>
            <ProfileDetailTemplate />
          </React.Fragment>
        )}
      />
      <Route
        path='/create-test-series/welcome'
        render={(props) => (
          <React.Fragment>
            <Welcome />
          </React.Fragment>
        )}
      />
      <Route
        path='/create-test-series/form'
        render={(props) => (
          <React.Fragment>
            <CreateContestHeader bgColor='bg-27878D' />
            <SubmitTestSeries />
            <Footer />
          </React.Fragment>
        )}
      />
      <Route

        path='/create-test-series'
        render={(props) => (
          <React.Fragment>
            <CreateContestHeader
              bgColor='bg-5EA4A9'
              logoColor='FFFFFF'
              buttonColor='FFFFFF'
            />
            <CreateTestSeries />
            <Footer />
          </React.Fragment>
        )}
      />
      <Route
        path='/terms-conditions'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <TermsAndConditions {...props} />
            </Layout>
          </React.Fragment>
        )}
      />
      <Route
        path='/privacy-policy'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <PrivacyPolicy {...props} />
            </Layout>
          </React.Fragment>
        )}
      />
      <Route
        path='/contact-us'
        render={(props) => (
          <React.Fragment>
            <Layout>
              <ContactUs/>
            </Layout>
          </React.Fragment>
        )}
      />
      {/* <Route
        path='/'
        render={(props) => (
          <React.Fragment>
              <Download {...props} />
          </React.Fragment>
        )}
      /> */}
      <Redirect from='/' to='/home' />
    </Switch>
  );
};
