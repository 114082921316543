import React, { useState } from "react";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import TextArea from "../../Components/TextArea";
import { Sitekey } from "../../config";
// import aankalanData from "../../Data/Aankalan.json";
import { contactUsService } from "../../Services/contactUs.service";
import { validEmail, validPhone } from "../../Utility/Helper";
import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
import PhoneInput from "../../Components/PhoneInput";

let aankalanData = require('../../Data/Aankalan.json')

const ContactUs = ({ close }) => {
  const { social_links } = aankalanData;

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [phone, setPhone] = useState("")


  const resetForm = () => {
    setEmail("")
    setName("")
    setPhone("")
    setMessage("")
    setIsCaptchaValid(false)
  }

  const submit = () => {
    console.log(phone, email, name, message)
    if (!name) {
      return toast.error("Enter Name");
    }
    if (!validEmail(email)) {
      return toast.error("Enter a valid email");
    }
    if (!validPhone(phone)) {
      return toast.error("Enter a valid phone number");
    }
    if (!message) {
      return toast.error("Enter Message");
    }
    if (!isCaptchaValid) {
      return toast.error("Captcha Falied");
    }
    if (name && message && isCaptchaValid) {
      return contactUsService
        .submitDetils(name, email, message, phone)
        .then((res) => {
          toast.success(
            "Thank you for your interest, Our Team will shortly contact you."
          )
          close()
          resetForm()
        }
        )
        .catch((err) => {
          toast.error("Something Went wrong");
          console.log(err);

        });
    }
  };
  return (
    <div className={'p-9 sm:p-12 lg:p-20 xl:px-32 mt-12'}>
      <div className='text-center'>
        <div className='heading'>Get in Touch!</div>
        <div className='sub-heading'>
          For any queries, feel free to get in touch with us
        </div>
      </div>
      {/* <div className='grid grid-cols-3 mt-9 gap-3'>
        <div className='flex col-span-3 sm:col-span-1 justify-center items-center border border-DDDDDD'>
          <div className='py-10 px-4 flex flex-col space-y-2 items-center'>
            <i className='fas fa-map-marker-alt text-3xl text-461995'></i>
            <div className='text-6F6F6F'>Address 1</div>
          </div>
        </div>
        <div className='flex col-span-3 sm:col-span-1 justify-center items-center border border-DDDDDD'>
          <div className='py-10 px-4 flex flex-col space-y-2 items-center'>
            <i class='fas fa-phone-alt text-3xl text-461995'></i>
            <div className='text-6F6F6F'>+91 9871597556</div>
          </div>
        </div>
        <div className='flex col-span-3 sm:col-span-1 justify-center items-center border border-DDDDDD'>
          <div className='py-10 px-4 flex flex-col space-y-2 items-center'>
            <i class='fas fa-envelope text-3xl text-461995'></i>
            <div className='text-6F6F6F'>aankalan@gmail.com</div>
          </div>
        </div>
      </div> */}
      <div className='mt-4 lg:mt-14'>
        <form>
          <div className='grid grid-cols-2 gap-3'>
            <div className='col-span-2 lg:col-span-1'>
              <Input
                value={name}
                onChange={setName}
                placeholder='Enter Your Name'
                title='Name'
              />
            </div>
            <div className='col-span-2 lg:col-span-1'>
              <Input
                value={email}
                onChange={setEmail}
                placeholder='Enter Your Email'
                title='Email'
              />
            </div>
            <div className='col-span-2 lg:col-span-1'>
              <PhoneInput
                sendOTP={false}
                value={phone}
                onChange={setPhone}
                placeholder='Enter Your Phone Number'
                title='Phone Number'
              />
            </div>
          </div>
          <div className='mt-4 lg:mt-14'>
            <TextArea
              value={message}
              onChange={setMessage}
              placeholder='Write Your Message'
              title='Message'
            />
          </div>
          <div className='mt-4 lg:mt-14'>
            <ReCAPTCHA
              sitekey={Sitekey}
              onChange={() => setIsCaptchaValid(true)}
            />
          </div>
        </form>
        <div className='mt-6'>
          <Button onClick={submit} width='w-full lg:w-44' height='h-12'>
            Submit
          </Button>
        </div>
      </div>
      <div className='mt-16 items-center flex flex-col'>
        <div className='font-soure-sans-pro font-semibold text-xl'>
          Reach Us On
        </div>
        <div className='flex space-x-8 mt-6 items-center'>
          <a
            href={social_links.facebook}
            target='_blank'
            rel='noopener noreferrer'>
            <i class='fab fa-facebook text-4xl'></i>
          </a>
          <a
            href={social_links.instagram}
            target='_blank'
            rel='noopener noreferrer'>
            <i class='fab fa-instagram text-4xl'></i>
          </a>
          <a
            href={social_links.twitter}
            target='_blank'
            rel='noopener noreferrer'>
            <i class='fab fa-twitter text-4xl'></i>
          </a>
        </div>
        <a href='mailto:contact@aankalan.app'>
          <div className='font-sans-pro text-base mt-6'>
            contact@aankalan.app
          </div>
        </a>
        <a href='tel:+919311771327'>
          <div className='font-sans-pro text-base mt-6'>
            +91-93117 71327
          </div>
        </a>
      </div>
    </div>
  );
};
export default ContactUs;
