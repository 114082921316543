let windowDomain = "http://13.52.139.224";
let createProfileDomain = "http://13.52.139.224/api/v3";

if (process.env.NODE_ENV !== "development") {
  // windowDomain = "https://aankalan.cliffex.com/";
  // createProfileDomain = "https://aankalan.cliffex.com/api/v3";

  windowDomain = "https://aankalan.app/";
  createProfileDomain = "https://aankalan.app/api/v3";
}

const domain = windowDomain + "/api/v3/";

export const TOKEN =
  "YMMh8nOvWsCHeXYD8FUJdeT5WbN!sHtexuh2RG*36B5M414SBUck1q3m9Np2ZFxCTKcTfjjNYR12NyFSAMbz!";

const urls = {
  contactUs: domain + "contact-us",
  // validateTeacherCode: domain + "validate-teacher-code?teacher_code=",
  // testAPI: "https://reqres.in/api/register",
  validateTeacherCode: createProfileDomain + "/validate-teacher-code",
  validatePhone: createProfileDomain + "/validate-phone",
  coreExpertise: createProfileDomain + "/core-expertise",
  states: createProfileDomain + "/states",
  cities: createProfileDomain + "/cities",
  subjects: createProfileDomain + "/subjects",
  examination: createProfileDomain + "/examination",
  saveTeacher: createProfileDomain + "/save-teacher",
  saveContest: createProfileDomain + "/save-contest-questions",
  uploadFile: createProfileDomain + "/upload-file",
};

export default urls;
