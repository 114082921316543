import React, { useState } from "react";

export const ProfileContext = React.createContext();

const data = {
  1: {
    stageName: "Contact Details",
    steps: {
      1: {
        message: "How can I contact you?",
        description: "",
      },
    },
  },
  2: {
    stageName: "Basic details",
    steps: {
      1: {
        message: "Hello! I'm Pooja.",
        description:
          "I'm a counselor at Aankalan. Let me help you set up your profile. Please enter your name.",
      },
      2: {
        message: `Hey`,
        description:
          "I would love to know if you are a Teacher or YouTuber or an Institute!",
      },
      3: {
        message:
          "Do you have a Website/YouTube channel? also, could you help me with your experience?",
        description: "",
      },
      4: {
        message: "I wish to know your expertise areas.",
        description: "",
      },
      5:{
        message:"I wish to know a brief about you",
        description: "",
      }
    },
  },
  3: {
    stageName: "Add address",
    steps: {
      1: {
        message: "I would love to know which part of India you are from.",
        description: "",
      },
    },
  },
  4: {
    stageName: "Bank details",
    steps: {
      1: {
        messageA: "How can I transfer your share of revenue to you?",
        messageB: "How can I transfer your share of revenue to you?",
        description: "",
      },
    },
  },
};

export default function ProfileContextWrapper(props) {
  const [stage, setStage] = useState(1);
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState(data[stage].steps[step].message);
  const [description, setDescription] = useState(
    data[stage].steps[step].description
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("Male");
  const [userID, setUserID] = useState("");
  const [profession, setProfession] = useState("teacher");
  const [customProfession, setCustomProfession] = useState(false)

  const [profileImage, setProfileImage] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [promoteUser, setPromoteUser] = useState(true);

  const [activeHandle, setActiveHandle] = useState("youtubeLink");

  const [youtubeLink, setYoutubeLink] = useState("");
  const [instaLink, setInstaLink] = useState("");
  const [fbLink, setFbLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [promoteUserSocialHandle, setPromoteUserSocialHandle] = useState(true);

  const [teachingExp, setTeachingExp] = useState(10);

  const [qualification, setQualification] = useState("");

  const [coreSubjects, setCoreSubjects] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const [aboutUser, setAboutUser] = useState("")

  const [phoneNumber, setPhoneNumber] = useState("");
  const [verifiedPhoneNo, setVerifiedPhoneNo] = useState(false);

  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const [accHolderName, setAccHolderName] = useState("");
  const [accNumber, setaccNumber] = useState("");
  const [IFSC, setIFSC] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [panCard, setPanCard] = useState("");
  const [upiID, setUpiID] = useState("");
  const [bankState, setBankState] = useState("UPI_PAN"); // BANK or UPI_PAN

  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [agreeAankalnAgreement, setAgreeAankalnAgreement] = useState(false);
  const [showInteraction, setShowInteraction] = useState(true);

  const initialState = {
    data,
    stage,
    setStage,
    step,
    setStep,

    message,
    setMessage,
    description,
    setDescription,

    name,
    setName,
    email,
    setEmail,
    gender,
    setGender,
    userID,
    setUserID,

    profession,
    setProfession,
    customProfession,
    setCustomProfession,

    profileImage,
    setProfileImage,
    logoImage,
    setLogoImage,
    promoteUser,
    setPromoteUser,

    activeHandle,
    setActiveHandle,
    youtubeLink,
    setYoutubeLink,
    instaLink,
    setInstaLink,
    fbLink,
    setFbLink,
    twitterLink,
    setTwitterLink,
    promoteUserSocialHandle,
    setPromoteUserSocialHandle,

    teachingExp,
    setTeachingExp,
    qualification,
    setQualification,
    coreSubjects,
    setCoreSubjects,
    subjects,
    setSubjects,

    aboutUser,
    setAboutUser,

    phoneNumber,
    setPhoneNumber,
    verifiedPhoneNo,
    setVerifiedPhoneNo,

    city,
    setCity,
    state,
    setState,

    accHolderName,
    setAccHolderName,
    accNumber,
    setaccNumber,
    IFSC,
    setIFSC,
    bankName,
    setBankName,
    branchName,
    setBranchName,

    panCard,
    setPanCard,
    upiID,
    setUpiID,
    bankState,
    setBankState,

    showAgreementModal,
    setShowAgreementModal,
    agreeAankalnAgreement,
    setAgreeAankalnAgreement,

    showInteraction,
    setShowInteraction,
  };

  return (
    <ProfileContext.Provider value={initialState}>
      {props.children}
    </ProfileContext.Provider>
  );
}
