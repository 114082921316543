import React, { useState } from "react";
// import aankalanData from "../Data/Aankalan.json";

import Aankalan from "../Assets/Icons/Aankalan.png";
import Facebook from "../Assets/Icons/Facebook.svg";
import Twitter from "../Assets/Icons/Twitter.svg";
import LinkedIN from "../Assets/Icons/LinkedIN.png";
import YoutubeFooter from "../Assets/Icons/YoutubeFooter.png";
import Instagram from "../Assets/Icons/Instagram.svg";
import { Link } from "react-router-dom";
import Image from "./Image";
import Modal from "./Modal";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import ContactUs from "../Containers/Modals/ContactUs";

const aankalanData = require("../Data/Aankalan.json");

const Footer = () => {
  const { companyName, email, social_links } = aankalanData;

  const [showTAC, setShowTAC] = useState(false);
  const [showPP, setShowPP] = useState(false);
  const [showCU, setShowCU] = useState(false);

  return (
    <React.Fragment>
      <div className='bg-230D4B border-t border-CACACA'>
        <div className='w-8/10-center grid grid-cols-6 gap-y-10 font-source-sans-pro'>
          <div className='col-span-3 md:col-span-2 order-2 md:order-1'>
            <div className='flex flex-col space-y-4 text-FFFFFF text-2xs md:text-xs 3xl:text-sm uppercase'>
              <Link to='/home'>
                <div>about aankalan</div>
              </Link>
              <Link to='/privacy-policy'>
                <div>privacy policy</div>
              </Link>
              <Link to='/terms-conditions'>
                <div>terms & conditions</div>
              </Link>
              <Link to='/faq'>
                <div>faq</div>
              </Link>
            </div>
          </div>
          <div className='col-span-6 md:col-span-2 order-1 md:order-2 flex flex-col items-center space-y-8'>
            <Link to='/'>
              <div className='cursor-pointer'>
                <Image
                  src={Aankalan}
                  alt='logo'
                  height='h-8 md:h-12'
                  width='w-8 md:w-12'
                />
              </div>
            </Link>
            <div className='space-y-4'>
              <div className='space-x-8 flex justify-center'>
                <div className='inline-block cursor-pointer'>
                  <a
                    href={social_links.instagram}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <Image
                      src={Instagram}
                      alt='social media 1'
                      height='h-3.5'
                      width='w-3.5'
                      link='/'
                    />
                  </a>
                </div>
                <div className='inline-block cursor-pointer'>
                  <a
                    href={social_links.twitter}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <Image
                      src={Twitter}
                      alt='social media 2'
                      height='h-3.5'
                      width='w-3.5'
                      link='/'
                    />
                  </a>
                </div>{" "}
                <div className='inline-block cursor-pointer'>
                  <a
                    href={social_links.facebook}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <Image
                      src={Facebook}
                      alt='social media 3'
                      height='h-3.5'
                      width='w-3.5'
                      link='/'
                    />
                  </a>
                </div>
                <div className='inline-block cursor-pointer'>
                  <a
                    href={social_links.linkedin}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <Image
                      src={LinkedIN}
                      alt='social media 2'
                      height='h-3.5'
                      width='w-3.5'
                      link='/'
                    />
                  </a>
                </div>
                <div className='inline-block cursor-pointer'>
                  <a
                    href={social_links.youtube}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <Image
                      src={YoutubeFooter}
                      alt='social media 2'
                      height='h-4'
                      width='w-4'
                      link='/'
                    />
                  </a>
                </div>
              </div>
              <div className='text-center text-CACACA text-2xs capitalize'>
                &copy;2021 {companyName}. All rights reserved
              </div>
            </div>
          </div>
          <div className='col-span-3 md:col-span-2 order-3 space-y-4'>
            <div
              className='text-right text-FFFFFF text-2xs md:text-xs 3xl:text-sm uppercase cursor-pointer'
              onClick={() => {
                window.scrollTo(0, 0);
                setShowCU(true);
              }}>
              Contact Us
            </div>
            <div className='text-right font-bold text-E5D472 text-sm md:text-base 3xl:text-xl cursor-pointer'>
              <a href='mailto:contact@aankalan.app'>{email}</a>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={showTAC}
        close={() => setShowTAC(false)}
        width='w-11/12 md:w-10/12'>
        <TermsAndConditions close={() => setShowTAC(false)} />
      </Modal>
      <Modal
        visible={showPP}
        close={() => setShowPP(false)}
        width='w-11/12 md:w-10/12'>
        <PrivacyPolicy close={() => setShowPP(false)} />
      </Modal>
      <Modal
        visible={showCU}
        close={() => setShowCU(false)}
        width='w-11/12 md:w-10/12'>
        <ContactUs close={() => setShowCU(false)} />
      </Modal>
    </React.Fragment>
  );
};

export default Footer;
