import React from "react";

const StatsCard = ({
  heading,
  description,
  // percentage,
  // percentageColor,
  bgColor,
  headingColor,
  descriptionColor,
  image,
}) => {
  // const percent = (function calPercentage() {
  //   if (percentage > 99 && percentage <= 100) {
  //     return "100";
  //   } else if (percentage > 89 && percentage <= 99) {
  //     return "90";
  //   } else if (percentage > 78 && percentage <= 89) {
  //     return "80";
  //   } else if (percentage > 70 && percentage <= 78) {
  //     return "75";
  //   } else if (percentage > 55 && percentage <= 70) {
  //     return "60";
  //   } else if (percentage > 48 && percentage <= 55) {
  //     return "50";
  //   } else if (percentage > 35 && percentage <= 48) {
  //     return "45";
  //   } else if (percentage > 20 && percentage <= 35) {
  //     return "25";
  //   }
  //   return "10";
  // })();

  return (
    <div
      className={`w-full h-full flex flex-col justify-between items-center bg-${bgColor} py-10 xl:py-12 px-4 lg:px-2 xl:px-6 font-source-sans-pro rounded-md lg:rounded-b-none lg:rounded-t-md space-y-8`}>
      <div className='flex flex-col justify-between space-y-4 '>
        <div
          className={`h-10 md:h-12 lg:h-16 3xl:h-20 font-bold text-center text-base md:text-xl lg:text-2xl 3xl:text-3xl text-${headingColor}`}>
          {heading}
        </div>
        <div
          className={`text-center card-content  text-${descriptionColor} lg:px-12`}>
          {description}
        </div>
      </div>
      <div className=''>
        <img src={image} alt='stats' height='h-full' width='w-full' />
      </div>
      {/*  <div className='circle-wrap'>
        <div className='circle'>
          <div className={` mask full${percent} full`}>
            <div className={` bg-${percentageColor} fill${percent} fill`}></div>
          </div>

          <div className={`mask half`}>
            <div className={` bg-${percentageColor} fill${percent} fill`}></div>
          </div>

          <div
            className={`w-40 h-40 rounded-full bg-${bgColor} font-bold text-center text-4xl text-${headingColor} absolute z-10 mt-2 ml-2`}>
            <div className='my-14'>
              {percentage}
              <span className='text-base'>%</span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default StatsCard;
