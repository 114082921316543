import React, { useState } from "react";
import Image from "../Image";
import download from "../../Assets/Icons/download-icon.svg";
import { useWindowDimensions } from "../../CustomHooks/ScreenH-W";

const ExamCard = ({
  width,
  title,
  description,
  image,
  tag,
  isAvailable,
  moreInfo,
}) => {
  const [showDownload, setShowDownload] = useState(false);
  const { width: windowWidth, height } = useWindowDimensions();

  const onMouseEnterHandler = () => {
    if (windowWidth > 768) setShowDownload(true);
  };
  const onMouseLeaveHandler = () => {
    setShowDownload(false);
  };
  return (
    <div
      className={`w-${width} h-full shadow rounded-md overflow-hidden border border-E1E1E1`}
      onMouseOver={onMouseEnterHandler}
      onMouseOut={onMouseLeaveHandler}>
      {showDownload ? (
        <div className='flex bg-230D4B justify-center items-center h-full w-full p-4'>
          <div>
            <div className='text-FFA300 text-center'>
              {/* {isAvailable ? "Download Syllabus" : "Comming Soon"} */}
              {moreInfo}
            </div>
            {/* {isAvailable ? (
              <img
                src={download}
                className='mx-auto my-2 cursor-pointer'
                alt='download icon'
              />
            ) : (
              <img
                src={download}
                className='mx-auto my-2 cursor-pointer'
                alt='download icon'
              />
            )} */}
          </div>
        </div>
      ) : (
        <div>
          <div className='relative'>
            <Image
              src={image}
              width='w-full'
              height='h-auto'
              alt='exam'
              bgColor='FAFAFA'
            />
            {tag && (
              <div className='absolute -bottom-3 right-3 text-2xs bg-E5D472 rounded-full px-2 py-1'>
                {tag}
              </div>
            )}
          </div>
          <div className='p-4 md:p-6 font-roboto-slab '>
            <div className='font-bold card-heading'>{title}</div>
            <div className='card-content  line-clamp-4 lg:line-clamp-3'>
              {description}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamCard;
