import React, { Component } from "react";
import StudentCategory from "./CategorySelection";
import UserSelection from "./UserSelection";
import Slider from "react-slick";
import Slide1 from "./Slides/Slide1";
import Slide2 from "./Slides/Slide2";
import Slide3 from "./Slides/Slide3";
import Button from "../../Components/Button";
import ClickableImage from "../../Components/ClickableImage";
import Aankalan from "../../Assets/Icons/Aankalan.png";
import { STUDENTS } from "../../Constants/Flow";
import { getLocalStorage, getRouteFromUsertype } from "../../Utility/Helper";

export default class Layout extends Component {
  state = {
    mode: "USER_SELECTION",
    Slider: null,
    userSelection: false,
  };

  updateMode = (mode) => {
    this.setState({
      mode: mode,
    });
  };

  componentDidMount() {
    this.setState({ Slider: this.slider });

    let userObj = getLocalStorage("user");
    if (userObj) {
      this.props.history.push(getRouteFromUsertype(userObj.userType));
    }
  }

  showUserSelection = () => {
    this.setState({
      userSelection: true,
    });
  };

  render() {
    const content = (() => {
      switch (this.state.mode) {
        case "USER_SELECTION":
          return <UserSelection changeMode={this.updateMode} />;
        case STUDENTS:
          return <StudentCategory mode={this.state.mode} />;

        default:
          return null;
      }
    })();

    const settings = {
      dots: false,
    };

    return (
      <React.Fragment>
        {this.state.userSelection ? (
          <div className='h-screen flex flex-col bg-gradient  py-8 px-8 justify-center items-center'>
            <div className='flex items-end mb-8'>
              <ClickableImage
                src={Aankalan}
                alt='logo'
                height='h-8'
                width='w-8'
                link='/'
              />
              <div className='text-white uppercase font-source-sans-pro font-semibold text-sm 3xl:text-base'>
                NAKALAN - THE ASSESSMENTS
              </div>
            </div>
            {content}
          </div>
        ) : (
          <div className='grid grid-cols-11 3xl:grid-cols-10 md:min-h-screen bg-gradient relative'>
            <div className='col-span-11 xl:col-span-7 3xl:col-span-6 space-y-6'>
              <div>
                <Slider
                  {...settings}
                  arrows={false}
                  autoplay={true}
                  adaptiveHeight={true}
                  fade={true}
                  autoplaySpeed={4000}
                  pauseOnHover={false}
                  dots={false}
                  ref={(slider) => (this.slider = slider)}>
                  <Slide1 Slider={this.state.Slider} />
                  <Slide2 Slider={this.state.Slider} />
                  <Slide3 Slider={this.state.Slider} />
                </Slider>
              </div>
            </div>
            <div className='hidden xl:flex xl:col-span-4 bg-white xl:my-12 xl:mr-12 py-8 px-8 md:px-12   items-center xl:rounded-xl'>
              {content}
            </div>
            <div className='absolute inset-y-3/4 inset-x-0 xl:hidden mx-auto w-10/12  rounded-lg'>
              <Button width='w-full' onClick={this.showUserSelection}>
                Let's Get Started
              </Button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
