import React from "react";
import OtpInput from "../../../../Components/CreateContest/OtpInput";

const PhoneOtp = ({ phoneNumber, verifyOTP, reSendOTP, setShowOTP ,edit}) => {
  const reSendOTPHandler = () => {
    document.getElementById("firstOtpInput").value = "";
    document.getElementById("secondOtpInput").value = "";
    document.getElementById("thirdOtpInput").value = "";
    document.getElementById("fourthOtpInput").value = "";
    document.getElementById("fifthOtpInput").value = "";
    document.getElementById("sixthOtpInput").value = "";
    reSendOTP();
  };
  return (
    <div className='space-y-8 font-source-sans-pro'>
      <div className='text-lg sm:text-3xl text-9A9A9A'>
        We have sent an OTP on &nbsp;
        <span className='inline-flex items-center space-x-2 bg-FFF7EA rounded-md border border-FFFFFF shadow-sm text-black px-2 py-1'>
          <div>{phoneNumber}</div>
          <div className='cursor-pointer' onClick={edit}>
            <ion-icon name='create'></ion-icon>
          </div>
        </span>
      </div>
      <div className='space-y-4'>
        <div className='text-9A9A9A sub-heading'>Please enter OTP here</div>
        <OtpInput checkOtpHandler={verifyOTP} />
        {/* <div onClick={reSendOTPHandler} className='w-24 border-2 border-76C680 rounded-2xl font-semibold text-76C680 text-xs text-center py-1'>
          Re-send OTP
        </div> */}
      </div>
    </div>
  );
};

export default PhoneOtp;
