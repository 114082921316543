import './App.css';
import { BrowserRouter } from "react-router-dom";
import { PublicRoutes } from "./Routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Toaster } from "react-hot-toast";
import ProfileContextWrapper from './Context/ProfileContext'

const App = () => {
  return (
    <BrowserRouter>
      <ProfileContextWrapper>
        <PublicRoutes />
      </ProfileContextWrapper>
      <Toaster />
    </BrowserRouter>
  );
};

export default App;
