import React, { useState } from 'react'
import SelectDownArrow from "../../Assets/Icons/SelectDownArrow.svg";


export default ({
    selectedItem,
    onClick,
    children
}) => {
    const [showItems, setShowItems] = useState(false)
    return (
        <div class="w-full relative mb-4">
            <div onClick={()=>setShowItems(true)} class={`flex justify-between items-center px-2 py-2 shadow ${showItems?"rounded-t":"rounded"}`}>
                <div class="z-10 w-full block capitalize">
                    {selectedItem}
                </div>
                <div>
                    <img src={SelectDownArrow} alt='down arrow' />
                </div>
            </div >
            <div className={`${showItems ? "block" : "hidden"} transition-opacity`}>
                <div onClick={()=>setShowItems(false)} class="fixed inset-0 h-full w-full z-10"></div>
                <div class="absolute left-0 w-full py-2 bg-white rounded-b-md shadow z-20">
                    {React.Children.map(children, (child) =>
                        React.cloneElement(child, {
                            onClick: onClick,
                            selectedItem:selectedItem,
                            close:()=>setShowItems(false)
                        })
                    )}
                </div>
            </div>
        </div >
    )
}
