import React from "react";

const PublishedQuestion = ({
  index,
  question,
  options,
  correctAnswer,
  explanation,
}) => {
  return (
    <div className='font-source-sans-pro space-y-2 mb-8 md:mb-16'>
      <div className='font-semibold text-2xs md:text-sm xl:text-base 3xl:text-lg uppercase'>
        QUESTION {index + 1}
      </div>
      <div className='font-semibold heading3 capitalize'>{question}</div>
      <div className='grid grid-cols-2 gap-2 pt-4'>
        {options.map((option, optionIndex) => (
          <div
            className={`col-span-2 flex items-center text-2xs md:text-sm xl:text-base 3xl:text-lg h-12 border-2 ${
              correctAnswer.includes(optionIndex + 1)
                ? "bg-green-100 border-green-400"
                : "border-D3D0D0 bg-FAFAFA"
            } rounded-lg`}>
            <div className='px-4'>
              {String.fromCharCode(97 + optionIndex).toUpperCase()}
            </div>

            <div className='px-4'>{option}</div>
          </div>
        ))}
      </div>
      {explanation && (
        <div>
          <b>Explanation: &nbsp;</b>
          {explanation}
        </div>
      )}
    </div>
  );
};

export default PublishedQuestion;
