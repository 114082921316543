import React from "react";

const CoachingInstituteSection = ({ heading, description, partners }) => {
  return (
    <div className='w-8/10-center'>
      <div className='text-center'>
        <div dangerouslySetInnerHTML={{ __html:heading }} className='heading'/>
        <div dangerouslySetInnerHTML={{ __html:description }} className='sub-heading mt-8'/>

        <div className='grid grid-cols-2 gap-4 lg:gap-8 md:grid-cols-4 mt-8 lg:mt-14'>
          {partners.map((partner, index) => (
            <div
              key={index}
              className='lg:h-64 rounded-md border-2 border-gray-300 flex justify-center items-center'>
              <img
                src={partner}
                alt='coaching partners'
                className='w-full my-14 sm:w-3/5 h-auto'
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoachingInstituteSection;
