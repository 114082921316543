import React from "react";

import ball from "../../Assets/Icons/ball-icon.svg";
import man from "../../Assets/Icons/running-man-icon.svg";
import video from "../../Assets/Icons/video-icon.svg";
import earth from "../../Assets/Icons/earth-icon.svg";
import school from "../../Assets/Icons/school-icon.svg";

import Slider from "react-slick";
import Image from "../../Components/Image";

import politicalGeek from "../../Assets/Images/political-geek.svg";
import learnaholic from "../../Assets/Images/learnaholic.svg";
import movieBuff from "../../Assets/Images/movie-buff.svg";
import sportsLover from "../../Assets/Images/sports-lover.svg";

const funData = [
  {
    text: "Political Geek?",
    description: "All Work & No Play Makes US Dull",
    subdescription:
      "At Aakalan, you can laso have a few light moments with our Fun Quizzes",
    image: politicalGeek,
  },
  {
    text: "Learnaholic?",
    description: "All Work & No Play Makes US Dull",
    subdescription:
      "At Aakalan, you can laso have a few light moments with our Fun Quizzes",
    image: learnaholic,
  },
  {
    text: "Movie Buff?",
    description: "All Work & No Play Makes US Dull",
    subdescription:
      "At Aakalan, you can laso have a few light moments with our Fun Quizzes",
    image: movieBuff,
  },
  {
    text: "Sports Lover?",
    description: "All Work & No Play Makes US Dull",
    subdescription:
      "At Aakalan, you can laso have a few light moments with our Fun Quizzes",
    image: sportsLover,
  },
];

const Slide = ({ text, description, subDescription, image }) => {
  return (
    <div className='grid grid-cols-2 bg-F5F5F5 font-source-sans-pro text-center xl:text-left'>
      <div className='col-span-2 xl:col-span-1 flex items-center justify-center  relative py-8 xl:py-0 xl:pl-6'>
        <div className='font-open-sans-pro mx-auto w-9/12 4xl:w-7/12 lg:pl-6 2xl:pl-0'>
          <div className='text-xs xl:text-2xl font-bold'>Are You a </div>
          <div className='font-roboto-slab text-3xl xl:text-5xl 4xl:text-6xl text-FFA200 mt-8 uppercase font-black'>
            {text}
          </div>
          <div className=' text-sm xl:text-2xl font-semibold mt-8'>
            {description}
          </div>
          <div className=' text-sm xl:text-2xl mt-1'>{subDescription}</div>
          <div className='text-sm xl:text-2xl mt-8 font-semibold'>
            Attempt a{" "}
            <span className='text-FFA200 text-base xl:text-3xl'>Fun Quiz</span>{" "}
            on the App
          </div>
        </div>
        <div className='absolute top-0 left-0 '>
          <img src={ball} className='w-16 xl:w-24 3xl:w-36 h-auto' />
        </div>
        <div className='absolute inset-y-1/2 left-2 '>
          <img src={school} className=' w-12  xl:w-20  3xl:w-36   h-auto ' />
        </div>
        <div className='absolute top-3 right-0 '>
          <img src={video} className='w-16 xl:w-24  3xl:w-36  h-auto ' />
        </div>
        <div className='absolute bottom-4 left-0'>
          <img src={man} className='w-20 xl:w-36 3xl:w-48 h-auto ' />
        </div>
        <div className='absolute bottom-6 right-6 '>
          <img src={earth} className='w-16  xl:w-24  3xl:w-36  h-auto ' />
        </div>
      </div>
      <div className='col-span-2 xl:col-span-1 flex items-center'>
        <Image src={image} alt='fun Section' height='h-auto' width='w-full' />
      </div>
    </div>
  );
};

const FunSection = () => {
  return (
    <Slider
      arrows={false}
      autoplay={true}
      adaptiveHeight={true}
      fade={true}
      pauseOnHover={false}
      autoplaySpeed={2000}
      dots={false}>
      {funData.map((data, index) => (
        <Slide
          text={data.text}
          description={data.description}
          subDescription={data.subdescription}
          image={data.image}
        />
      ))}
    </Slider>
  );
};

export default FunSection;
