import { useContext } from "react";
import { ProfileContext } from "../../../Context/ProfileContext";
import { validEmail, validPhone } from "../../../Utility/Helper";

export const ShowNextButton = () => {
  const {
    stage,
    step,
    name,
    email,
    qualification,
    coreSubjects,
    subjects,
    phoneNumber,
    city,
    state,
    accHolderName,
    accNumber,
    IFSC,
    verifiedPhoneNo,
    branchName,
    panCard,
    upiID,
  } = useContext(ProfileContext);

  switch (stage) {
    case 1:
      return verifiedPhoneNo ? true : false;
    case 2:
      return basicDetailsCheck(
        step,
        name,
        email,
        qualification,
        coreSubjects,
        subjects
      );
    case 3:
      return city && state ? true : false;
    case 4:
      return bankDetailsCheck(
        step,
        accHolderName,
        accNumber,
        IFSC,
        branchName,
        panCard,
        upiID
      );
    default:
      return;
  }
};
const basicDetailsCheck = (
  step,
  name,
  email,
  qualification,
  coreSubjects,
  subjects
) => {
  switch (step) {
    case 1:
      return name.length > 2 && validEmail(email) ? true : false;
    case 2:
      return true;
    case 3:
      return true;
    case 4:
      return qualification && coreSubjects.length && subjects.length
        ? true
        : false;
    case 5:
      return true;
  }
};

const bankDetailsCheck = (
  step,
  accHolderName,
  accNumber,
  IFSCCode,
  branchName,
  panCard,
  upiID
) => {
  switch (step) {
    case 1:
      return (accHolderName && accNumber && IFSCCode && branchName) || upiID
        ? true
        : false;
      break;
  }
};

export const handleBackNavigation = (
  stage,
  setStage,
  step,
  setStep,
  history
) => {
  switch (step) {
    case 1: {
      if (stage === 1) {
        console.log(history.location.pathname);
        if (history.location.pathname === "/create-test-series/profile") {
          return history.push("/create-test-series");
        } else {
          return history.push("/create-contest");
        }
      } else {
        setStage(stage - 1);
        setStep(1);
      }
      break;
    }
    default:
      return setStep(step - 1);
  }
};
