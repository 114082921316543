import React, { useState } from "react";
import Button from "../../Components/Button";
import TeacherProfileCard from "../../Components/Card/TeacherProfileCard";
import { TitleWrapper } from "../../Components/TitleWrapper";
import SelectGroup from "../../Components/CustomSelect/SelectGroup";
import SelectOption from "../../Components/CustomSelect/SelectOption";
import OfferSection from "../Sections/OfferSection";
import DownloadAppSection from "../Sections/DownloadAppSection";
import ProfileCard from "../../Components/Card/ProfileCard";
// import AankalanData from "../../Data/Aankalan.json";


let AankalanData = require('../../Data/Aankalan.json')

const Teachers = () => {
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedExpertise, setSelectedExpertise] = useState("");
  const [selectedExam, setSelectedExam] = useState("");
  const {
    teacherHeading: headingDA,
    teacherDescription: descriptionDA,
    adImage,
    qrImage,
  } = AankalanData["DOWNLOAD_APP"];

  return (
    <div className='bg-F7F7FF'>
      <TitleWrapper title='YouTuber Section'>
        <div className='bg-white mx-7 lg:mx-16 xl:mx-28 -mt-28  rounded p-6 lg:p-16 '>
          <div className='text-center'>
            <div className='heading'>Know About Our Experts In Your City</div>
            <div className='sub-heading'>
              Youtubers who are the strong pillars of aankalan Family
            </div>
          </div>
          <div className='mt-10 grid grid-cols-4 lg:gap-4 xl:gap-8'>
            <div className='col-span-4 lg:col-span-1'>
              <SelectGroup
                id='city'
                placeholder='Select Your City'
                selectedOption={selectedCity}
                selectOptionHandler={(value) => {
                  setSelectedCity(value);
                }}>
                <SelectOption value='Delhi'>Delhi</SelectOption>
                <SelectOption value='Mumbai'>Mumbai</SelectOption>
                <SelectOption value='Goa'>Goa</SelectOption>
              </SelectGroup>
            </div>
            <div className='col-span-4 lg:col-span-1'>
              <SelectGroup
                id='exam'
                placeholder='Select Exam'
                selectedOption={selectedExam}
                selectOptionHandler={(value) => {
                  setSelectedExam(value);
                }}>
                <SelectOption value='IiT-Jee'>Iit-Jee</SelectOption>
                <SelectOption value='Neet'>Neet</SelectOption>
              </SelectGroup>
            </div>
            <div className='col-span-4 lg:col-span-1'>
              <SelectGroup
                id='expertise'
                placeholder='Select Expertise'
                selectedOption={selectedExpertise}
                selectOptionHandler={(value) => {
                  setSelectedExpertise(value);
                }}>
                <SelectOption value='physics'>physics</SelectOption>
                <SelectOption value='maths'>Maths</SelectOption>
              </SelectGroup>
            </div>
            <div className='col-span-4 lg:col-span-1 mt-3'>
              <Button width='w-full' height='h-12'>
                Search
              </Button>
            </div>
          </div>
        </div>

        <div className='py-11'>
          <div className='px-7 lg:px-16 xl:px-28'>
            <div className='heading text-center '>
              Youtubers Who Recently Joined Us
            </div>
          </div>
          <div className='flex overflow-without-scrollbar mt-8 pl-7 lg:px-16 xl:px-28 gap-6'>
            <div>
              <div className='w-72 lg:w-80'>
                <ProfileCard />
              </div>
            </div>
            <div>
              <div className='w-72 lg:w-80'>
                <ProfileCard />
              </div>
            </div>
          </div>
        </div>
        <div className='bg-FAFAFA '>
          <div className=' flex md:w-2/3 mx-auto py-16 space-x-4 items-center'>
            <div className='flex-grow text-center'>
              <div className='heading'>
                High Quality Test And Content From Experts Across India
              </div>
              <div className='sub-heading mt-4'>
                Not Everyone can who apply get selected as our experts
              </div>
            </div>
          </div>
        </div>
        <div className='py-10 md:py-20 bg-white'>
          <div className='text-center'>
            <div className='heading'>Core Experts for Engineering Exams</div>
          </div>
          <div className='w-4/12 md:w-1/12 mx-auto h-1 bg-461995 mt-6 lg:mt-10'></div>
        </div>
        <div className='bg-white'>
          <div className='flex space-x-4 overflow-without-scrollbar px-7 lg:px-16 xl:px-28 '>
            {["Physics's Teacher", "Math's Teacher", "Chemistry Teacher"].map(
              (teacher) => (
                <div>
                  <div className='bg-E9E9FF border border-461995 rounded flex justify-center items-center  w-32  h-12 lg:h-16 lg:w-48'>
                    <div className=' text-xs lg:text-base '>{teacher}</div>
                  </div>
                </div>
              )
            )}
          </div>
          <div className='py-6 md:py-16 pl-7 lg:px-16 xl:px-28 '>
            <div className='flex lg:grid grid-cols-3 gap-6 overflow-without-scrollbar pb-6'>
              <div>
                <div className='w-72 lg:w-full'>
                  <ProfileCard />
                </div>
              </div>
              <div>
                <div className='w-72 lg:w-full'>
                  <ProfileCard />
                </div>
              </div>
              <div>
                <div className='w-72 lg:w-full'>
                  <ProfileCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </TitleWrapper>
      {/* <OfferSection /> */}
      <DownloadAppSection
        heading={headingDA}
        description={descriptionDA}
        adImage={adImage}
        qrImage={qrImage}
      />
    </div>
  );
};

export default Teachers;
