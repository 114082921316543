import React, { useState } from "react";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import RadioButton from "../../Components/Radio/Radio.Button";
import RadioGroup from "../../Components/Radio/Radio.Group";
import RadioTabs from "../../Components/Radio/Radio.Tab";
import { TitleWrapper } from "../../Components/TitleWrapper";

import Tution from "../../Assets/Icons/Tution.svg";
import SelfStudy from "../../Assets/Icons/SelfStudy.svg";
import College from "../../Assets/Icons/College.svg";
import DigitalClasses from "../../Assets/Icons/DigitalClasses.svg";
import Modal from "../../Components/Modal";
import Congrats from "../Modals/Congrats";
import { Link } from "react-router-dom";
import SelectGroup from "../../Components/CustomSelect/SelectGroup";
import SelectOption from "../../Components/CustomSelect/SelectOption";
// import StateCityData from "../../Data/StateCity.json";
import UploadFiles from "../../Components/UploadFiles";

import book from "../../Assets/Icons/Registration/book.svg";
import onlineApp from "../../Assets/Icons/Registration/online-app.svg";
import school from "../../Assets/Icons/Registration/school.svg";
import tution from "../../Assets/Icons/Registration/tution.svg";
import university from "../../Assets/Icons/Registration/university.svg";

import knowledge from '../../Assets/Icons/Registration/knowledge.png'
import teach from '../../Assets/Icons/Registration/teach.png'
import police from '../../Assets/Icons/Registration/police.png'
import engineering from '../../Assets/Icons/Registration/engineering.png'
import ssc from '../../Assets/Icons/Registration/ssc.png'
import medicine from '../../Assets/Icons/Registration/medicine.png'



let StateCityData = require('../../Data/StateCity.json')












const redundantStates = StateCityData.map((item, index) => item.state);
const states = Array.from(new Set(redundantStates));
const cities = StateCityData.map((item, index) => item.name);

const TeacherRegistration = () => {
    const [showCongratsModal, setShowCongratsModal] = useState(false);
    const [teachingIndustry, setTeachingIndustry] = useState("school")
    const [prepModeSec, setPrepModeSec] = useState("Self-Study");

    // Form Data State Management
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedExperience, setSelectedExperience] = useState("")
    const [selectedQualification, setSelectedQualification] = useState("")
    const [selectedSubject, setSelectedSubject] = useState("")
    const [gender, setGender] = useState("male")
    const [exam, setExam] = useState('engg')


    return (
        <div className='bg-F7F7FF'>
            <TitleWrapper title='Student Registration'>
                <div className='py-10 sm:py-24 mx-7 lg:mx-16 xl:mx-28 -mt-28 rounded bg-white px-6 lg:px-16 xl:px-32'>
                    <div className='text-center'>
                        <div className='heading'>Complete Your Registration</div>
                        <div className='sub-heading mt-4'>
                            Enter your details to set up your account
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-8 mt-20'>
                        <div className='col-span-2 sm:col-span-1'>
                            <Input placeholder='Enter Your First Name' title='First Name' />
                        </div>
                        <div className='col-span-2 sm:col-span-1'>
                            <Input placeholder='Enter Your Last Name' title='Last Name' />
                        </div>
                        <div className='col-span-2 sm:col-span-1'>
                            <Input placeholder='DD/MM/YYYY' title='Date Of Birth' />
                        </div>
                        <div className='col-span-2 sm:col-span-1'>
                            <RadioGroup
                                selectedValue={gender}
                                title='Gender'
                                onClick={(value) => setGender(value)}>
                                <RadioButton value='male'>
                                    male
                                </RadioButton>
                                <RadioButton value='female'>
                                    female
                                </RadioButton>
                            </RadioGroup>
                        </div>
                        <div className='col-span-2 sm:col-span-1'>
                            <SelectGroup
                                id='state'
                                placeholder='Select Your State'
                                title='State'
                                selectedOption={selectedState}
                                selectOptionHandler={(value) => {
                                    setSelectedState(value);
                                }}>
                                {states.map((state, index) => (
                                    <SelectOption value={state}>{state}</SelectOption>
                                ))}
                            </SelectGroup>
                        </div>
                        <div className='col-span-2 sm:col-span-1'>
                            <SelectGroup
                                id='city'
                                placeholder='Select Your City'
                                title='City'
                                selectedOption={selectedCity}
                                selectOptionHandler={(value) => {
                                    setSelectedCity(value);
                                }}>
                                {cities.map((city, index) => (
                                    <SelectOption value={city}>{city}</SelectOption>
                                ))}
                            </SelectGroup>
                        </div>
                        <div className='col-span-2 sm:col-span-1'>
                            <SelectGroup
                                id='exp'
                                placeholder='Select Year Of Experience in Teaching'
                                title='Year Of Experience'
                                selectedOption={selectedExperience}
                                selectOptionHandler={(value) => {
                                    setSelectedExperience(value);
                                }}>
                                {['2+', '5+', '10+', '20+'].map((city, index) => (
                                    <SelectOption value={city}>{city}</SelectOption>
                                ))}
                            </SelectGroup>
                        </div>

                        <div className='col-span-2 sm:col-span-1'>
                            <SelectGroup
                                id='qualification'
                                placeholder='Select Your Highest Qualification'
                                title='Highest Qualification'
                                selectedOption={selectedQualification}
                                selectOptionHandler={(value) => {
                                    setSelectedQualification(value);
                                }}>
                            </SelectGroup>
                        </div>
                        <div className='col-span-2 sm:col-span-1'>
                            <SelectGroup
                                id='expertise'
                                placeholder='Select Your Subject'
                                title='Core Expertise'
                                selectedOption={selectedSubject}
                                selectOptionHandler={(value) => {
                                    setSelectedSubject(value);
                                }}>
                                {['Physics', 'chemistry', 'Maths', 'English'].map((city, index) => (
                                    <SelectOption value={city}>{city}</SelectOption>
                                ))}
                            </SelectGroup>
                        </div>
                        <div className='col-span-2 sm:col-span-1'>
                            <Input placeholder='Enter Your Website Url / Youtube Link' title='Website Url/Youtube Link ( if any)' />
                        </div>
                        <div className='col-span-2'>
                            <RadioGroup
                                selectedValue={teachingIndustry}
                                title='Tell us more about your teaching'
                                onClick={setTeachingIndustry}>
                                <RadioTabs icon={school} value='school'>
                                    Teaches At School
                                </RadioTabs>
                                <RadioTabs icon={tution} value='tution'>
                                    Teaches At Tution
                                </RadioTabs>
                                <RadioTabs icon={university} value='institutional'>
                                    Teaches At Institutio
                                </RadioTabs>
                                <RadioTabs icon={onlineApp} value='digital-classes'>
                                    Teaches Through Digital Classes / App
                                </RadioTabs>

                                <RadioTabs icon={book} value='others'>
                                    Others
                                </RadioTabs>
                            </RadioGroup>
                        </div>
                        <div className='col-span-2'>
                            <RadioGroup
                                selectedValue={exam}
                                title='What Competitive Examination Are You Teaching?'
                                onClick={(value) => setExam(value)}>
                                <RadioTabs icon={engineering} value='engg'>
                                    Engg.
                                </RadioTabs>
                                <RadioTabs icon={medicine} value='medical'>
                                    Medical
                                </RadioTabs>
                                <RadioTabs icon={ssc} value='ssc'>
                                    SSC
                                </RadioTabs>
                                <RadioTabs icon={knowledge} value='banking'>
                                    Banking
                                </RadioTabs>
                                <RadioTabs icon={teach} value='teaching'>
                                    Teaching
                                </RadioTabs>
                                <RadioTabs icon={ssc} value='VDO'>
                                    VDO
                                </RadioTabs>
                                <RadioTabs icon={knowledge} value='railway'>
                                    Railway
                                </RadioTabs>
                                <RadioTabs icon={police} value='police'>
                                    Police
                                </RadioTabs>
                                <RadioTabs icon={police} value='defence'>
                                    Defence
                                </RadioTabs>
                            </RadioGroup>
                        </div>
                        <div className='col-span-2'>
                            <UploadFiles title="We would like to know more about you...upload your Resume" />
                        </div>
                        <div className='col-span-2'>
                            <UploadFiles title="Its good to show your self as brand ...upload your photograph" />
                        </div>


                    </div>
                    <div className='flex flex-col sm:flex-row justify-center mt-20'>
                        <Button
                            onClick={() => {
                                window.scrollTo(0, 0);
                                setShowCongratsModal(true);
                            }}
                            width='w-full sm:w-60'
                            height='h-12 sm:h-16'
                            textTransform='capitalize'>
                            Register As a Teacher
                        </Button>
                        <Link to='/students'>
                            <Button
                                className=''
                                bgColor='white'
                                textColor='black'
                                width='w-full sm:w-60'
                                height='h-12 sm:h-16'
                                textTransform='capitalize'>
                                Take Me Back To Homepage
                            </Button>
                        </Link>
                    </div>
                </div>
                <Modal
                    visible={showCongratsModal}
                    isClosable={false}
                    width='w-11/12 md:w-10/12'>
                    <Congrats close={() => setShowCongratsModal(false)} />
                </Modal>
            </TitleWrapper>
        </div>
    );
};

export default TeacherRegistration;
