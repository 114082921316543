import React from "react";
import TestimonialCard from "../../Components/Card/TestimonialCard";
import ScrollableFrame from "./ScrollableFrame";

const TestimonialSection = ({ heading, description, testimonialsList }) => {
  return (
    <div className='space-y-8 md:space-y-24 md:py-32 py-10 sm:py-24'>
      <div className='space-y-5 md:space-y-8 w-8/10-center-without-py'>
        <div
          dangerouslySetInnerHTML={{ __html: heading }}
          className='heading text-center'
        />
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className='sub-heading text-center'
        />
      </div>
      <div className='w-9/10-left-without-py'>
        <ScrollableFrame
          theme='LIGHT'
          containerId='testimonial_section'
          isButtonVisible={false}>
          <div
            id='testimonial_section'
            className='flex space-x-8 overflow-without-scrollbar'>
            {testimonialsList.map((testimonial, index) => (
              <div id={`testimonial_section-card-${index}`}>
                <div className='w-72 md:w-80 xl:w-112 h-full'>
                  <TestimonialCard
                    key={index}
                    name={testimonial.name}
                    description={testimonial.description}
                    image={testimonial.image}
                    title={testimonial.title}
                    index={index}
                  />
                </div>
              </div>
            ))}
          </div>
        </ScrollableFrame>
      </div>
    </div>
  );
};

export default TestimonialSection;
