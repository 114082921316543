import urls,{ TOKEN } from "../Urls";
import { handleResponse } from "../Utility/Helper";

const submitDetils = (first_name, email, message, phone) => {
  const data = {
    type: "ContactUs",
    first_name,
    email,
    phone,
    message,
  };

  const requestOptions = {
    method: "POST",
    headers: { Token: TOKEN, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(urls["contactUs"], requestOptions).then(handleResponse);
};



export const contactUsService = {
  submitDetils,
};
