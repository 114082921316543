import { AANKALAN } from "../../Constants/Flow";
import { ENGINEERING } from "../../Constants/StudentCategories";
import { userConstants } from "../Constants";

const initialState = {
  userType: AANKALAN, // STUDENT , TEACHER , PARENT , COACHINGINSTITUTIONS
  category: ENGINEERING, // ENGINEERING, MEDICAL, RAILWAY, BANKING
};

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.USER_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
