import React, { useState, useContext, useEffect } from "react";
import Jumbotron from "../../Components/Jumbotron/Jumbotron";
import Modal from "../../Components/Modal";
import ContactUs from "../Modals/ContactUs";
import DownloadAppSection from "../Sections/DownloadAppSection";
import JumbotronData from "../../Data/Jumbotron.json";
// import AankalanData from "../../Data/Aankalan.json";
import Cloud from "../../Assets/Icons/Cloud.svg";
import { Link, useHistory } from "react-router-dom";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import OurFeatures from "../Aankalan/OurFeatures";
import SoftwareEnggWithBg from "../../Assets/Icons/SoftwareEnggWithBg.svg";
import CognitivePhysc from "../../Assets/Icons/CognitivePhysc.svg";
import CirriculumTheorist from "../../Assets/Icons/CirriculumTheorist.svg";
import { ProfileService } from "../../Services/profile.service";
import toast from "react-hot-toast";
import { ProfileContext } from "../../Context/ProfileContext";
import Group from "../../Assets/Icons/Group.svg";
// import CreateTestSeriesAtf from "../../Assets/Images/CreateTestSeriesAtf.png";
import { getAnalytics, logEvent } from "firebase/analytics";

let AankalanData = require('../../Data/Aankalan.json')


const CreateContest = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { setName, setEmail, setUserID } = useContext(ProfileContext);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'CREATE_PROFILE', {
      landed: "true"
    });
  }, [])

  const {
    heading: headingFAQ,
    createTestSeriesDescription: descriptionFAQ,
    btnText: btnTextFAQ,
  } = JumbotronData["FAQ"];
  const {
    heading: headingCU,
    createTestSeriesDescription: descriptionCU,
    btnText: btnTextCU,
  } = JumbotronData["CONTACT_US"];
  const {
    teacherHeading: headingDA,
    teacherDescription: descriptionDA,
    adImage,
    qrImage,
  } = AankalanData["DOWNLOAD_APP"];

  const { heading: headingCC, statsArray: statsArrayCC } =
    AankalanData["CREATE_CONTEST"];

  const statsImagesCC = [
    SoftwareEnggWithBg,
    CognitivePhysc,
    CirriculumTheorist,
  ];

  const validateCode = () => {
    setLoading(true);
    ProfileService.validateTeacherCode(code)
      .then((res) => {
        setName(res.name);
        setEmail(res.email);
        setUserID(res.id);
        setLoading(false);
        history.push("/create-test-series/form");
      })
      .catch((err) => {
        toast.error("Wrong Code");
        setLoading(false);
      });
  };

  return (
    <div className='mt-16 lg:mt-20 3xl:mt-24 relative overflow-hidden'>
      <div className='font-source-sans-pro text-center lg:text-left'>
        <div className='relative flex flex-col justify-center px-9 bg-27878D bg-opacity-75  sm:px-16 xl:px-28 2xl:px-36 3xl:px-44 h-70vh sm:h-60vh '>
          <div className='hidden xl:block absolute -bottom-0 2xl:-bottom-12 3xl:-bottom-16 right-0 left-1/4 create-test-series-atf-bg h-60vh'></div>
          <div className='text-4.5xl lg:text-6xl 3xl:text-7xl text-white uppercase font-bold'>
            SUBMIT TEST SERIES
          </div>
          {/* <div className='text-sm sm:text-xl lg:text-2xl 3xl:text-4xl mt-4 text-white'>
            Setup your test series within few minutes and
            <br />
            reach out to students nationwide
          </div> */}
          <div className='text-sm sm:text-base 3xl:text-xl text-white mt-9'>
            ENTER YOUR UNIQUE AANKALAN CODE
          </div>
          <div className='flex flex-col lg:flex-row justify-center lg:justify-start space-y-4  lg:space-y-0 lg:space-x-4 mt-3'>
            <div className='w-48 lg:w-3/12 mx-auto lg:mx-0'>
              <Input
                onChange={setCode}
                value={code}
                placeholder='Enter Your Code'
              />
            </div>
            <div className='mx-auto'>
              <Button
                onClick={validateCode}
                loading={loading}
                width='w-48'
                outline={true}
                buttonColor='FFFFFF'>
                SUBMIT
              </Button>
            </div>
          </div>
          <div className='text-sm sm:text-base 3xl:text-xl text-white mt-6'>
            Don't have your unique aankalan code?
          </div>
          <div className='mt-2'>
            <Link to='/create-test-series/welcome'>
              <Button width='w-48'>Create your code</Button>
            </Link>
          </div>
          {/* <div className='text-sm sm:text-base 3xl:text-xl text-white mt-3'>
            To get your code, setup your profile{" "}
            <Link to='/create-test-series/welcome'>
              <u className='cursor-pointer'>click here</u>
            </Link>
          </div> */}
          <div className='text-sm sm:text-base 3xl:text-xl text-white 4xl:text-xl mt-2 sm:inline-flex'>
            If you are facing any problem then contact us at
            <a href='mailto:test@aankalan.app'>
              <div className='text-FFA200 underline sm:ml-1 font-bold'>
                test@aankalan.app
              </div>
            </a>
          </div>
        </div>
        <div className='bg-F7F7F7 px-9 sm:px-16 xl:px-28 2xl:px-36 3xl:px-44'>
          <div className='py-12 text-393939'>
            <div>WHY SETUP A TEST SERIES AT AANKALAN?</div>
            <div className='grid grid-cols-12 xl:flex 2xl:space-x-6 3xl:space-x-8 mt-6 text-sm 2xl:text-base 3x:text-lg'>
              <div className='col-span-12 md:col-span-6 flex space-x-4  justify-start sm:justify-center lg:justify-start items-center flex-grow'>
                <img
                  src={Group}
                  className='h-14 w-14 rounded-full'
                  alt='group'
                />
                <div>24X7 Aankalan team support</div>
              </div>
              <div className='col-span-12 md:col-span-6 flex space-x-4  justify-start sm:justify-center lg:justify-start items-center flex-grow'>
                <img
                  src={Group}
                  className='h-14 w-14 rounded-full'
                  alt='group'
                />
                <div>Zero joining fees</div>
              </div>
              <div className='col-span-12 md:col-span-6  flex space-x-4 justify-start sm:justify-center lg:justify-start items-center flex-grow'>
                <img
                  src={Group}
                  className='h-14 w-14 rounded-full'
                  alt='group'
                />
                <div>Reachout to nationwide students</div>
              </div>
              <div className='col-span-12 md:col-span-6 flex space-x-4  justify-start sm:justify-center lg:justify-start items-center flex-grow'>
                <img
                  src={Group}
                  className='h-14 w-14 rounded-full'
                  alt='group'
                />
                <div>Increase followers</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='font-source-sans-pro pt-10 sm:pt-24 text-center px-6'>
        <div className='heading'>Welcome to WiseMagnet,</div>
        <div className='heading'>Aankalan's Exclusive Quizzer's Club</div>
        <div className='text-white px-8 text-sm sm:text-xl lg:text-2xl  py-1 bg-228F95 rounded-full mt-8 inline-block font-bold'>
          Aankalan is thrilled to announce a club for its premium quizzers.
        </div>

        <div className='text-5A5A5A text-sm sm:text-xl lg:text-2xl mt-10 w-10/12 sm:w-2/3 lg:w-1/2 mx-auto'>
          At WiseMagnet, we bring together experts who have not only achieved
          significant success in the noble profession of teaching but have also
          been able to predict the probability of questions appearing in the
          final exams. The club hosts test series created by India’s such
          highly-in-demand teachers.
        </div>
      </div>
      <OurFeatures
        heading={headingCC}
        statsArray={statsArrayCC}
        statsImages={statsImagesCC}
        bordered={true}
      />
      <DownloadAppSection
        heading={headingDA}
        description={descriptionDA}
        adImage={adImage}
        qrImage={qrImage}
      />
      <Jumbotron
        bgColor='F5F5F5'
        heading={headingFAQ}
        description={descriptionFAQ}
        btnText={btnTextFAQ}
        headingColor='000000'
        descriptionColor='000000'
        src={Cloud}
        alt='cloud'
        height='11'
        width='11'
        position=''
        buttonOnclick={() => {
          window.scrollTo(0, 0);
          history.push("/faq");
        }}
      />
      <Jumbotron
        bgColor='230D4B'
        heading={headingCU}
        description={descriptionCU}
        btnText={btnTextCU}
        headingColor='FFFFFF'
        descriptionColor='FFFFFF'
        btnColor='FFFFFF'
        btnTextColor='000000'
        src={""}
        alt=''
        height=''
        width=''
        position=''
        buttonOnclick={() => {
          window.scrollTo(0, 0);
          setShowContactModal(true);
        }}
      />
      <Modal
        visible={showContactModal}
        close={() => setShowContactModal(false)}
        width='w-11/12 md:w-10/12'>
        <ContactUs close={() => setShowContactModal(false)} />
      </Modal>
    </div>
  );
};

export default CreateContest;
