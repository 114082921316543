import Image from "../../Components/Image";
import PhoneScreen from "../../Assets/Images/PhoneScreen.png";
import Gratification from "../../Assets/Icons/AboutUs/gratification.svg";
import Gamification from "../../Assets/Icons/AboutUs/gamification.svg";
import mockTest from "../../Assets/Icons/AboutUs/mock-test.svg";
import support from "../../Assets/Icons/AboutUs/support.svg";

const imageArray = [mockTest, Gratification, support, Gamification];

const AboutUs = ({ heading, description, statsArray }) => {
  const Feature = ({ icon, text }) => {
    return (
      <div className='flex flex-col items-center text-center space-y-2'>
        <img src={icon} alt={text} className='h-20 w-20 md:h-16 md:w-16' />
        <div className='text-FFFFFF text-xs md:text-sm xl:text-base 3xl:text-lg'>
          {text}
        </div>
      </div>
    );
  };
  return (
    <div className='bg-gradient'>
      <div className='w-8/10-center-without-py pt-10 sm:pt-24'>
        <div className='grid grid-cols-12 gap-y-12 lg:gap-16'>
          <div className='col-span-12 order-2 lg:hidden'>
            <div className='flex justify-center'>
              <Image src={PhoneScreen} height='h-auto md:h-112' width='' />
            </div>
          </div>
          <div className='col-span-12 lg:col-span-7 font-source-sans-pro my-auto space-y-8 2xl:space-y-16'>
            <div
              dangerouslySetInnerHTML={{ __html: heading }}
              className='font-bold text-FFA300 md:text-left text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 3xl:text-5xl'
            />
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className='text-FFFFFF text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl 3xl:text-3xl'
            />
            <div className='grid grid-cols-2 md:grid-cols-4 mt-10 gap-4 pb-4 xl:pb-8 2xl:pb-20'>
              {statsArray.map((item, index) => (
                <Feature icon={imageArray[index]} text={item} />
              ))}
            </div>
          </div>
          <div className='hidden lg:block lg:col-span-5'>
            <Image src={PhoneScreen} height='h-auto' width='w-auto' />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
