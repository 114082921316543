import React from "react";
import Image from "../../../Components/Image";

const Interaction = ({
  message,
  description,
  image,
  imageWidth,
  imageHeight,
  messageBubbleColor = "442485",
}) => {
  return (
    <div className='flex flex-col items-center'>
      <div
        className={`font-source-sans-pro sub-heading 3xl:text-xl 4xl:text-2xl bg-${messageBubbleColor} text-FFFFFF text-center space-y-1 ellipse-message-container py-4 px-8 xl:py-8 xl:px-8 2xl:py-8 2xl:px-8 3xl:px-10`}>
        <div className='font-bold'>{message}</div>
        <div>{description}</div>
      </div>
      <Image
        src={image}
        alt='interaction girl'
        width={imageWidth}
        height={imageHeight}
      />
    </div>
  );
};

export default Interaction;
