export const handleSelect = (e, dropdownActive, setDropdownActive) => {
  setDropdownActive(!dropdownActive);
  e.stopPropagation();
};

export const sideScroll = (direction, step, containerId) => {
  let scrollAmount = 0;
  let element = document.getElementById(containerId);

  let cardWidth = getCardWidth(`${containerId}-card-0`);
  var slideTimer = setInterval(function () {
    if (direction === "LEFT") {
      element.scrollLeft -= step;
    } else {
      element.scrollLeft += step;
    }
    scrollAmount += step;
    if (scrollAmount >= cardWidth) {
      window.clearInterval(slideTimer);
    }
  }, 50);
};

export const updateLocalStorage = (key, value) => {
  value = JSON.stringify(value);
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const getRouteFromUsertype = (userType) => {
  let userRoute;
  switch (userType) {
    case "STUDENTS": {
      userRoute = "/students";
      break;
    }
    case "TEACHERS": {
      userRoute = "/teachers";
      break;
    }
    case "COACHINGINSTITUTIONS": {
      userRoute = "/coachinginstitutions";
      break;
    }
    case "PARENTS": {
      userRoute = "/parents";
      break;
    }
    default: {
      userRoute = "";
      break;
    }
  }
  return userRoute;
};

export const isLinkVisible = (type, value) => {
  const loginAvailable = []; // add usertype as strings like "students", "teachers"
  const createContestAvailable = [
    "aankalan",
    "students",
    "parents",
    "teachers",
    "coachinginstitutions",
  ];

  switch (type) {
    case "LOGIN":
      return loginAvailable.includes(value);
    case "CONTEST":
      return createContestAvailable.includes(value);
    default:
      return false;
  }
};

export const validEmail = (email) => {
  const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
  return regex.test(email);
};

export const validPhone = (phone) => {
  let phoneRegx = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  return phone.match(phoneRegx);
};

export const getCardWidth = (id) => {
  let card = document.getElementById(id);
  let cardWidthWithoutMargin = card.offsetWidth;
  let cardStyle = getComputedStyle(card);
  let cardMargin = parseInt(cardStyle.marginRight);
  return cardWidthWithoutMargin + cardMargin;
};

export function handleResponse(response) {
  return response.json().then((res) => {
    if (res.code === "error") {
      const error = res.message;
      return Promise.reject(error);
    }
    return res.data;
  });
}

// T-> Time object, s-> separator
export const formatddmmyyyy = (t, s) => {
  let a = [{ day: "2-digit" }, { month: "short" }, { year: "numeric" }];
  function format(m) {
    let f = new Intl.DateTimeFormat("en", m);
    return f.format(t);
  }
  return a.map(format).join(s);
};

export const formatDate = (dateObj, s) => {
  var dd = String(dateObj.getDate()).padStart(2, "0");
  var mm = String(dateObj.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = dateObj.getFullYear();

  return dd + s + mm + s + yyyy;
};

// time -> 24 hr format time
export const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};
