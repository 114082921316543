import React from "react";
import PublishedQuestion from "../../../Components/Question/PublishedQuestion";
import Question from "../../../Components/Question/Question";

const QuestionsList = ({
  step,
  questions,
  changeOptionHandler,
  changeExplanationHandler,
  changeQuestionHandler,
  changeAnswerHandler,
  deleteQuestionHandler,
}) => {
  return (
    <div>
      {step === 1 && (
        <div>
          {questions.map((question, index) => (
            <Question
              key={index}
              question={question.title}
              options={[
                question.answer_1,
                question.answer_2,
                question.answer_3,
                question.answer_4,
              ]}
              correctAnswer={question.correct_answer}
              explanation={question.explanation}
              index={index}
              numberOfQuestions={questions.length}
              changeOptionHandler={changeOptionHandler}
              changeExplanationHandler={changeExplanationHandler}
              changeQuestionHandler={changeQuestionHandler}
              changeAnswerHandler={changeAnswerHandler}
              deleteQuestionHandler={deleteQuestionHandler}
            />
          ))}
        </div>
      )}
      {step === 2 && (
        <div>
          {questions.map((question, index) => (
            <PublishedQuestion
              key={index}
              index={index}
              question={question.title}
              options={[
                question.answer_1,
                question.answer_2,
                question.answer_3,
                question.answer_4,
              ]}
              correctAnswer={question.correct_answer}
              explanation={question.explanation}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default QuestionsList;
