import React from "react";
import { Avtaar } from "../Avtaar";

const theme = {
  "PINK": {
    bgColor: "bg-FAD6D6",
    borderColor: "border-FFB5B5"
  },
  "BLUE": {
    bgColor: "bg-B5DCEA",
    borderColor: "border-919DD6"
  },
  "PURPLE": {
    bgColor: "bg-EBCFEB",
    borderColor: "border-786AB5"
  }
}



const TestimonialCard = ({ name, image, description, title, index }) => {

  let selectedTheme
  if (index % 3 == 0) selectedTheme = theme["PINK"]
  else if (index % 3 == 1) selectedTheme = theme["BLUE"]
  else if (index % 3 == 2) selectedTheme = theme["PURPLE"]


  return (
    <div className={`${selectedTheme.bgColor} relative rounded-md border-2 ${selectedTheme.borderColor} p-5 md:px-8 md:py-10 flex flex-col space-y-2 md:space-y-6 font-source-sans-pro w-full h-full`}>
      {/* <div className=' flex flex-col md:flex-row space-y-3 md:space-y-0 space-x-0 md:space-x-4 md:items-center'> */}
      <div>
        {/* <div className='rounded-full overflow-hidden'>
          <Avtaar image={image} />
        </div> */}
        <div className=' font-semibold text-left card-heading text-451894 mt-6 lg:mt-2'>
          {name}
        </div>
        <div className='text-left text-xs md:text-sm text-181818'>
          {title}
        </div>
      </div>
      <div className='testimonial-card-description font-semibold text-left  text-xs md:text-sm text-1A1A1A'>
        {description}
      </div>
    </div>
  );
};

export default TestimonialCard;
