import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Components/Button";
import DownloadAppSection from "../Sections/DownloadAppSection";
import BalloonLeft from "../../Assets/Images/BalloonLeft.svg";
import BalloonRight from "../../Assets/Images/BalloonRight.svg";
import Image from "../../Components/Image";
// import AankalanData from "../../Data/Aankalan.json";

let AankalanData = require('../../Data/Aankalan.json')



const Congrats = ({ close }) => {
  const { adImage, qrImage } = AankalanData["DOWNLOAD_APP"];

  return (
    <div className='relative p-9 sm:p-12 lg:p-20 xl:px-32 flex flex-col items-center space-y-4'>
      <div className='flex flex-col items-center space-y-4 xl:space-y-10 font-source-sans-pro text-center'>
        <div className='font-bold text-base sm:text-3xl 3xl:text-5xl text-black'>
          Congratulations!!!
        </div>
        <div className='text-sm sm:text-lg xl:text-2xl 3xl:text-4xl text-black w-8/12'>
          You are successfully registered on Aankalan. Download the App to get
          started!
        </div>
      </div>
      <DownloadAppSection adImage={adImage} qrImage={qrImage} />
      <div className='flex flex-col sm:flex-row justify-center mt-20 w-full px-3'>
        <Link to='/students'>
          <Button width='w-full sm:w-60' textTransform='capitalize'>
            Take Me back to homepage
          </Button>
        </Link>
        <Button
          onClick={() => close()}
          className=''
          bgColor='white'
          textColor='black'
          width='w-full sm:w-60'
          textTransform='capitalize'>
          Close
        </Button>
      </div>
      <div className='absolute top-0 left-0'>
        <Image
          src={BalloonLeft}
          alt='balloon'
          height='h-24 sm:h-40 lg:h-auto'
          width='w-auto'
        />
      </div>
      <div className='absolute top-0 right-0'>
        <Image
          src={BalloonRight}
          alt='balloon'
          height='h-24 sm:h-40 lg:h-auto'
          width='w-auto'
        />
      </div>
    </div>
  );
};

export default Congrats;
