import React from 'react'
import HeroCard from '../../../../Components/Card/HeroCard'
import notes from '../../../../Assets/Icons/Teacher-Coaching-Flow/notes.svg'
import connections from '../../../../Assets/Icons/Teacher-Coaching-Flow/connections.svg'
import online from '../../../../Assets/Icons/Teacher-Coaching-Flow/online.svg'
import arrow from '../../../../Assets/Icons/colored-arrow-left.svg'




const statsArray = [
    {
        text: "Create Test As Per Your Preferred Subject",
        icon: notes
    }, {
        text: "Share it With Aankalan",
        icon: connections
    }, {
        text: "Administer All Your Tests In One Place",
        icon: online
    }

]

export const TeacherStep1 = () => {
    return (
        <div className="bg-white w-8/10-center-without-py pt-4 sm:pt-24 ">
            <div className="grid grid-cols-1 lg:grid-cols-3">
                {statsArray.map((stats, index) => (
                    <div key={index}>
                        <div className="py-7 lg:py-0 lg:px-7 relative h-full">
                            <div className='w-9/12 sm:w-1/2 mx-auto lg:w-auto h-52 md:h-72 lg:h-full'>
                                <HeroCard
                                    index={index}
                                    description={stats.text}
                                    image={stats.icon}
                                />
                            </div>
                            {index < statsArray.length - 1 ? <div>
                                <img src={arrow} className="absolute left-1/2 bottom-0 lg:left-auto lg:bottom-auto lg:right-0 lg:top-1/2 rotate-90 lg:rotate-0 transform" />
                            </div>
                                :
                                null
                            }
                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}
