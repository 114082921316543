import React from "react";
import loadingIcon from "../Assets/Icons/Rolling.svg";

const Button = ({
  onClick,
  height = "h-12 sm:h-14 3xl:h-16",
  width,
  bgColor = "FFA300",
  textColor = "black",
  textSize = "text-base",
  weight = "semibold",
  children,
  textTransform = "capitalize",
  className = "",
  disabled = false,
  loading = false,
  outline = false, 
  buttonColor = ""
}) => {
  return (
    <button
      className={`${textSize}
              ${outline ? `border border-${buttonColor} text-${buttonColor}` : ` bg-${bgColor} text-${textColor}`}
             font-source-sans-pro
             font-${weight}
             ${height}
             ${width}
             rounded-lg
             ${textTransform}
             ${className}
             ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
            `}
      onClick={onClick}
      disabled={disabled}>
      {loading ? (
        <div className='flex-centerXY'>
          <img src={loadingIcon} className='h-9 w-auto' alt='loader' />
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
