import React, { useState, useEffect } from "react";
import Logo from "../../Components/Logo/Logo";
import Interaction from "./ProfileDetailTemplate/Interaction";
import Congrats from "../../Assets/Images/Congrats.svg";
import CongratsGirl from "../../Assets/Images/CongratsGirl.svg";
import Image from "../../Components/Image";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Congratulations = () => {
  const history = useHistory();

  const [backgroundColor, setBackgroundColor] = useState("461995");
  const [logoColor, setLogoColor] = useState("FA200");
  const [messageBubbleColor, setMessageBubbleColor] = useState("461995");
  const [textColor, setTextColor] = useState("B798F7");

  useEffect(() => {
    let route = history.location.pathname;
    if (route === "/create-test-series/congratulations") {
      setBackgroundColor("228F95");
      setLogoColor("FFFFFF");
      setMessageBubbleColor("2E959B");
      setTextColor("FFFFFF");
    }
  }, []);

  return (
    <div className='h-screen grid grid-cols-10 font-source-sans-pro'>
      <div
        className={`z-10 pt-14 col-span-5 hidden lg:flex flex-col items-center justify-between bg-${backgroundColor}`}>
        <div className='space-y-2 flex flex-col items-center'>
          <Logo colo={logoColor} />
          <div className={`sub-heading text-${textColor} uppercase`}>
            CREATE YOUR PROFILE
          </div>
        </div>
        <div className='px-10 w-2/3 xl:w-1/2'>
          <Interaction
            message='Zip..Zap..Zoom!'
            description='We are happy to have you as part of our amazing team.'
            image={CongratsGirl}
            imageWidth='w-48 3xl:w-56 4xl:w-72'
            imageHeight='h-112'
            messageBubbleColor={messageBubbleColor}
          />
        </div>
      </div>
      <div className='col-span-10 lg:col-span-5 bg-FFFFFF flex flex-col items-center justify-center px-16'>
        <Image
          src={Congrats}
          alt='congrats'
          height='h-auto'
          widthy='w-auto'
          className='animate-bounce'
        />
        <div className='space-y-2 text-center'>
          <div className='text-484949 text-4xl sm:text-6xl'>
            Congratulations!
          </div>
          <div className='text-9A9A9A sub-heading'>
            You have successfully created your profile.
          </div>
          <div className={`text-${backgroundColor} sub-heading font-semibold`}>
            Aankalan will reach out to you shortly.
          </div>
        </div>
        <Link to='/home'>
          <button className='mt-20 block border bg-FFA200 rounded-md font-source-sans-pro text-base xl:text-lg text-center text-black h-10 lg:h-12 w-40 xl:w-44'>
            Home
          </button>
        </Link>
      </div>
      <div
        className={`hidden lg:block absolute h-full w-full top-0 bottom-0 left-0 clip-section-left bg-${backgroundColor}`}></div>
      <div className='hidden lg:block z-10 absolute h-full w-full top-0 bottom-0 right-0 clip-section-right bg-FFFFFF'></div>
      {/* <div className="absolute top-0 right-10">
        <Link to='/home'>
          <div className={`text-xs uppercase text-${backgroundColor} mt-20 font-semibold`}>
            GO TO AANKALAN.APP
          </div>
        </Link>
      </div> */}
    </div>
  );
};

export default Congratulations;
