import React from "react";
import HeroCard from "../../Components/Card/HeroCard";
import { useWindowDimensions } from "../../CustomHooks/ScreenH-W";

const ScoreAnalysis = ({
  heading,
  description,
  statsArray,
  statsImages,
  bgColor = "FFFFFF",
  textColor = "black",
  isDividerVisibile = true,
}) => {
  const { width, height } = useWindowDimensions();

  return (
    <div className={`bg-${bgColor}`}>
      <div className=' space-y-8 lg:space-y-20 py-10 sm:py-24'>
        <div
          className={`space-y-6 lg:space-y-10 flex flex-col items-center justify-center text-center text-${textColor}`}>
          <div
            dangerouslySetInnerHTML={{ __html: heading }}
            className='heading'
          />
          {isDividerVisibile && (
            <div className='hidden lg:block border-4 border-white bg-black w-1/4 rounded-md'></div>
          )}
          {description && (
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className='sub-heading'
            />
          )}
        </div>
        <div
          className={`${
            width >= 768 ? "w-8/10-center-without-py" : "w-9/10-left-without-py"
          } flex overflow-without-scrollbar md:grid md:grid-cols-2 lg:grid-cols-4
           gap-4 xl:gap-6`}>
          {statsArray.map((stats, index) => (
            <div key={index}>
              <div className='w-44 sm:w-52 md:w-auto h-full'>
                <HeroCard
                  index={index}
                  description={stats}
                  image={statsImages[index]}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScoreAnalysis;
