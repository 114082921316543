import React from "react";
import ProfileCard from "../../Components/Card/ProfileCard";
import Image from "../../Components/Image";
import ScrollableFrame from "./ScrollableFrame";

const FeatureFrame = ({
  id,
  heading,
  description,
  cardsList,
  btnTextArr,
  theme,
  bgImage,
  image,
  imgAlt,
  btnOnClickArr,
}) => {
  const lightTheme = {
    textColor: "000000",
    dividerColor: "black",
    bgColor: "FAFAFA",
    btnColor: "FFA300",
    btnText: "000000",
    cardBorder: "E1E1E1",
  };

  const purpleTheme = {
    textColor: "FFFFFF",
    dividerColor: "FFFFFF",
    bgColor: "gradient",
    btnColor: "FFA300",
    btnText: "000000",
    cardBorder: "transparent",
  };

  const selectedTheme = (function themeSelection() {
    switch (theme) {
      case "LIGHT":
        return lightTheme;
      case "PURPLE":
        return purpleTheme;
      default:
        return null;
    }
  })();

  return (
    <div
      className={`bg-${selectedTheme.bgColor} ${
        bgImage ? "relative" : ""
      } py-8 sm:py-24  `}>
      <div
        className={`grid grid-cols-10 ${
          !description ? "items-center" : ""
        } gap-y-10 gap-x-0 lg:gap-x-24 lg:gap-y-0  text-${
          selectedTheme.textColor
        } w-9/10-left-without-py`}>
        <div className='col-span-10 lg:col-span-3 2xl:col-span-4 flex flex-col items-center space-y-8 lg:space-y-10 3xl:space-y-14 w-11/12 mr-auto lg:w-full'>
          <div
            dangerouslySetInnerHTML={{ __html: heading }}
            className='font-roboto-slab font-bold text-center text-base sm:text-2xl lg:text-3xl xl:text-4xl 3xl:text-5xl'
          />

          <div
            className={`border-t-4 border-${selectedTheme.dividerColor} bg-${selectedTheme.dividerColor} rounded-md w-4/12`}></div>
          {description && (
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className='font-soure-sans-pro text-sm sm:text-lg lg:text-xl xl:text-2xl text-center'
            />
          )}
        </div>
        <div className='col-span-10 lg:col-span-7 2xl:col-span-6'>
          <ScrollableFrame
            theme={theme}
            containerId={id}
            // isButtonVisible={true}
            btnOnClickArr={btnOnClickArr}
            btnTextArr={btnTextArr}>
            <div id={id} className='flex space-x-8 overflow-without-scrollbar'>
              {cardsList.map((item, index) => (
                <div id={`${id}-card-${index}`} key={index}>
                  <div className='w-64 md:w-80 3xl:w-96 h-full'>
                    <ProfileCard
                      name={item.name}
                      description={item.title}
                      text={item.description}
                      verified={item.verified}
                      image={item.image}
                      isOrganization={item.organization ? true : false}
                      organization={item.organization}
                      index={index}
                    />
                  </div>
                </div>
              ))}
            </div>
          </ScrollableFrame>
        </div>
        {bgImage && (
          <div className={`absolute -bottom-4 right-16`}>
            <Image
              src={image}
              alt={imgAlt}
              height='h-20 lg:h-28'
              width='w-32 lg:w-40'
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FeatureFrame;
