import React, { useState } from "react";

const Image = ({
  src,
  alt,
  position = "",
  width,
  height,
  bgColor = "transparent",
  className = "",
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={`${className} ${width} ${height}`}>
      <img
        src={src}
        alt={alt}
        className={`transition-opacity duration-500 ${
          imageLoaded ? "opacity-100" : "opacity-0"
        } h-full w-full ${position}`}
        onLoad={() => setImageLoaded(true)}
      />
      {!imageLoaded && (
        <div className={`bg-${bgColor} ${height} ${width}`}></div>
      )}
    </div>
  );
};

export default Image;
