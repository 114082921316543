import React, { Component } from "react";
import Dropzone from "react-dropzone";
import './uploadfile.css'
import uploadIcon from '../../Assets/Icons/upload-image-icon.svg'
export default class UploadFiles extends Component {
  constructor(props) {
    super(props);
  }
  onDrop = (file) => {
    if (file.length > 0) {
      this.props.onChange(file);
    }
  }
  upload() { }

  deleteImage=(e)=> {
    e.stopPropagation()
    this.props.onChange(null);

  }




  render() {
    const { selectedFile } = this.props;
    const { title } = this.props

    return (
      <div>
        {title && (
          <div className=' font-semibold text-base md:text-xl 3xl:text-2xl mb-3'>
            {title}
          </div>
        )}

        <Dropzone onDrop={this.onDrop} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ className: `${selectedFile ? "bg-white" : "bg-transparent"} p-3 sm:p-4 shadow-md rounded flex justify-between items-center` })}>
                <input {...getInputProps()} />
                {selectedFile && selectedFile[0].name ? (
                  <div className="selected-file truncate ...">
                    {selectedFile && selectedFile[0].name}
                    <div className="text-red-500 text-xs relative z-20 py-2  " onClick={this.deleteImage}>Delete Image</div>
                  </div>
                ) : (
                  <div className="text-B1B1B1 text-sm sm:text-base">Upload Image</div>
                )}
                {!selectedFile  && <div className="h-8 w-8 sm:w-14 sm:h-14">
                  <img src={uploadIcon} className="w-full h-full" />
                </div>}
              </div>
            </section>
          )}
        </Dropzone>
        {/* <div className="flex justify-between font-source-sans-pro text-sm text-7A7A7A">
          <div>Files suported</div>
          <div>maximum size</div>
        </div> */}
      </div>
    );
  }
}
