import React from "react";

const SelectOption = ({ value, selectedValue, onClick }) => {
  return (
    <div
      className={` ${
        value === selectedValue ? "bg-FFF1CA" : "bg-FFF5E3"
      } hover:bg-FFF1CA  font-soure-sans-pro font-normal text-left text-base text-000000 capitalize h-12 px-4 py-2.5 cursor-pointer`}
      style={{minWidth:"200px"}}
      onClick={(e) => {
        onClick(value);
        e.stopPropagation();
      }}>
      {value}
    </div>
  );
};

export default SelectOption;
