import React from "react";
import FeatureCard from "../../Components/Card/FeatureCard";

const CoachingInstituteFeatureSection = ({
  heading,
  description,
  statsArray,
  uspImageArray,
}) => {
  return (
    <div className='w-8/10-center space-y-8'>
      <div dangerouslySetInnerHTML={{ __html:heading }} className='heading text-center'/>
      <div className='border-2 lg:border-4 border-black bg-black rounded-md  w-5/12 lg:w-1/12 mx-auto'></div>
      <div dangerouslySetInnerHTML={{ __html:description }} className='heading3 text-center lg:w-5/12 mx-auto'/>

      <div className='grid grid-cols-1 gap-4 lg:gap-8 md:grid-cols-2 lg:w-8/12 4xl:w-7/12 mx-auto'>
        {statsArray.map((stat, index) => (
          <FeatureCard
            icon={uspImageArray[index]}
            heading={stat.heading}
            description={stat.description}
          />
        ))}
      </div>
    </div>
  );
};

export default CoachingInstituteFeatureSection;
