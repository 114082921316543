import React from "react";
import Slider from "react-slick";
import mobileScreen from "../../Assets/Images/mobileScreen.png";

const FeatureSection = ({
  heading , description , image
}) => {
  let settings = {
    dots: true,
  };
  return (
    <Slider {...settings}>
      <div className="bg-F5F5F5 feature-section">
        <div className="w-8/10-center flex flex-col justify-center items-center  relative ">
          <div className="relative z-10">
            <div className="text-center">
              <div dangerouslySetInnerHTML={{ __html:heading }} className="heading"/>
            </div>
            <div className="mt-8 lg:mt-14">
              <div className="phone mx-auto">
                <div className="screen">
                  <img src={image} alt="App Screen" />
                </div>
              </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html:description }} className="mt-8 lg:mt-16 font-source-sans-pro text-sm text-white lg:text-2xl text-center w-72 sm:w-96 mx-auto"/>
          </div>
        </div>
      </div>
    </Slider>
  );
};
export default FeatureSection;
