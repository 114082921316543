import React from "react";
import Image from "./Image";

const defaultUserImage =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

export const Avtaar = ({ image }) => {
  if (!image) {
    image = defaultUserImage;
  }
  return (
    <div className='h-14 sm:h-16 w-14 sm:w-16 rounded-full overflow-hidden border-4 border-white shadow-md'>
      <Image
        src={image}
        alt='Avtaar'
        width='w-full'
        height='h-full'
        position='object-cover object-top'
      />
    </div>
  );
};
