import { useEffect, useState, useContext } from "react";
import Input from "../../../../Components/Input";
import RadioGroup from "../../../../Components/Radio/Radio.Group";
import RadioTabs from "../../../../Components/Radio/Radio.Tab";

import Teacher from "../../../../Assets/Icons/Create-Contest/teacher.svg";
import Youtuber from "../../../../Assets/Icons/Create-Contest/youtubers.png";
import TeacherYoutuberBoth from "../../../../Assets/Icons/teacher-youtube-both.png";
import College from "../../../../Assets/Images/college.png";

import UploadFiles from "../../../../Components/UploadFiles";
import RadioTag from "../../../../Components/Radio/Radio.Tag";

import YoutuberLogo from "../../../../Assets/Icons/youtube-logo.png";
import InstaLogo from "../../../../Assets/Icons/insta-logo.png";
import FbLogo from "../../../../Assets/Icons/fb-logo.png";
import TwitterLogo from "../../../../Assets/Icons/twitter-logo.png";

import SocialInput from "../../../../Components/SocialInput";
import { RangeInput } from "../../../../Components/RangeInput";
import SelectGroup from "../../../../Components/CustomSelect/SelectGroup";
import SelectOption from "../../../../Components/CustomSelect/SelectOption";
import { TagsInput } from "../../../../Components/TagsInput";
import { ProfileContext } from "../../../../Context/ProfileContext";
import { ProfileService } from "../../../../Services/profile.service";
import toast from "react-hot-toast";
import Modal from "../../../../Components/Modal";
import Button from "../../../../Components/Button";
import TextArea from "../../../../Components/TextArea";
import { getAnalytics, logEvent } from "firebase/analytics";


const BasicDetails = () => {

  const {
    name,
    setName,
    email,
    setEmail,
    gender,
    setGender,
    profession,
    setProfession,
    customProfession,
    setCustomProfession,


    profileImage,
    setProfileImage,
    logoImage,
    setLogoImage,
    promoteUser,
    setPromoteUser,
    activeHandle,
    setActiveHandle,
    youtubeLink,
    setYoutubeLink,
    instaLink,
    setInstaLink,
    fbLink,
    setFbLink,
    twitterLink,
    setTwitterLink,
    promoteUserSocialHandle,
    setPromoteUserSocialHandle,

    teachingExp,
    setTeachingExp,
    qualification,
    setQualification,
    coreSubjects,
    setCoreSubjects,
    subjects,
    setSubjects,
    aboutUser,
    setAboutUser,


    step,
  } = useContext(ProfileContext);
  const [expertiseList, setExpertiseList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [institutionModal, setInstitutionModal] = useState(false)

  const onSelectProfession = (selectedProfession) => {
    if (selectedProfession === "institution") {
      setInstitutionModal(true);
      setCustomProfession(true)
      setProfession("")


    } else {
      setCustomProfession(false)
      setProfession(selectedProfession)
    }
  }

  const onModalClose = () => {
    setProfession("teacher")
    setInstitutionModal(false)
    setCustomProfession(false)

  }

  useEffect(() => {
    // Fetch subjects
    const analytics = getAnalytics();
    logEvent(analytics, 'CREATE_PROFILE_EDUCATION', {
      landed: "true"
    });

    
    ProfileService.getSubjects()
      .then((res) => {
        let subjectsArr = res.map((item) => item.name);
        setSubjectList([...subjectsArr]);
      })
      .catch((err) => {
        console.log("Subjects", err);
        toast.error("Something went wrong");
      });

    // Fetch Core-expertise
    ProfileService.getCoreExpertise()
      .then((res) => {
        let coreExpertiseArr = res.map((item) => item.core_expertise);
        setExpertiseList([...coreExpertiseArr]);
      })
      .catch((err) => {
        console.log("Expertise", err);
        toast.error("Something went wrong");
      });
  }, []);

  const ProfileDetails = () => (
    <div>
      <div className='mt-4'>
        <RadioGroup
          selectedValue={profession}
          onClick={onSelectProfession}
          title='Choose the option applicable to you.'>
          <RadioTabs large icon={Teacher} value='teacher'>
            Teacher
          </RadioTabs>
          <RadioTabs large icon={Youtuber} value='youtuber'>
            Youtuber
          </RadioTabs>
          <RadioTabs large icon={College} value={`${customProfession ? profession : "institution"}`}>
            {customProfession ? profession : "institution"}
          </RadioTabs>
        </RadioGroup>
      </div>
      <div
        className={`font-semibold text-base md:text-xl 3xl:text-2xl mb-3 mt-3`}>
        Please upload your image and logo
        <span className='text-B1B1B1'>(Optional)</span>
      </div>
      <div className='grid grid-cols-2 gap-4'>
        <UploadFiles selectedFile={profileImage} onChange={setProfileImage} />
        <UploadFiles selectedFile={logoImage} onChange={setLogoImage} />
      </div>
      {(profileImage || logoImage) && (
        <div className='mt-4'>
          <RadioTag selected={promoteUser} onClick={setPromoteUser}>
            Allow Aankalan to use my photo or logo for my promotion across India
          </RadioTag>
        </div>
      )}
    </div>
  );
  const ProfessionalExp = () => (
    <div className='mt-4'>
      <SelectGroup
        id='qualification'
        placeholder='Select Your Highest Qualification'
        title='Your Highest Qualification'
        selectedOption={qualification}
        transparent
        allowType
        selectOptionHandler={setQualification}>
        <SelectOption value='Diploma'>"Diploma"</SelectOption>
        <SelectOption value='graduate'>"graduate"</SelectOption>
        <SelectOption value='post-graduate'>"Post graduate"</SelectOption>
        <SelectOption value='phd'>"Ph.D"</SelectOption>
      </SelectGroup>
      <div className='grid grid-cols-2 gap-4 mt-8 sm:mt-14'>
        <div className='col-span-2 sm:col-span-1'>
          <TagsInput
            title='Core Expertise'
            placeholder='Search Your Core Expertise Or Type'
            tagsArray={coreSubjects}
            setTagsArray={setCoreSubjects}
            transparent
            optionsList={expertiseList}
            allowType={true}
            dropDownPosition='-bottom-10 sm:bottom-0 left-4 sm:left-24 w-full'
          />
        </div>
        <div className='col-span-2 sm:col-span-1'>
          <TagsInput
            title='Subjects You Teach / Talk About'
            placeholder='Search Your subjects  Or Type'
            tagsArray={subjects}
            setTagsArray={setSubjects}
            transparent
            optionsList={subjectList}
            allowType={true}
            dropDownPosition='-bottom-10 sm:bottom-0 left-4 sm:left-24 w-full'
          />
        </div>
      </div>
    </div>
  );
  return (
    <div>
      {step === 1 && (
        <div>
          <Input
            large
            title='My Name is'
            placeholder='enter fullname here'
            value={name}
            transparent
            onChange={setName}
          />
          <div className='mt-6'></div>
          <Input
            large
            title='My Email is'
            placeholder='enter your email'
            value={email}
            transparent
            onChange={setEmail}
          />
          {/*<div className='mt-4'></div>
         <div className=''>
        <RadioGroup
          selectedValue={gender}
          title='Gender(Optional)'
          large
          onClick={(value) => setGender(value)}>
          <RadioButton value='Male'>Male</RadioButton>
          <RadioButton value='Female'>Female</RadioButton>
        </RadioGroup>
      </div> */}
        </div>
      )}
      {step === 2 && <ProfileDetails />}
      {step === 3 && (
        <div>
          <div
            className={`font-semibold text-base md:text-xl 3xl:text-2xl mb-3`}>
            Update social media handles
          </div>
          <div className='flex space-x-2'>
            <SocialInput
              icon={YoutuberLogo}
              selected={activeHandle === "youtubeLink"}
              onClick={() => setActiveHandle("youtubeLink")}
              placeholder='Your YouTube handle'
              value={youtubeLink}
              onChange={setYoutubeLink}
            />
            <SocialInput
              icon={InstaLogo}
              placeholder='Your Instagram handle'
              value={instaLink}
              selected={activeHandle === "instaLink"}
              onClick={() => setActiveHandle("instaLink")}
              onChange={setInstaLink}
            />
            <SocialInput
              icon={FbLogo}
              placeholder='Your Facebook handle'
              selected={activeHandle === "fbLink"}
              onClick={() => setActiveHandle("fbLink")}
              value={fbLink}
              onChange={setFbLink}
            />
            <SocialInput
              icon={TwitterLogo}
              placeholder='Your Twitter handle'
              value={twitterLink}
              selected={activeHandle === "twitterLink"}
              onClick={() => setActiveHandle("twitterLink")}
              onChange={setTwitterLink}
            />
          </div>
          {(youtubeLink || instaLink || fbLink || twitterLink) && (
            <div className='mt-4'>
              <RadioTag
                selected={promoteUserSocialHandle}
                onClick={setPromoteUserSocialHandle}>
                Allow aankalan to promote my social media handles across country
              </RadioTag>
            </div>
          )}
          <div className='mt-6'>
            <RangeInput
              title='Number Of Years Of Teaching Experience'
              min={0}
              max={50}
              step={1}
              value={teachingExp}
              onChange={setTeachingExp}
            />
          </div>
        </div>
      )}
      {step === 4 && <ProfessionalExp />}
      {step === 5 && <TextArea
        transparent
        autoFocus
        value={aboutUser}
        onChange={setAboutUser}
        maxLength="400"
        title="Tell the world your brief summary & key achievements"
        placeholder={profession === "youtuber" ?
          `Creating Videos on Interesting Topics like Technology is my Passion, I have more that 1.3 million subscribers. I graduated from Delhi University and loves to travel and meet new people. 
I got Silver Play Button from YouTube recently and presently working on my new book on Technology.`
          :
          `I have done B.Tech from REC, South INDIA, then Completed M. Tech from IIT. Teaching is my Passion, and I am into it since last 15 years, very proudly every year 50 to 100 Students cleared various competitive exams who taught from us. 
I have also written 2 books on Chemistry, named ABC of Chemistry & XYZ of Chemistry, both are available on Amazon`}

      />}
      <Modal
        visible={institutionModal}
        close={onModalClose}
        width='w-11/12 md:w-10/12'>
        <div className="p-9 sm:p-12 lg:p-20 xl:px-32">
          <Input placeholder="institution name" title="Enter Institution Name" value={profession} onChange={setProfession} />
          <div className="mt-4">
            <Button width="w-32" onClick={() => setInstitutionModal(false)}>
              Submit
            </Button>
          </div>
        </div>

      </Modal>
    </div>
  );
};

export default BasicDetails;

{
  /* <div>
      <PhoneInput title='You can call me on' transparent large sendOTP />
      <PhoneOtp />
      <button onClick={save}>save</button>
</div> */
}
