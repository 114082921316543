import React, { useContext } from "react";
import Input from "../../../Components/Input";

import SelectGroup from "../../../Components/CustomSelect/SelectGroup";
import SelectOption from "../../../Components/CustomSelect/SelectOption";
import Button from "../../../Components/Button";
import toast from "react-hot-toast";
import DateInput from "../../../Components/DateInput";
import { ProfileContext } from "../../../Context/ProfileContext";
import TimeInput from "../../../Components/TimeInput";

const contestLevelArray = ["Easy", "Medium", "Hard"];
const entryFeeArray = [5, 10, 20, 50, 100, 1000, 2500];

const ContestDetails = ({
  updateStage,
  contestName,
  setContestName,
  exam,
  setExam,
  examsList,
  subject,
  setSubject,
  subjectsList,
  level,
  setLevel,
  date,
  setDate,
  time,
  setTime,
  fee,
  setFee,
  noOfParticipants,
  setNoOfParticipants,
  commission,
  setCommission,
  cashPoints,
  setCashPoints,
}) => {
  const { name } = useContext(ProfileContext);

  const validateForm = () => {
    if (
      !contestName ||
      !exam ||
      !subject ||
      !level ||
      !date ||
      !time ||
      !noOfParticipants ||
      !fee ||
      !commission
    ) {
      return toast.error(`Fill all the details`);
    }
    if (isNaN(noOfParticipants)) {
      return toast.error(`Number of participants must be number`);
    }
    if (noOfParticipants < 50) {
      return toast.error(`Number of participants must be greater than 50`);
    }
    if (isNaN(fee) || isNaN(commission) || isNaN(cashPoints)) {
      return toast.error(`Fee/Commission/Cashpoints must be number`);
    }
    if (fee > 100) {
      return toast.error("Fee cannot be greater than 100");
    }
    if (commission > 80) {
      setCommission("");
      return toast.error("Commision can not be greater than 80");
    }
    console.log(time);
    updateStage(1);
    window.scrollTo(0, 0);
  };

  const CustomLabel = ({ label, hintText }) => {
    return (
      <div className='font-open-sans-pro'>
        <div className='text-base capitalize'>{label}</div>
        {hintText && (
          <div className='text-xs opacity-50 capitalize'>{hintText}</div>
        )}
      </div>
    );
  };

  const updateRevenueAndCashpoints = (value) => {
    setCommission(value);
    !isNaN(value) && value <= 80
      ? setCashPoints(80 - value)
      : setCashPoints("");
  };
  return (
    <div className='font-open-sans-pro'>
      <div className='font-semibold text-lg capitalize'>Hi {name},</div>
      <div className='font-semibold text-xl capitalize'>
        Enter Contest Details
      </div>
      <div className='grid grid-cols-4 gap-4 mt-8'>
        <div className='col-span-4 md:col-span-1 flex items-center'>
          <CustomLabel label='Contest Name' />
        </div>
        <div className='col-span-4 md:col-span-3'>
          <Input
            onChange={setContestName}
            value={contestName}
            placeholder='Type Here'
          />
        </div>
        <div className='col-span-4 md:col-span-1 flex items-center'>
          <CustomLabel label='Select Examination' hintText='select Any One' />
        </div>
        <div className='col-span-4 md:col-span-3'>
          <SelectGroup
            id='examination'
            placeholder='Select from here '
            selectedOption={exam}
            selectOptionHandler={setExam}>
            {examsList.map((exam) => (
              <SelectOption value={exam.exam_name}>
                {exam.exam_name}
              </SelectOption>
            ))}
          </SelectGroup>
        </div>
        <div className='col-span-4 md:col-span-1 flex items-center'>
          <CustomLabel label='Select Subject' hintText='select Any One' />
        </div>
        <div className='col-span-4 md:col-span-3'>
          <SelectGroup
            id='subject'
            placeholder='Select from here '
            selectedOption={subject}
            selectOptionHandler={setSubject}>
            {subjectsList.map((subject) => (
              <SelectOption value={subject.name}>{subject.name}</SelectOption>
            ))}
          </SelectGroup>
        </div>
        <div className='col-span-4 md:col-span-1 flex items-center'>
          <CustomLabel label='Contest Levels' hintText='select Any One' />
        </div>
        <div className='col-span-4 md:col-span-3'>
          <SelectGroup
            id='contestLevel'
            placeholder='Select from here '
            selectedOption={level}
            selectOptionHandler={setLevel}>
            {contestLevelArray.map((level) => (
              <SelectOption value={level}>{level}</SelectOption>
            ))}
          </SelectGroup>
        </div>
        <div className='col-span-4 md:col-span-1 flex items-center'>
          <CustomLabel label='Contest Date' hintText='select Any One' />
        </div>
        <div className='col-span-4 md:col-span-3'>
          <DateInput date={date} setDate={setDate} placeholder='Select Date' />
        </div>
        <div className='col-span-4 md:col-span-1 flex items-center'>
          <CustomLabel label='Contest Time' hintText='select start time' />
        </div>
        <div className='col-span-4 md:col-span-3'>
          <TimeInput setTime={setTime} time={time} />
        </div>
        {/* <div className='col-span-4 md:col-span-1 flex items-center'>
          <CustomLabel label='Select Time' />
        </div>
        <div className='col-span-4 md:col-span-3'>
          <SelectGroup
            id='Duration'
            placeholder='Select Time'
            selectedOption={duration}
            selectOptionHandler={(value) => {
              setDuration(value);
            }}>
            {durationArray.map((state, index) => (
              <SelectOption value={state}>{state}</SelectOption>
            ))}
          </SelectGroup>
        </div> */}
        <div className='col-span-4 md:col-span-1 flex items-center'>
          <CustomLabel label='Max participants allowed' />
        </div>
        <div className='col-span-4 md:col-span-3'>
          {/* <SelectGroup
            id='Participants'
            placeholder='Select Max Number Of Participants'
            selectedOption={noOfParticipants}
            selectOptionHandler={(value) => {
              setNoOfParticipants(value);
            }}
            info='Minimum 50 Partcipants Required'>
            {participantsArray.map((state, index) => (
              <SelectOption value={state}>{state}</SelectOption>
            ))}
          </SelectGroup> */}
          <Input
            onChange={setNoOfParticipants}
            value={noOfParticipants}
            placeholder='Enter Max Number Of Participants'
            info='Minimum 50 Partcipants Required'
          />
        </div>
      </div>
      <div className='font-semibold text-xl capitalize mt-14'>
        Examination Fee Related Details
      </div>
      <div className='grid grid-cols-4 gap-4 mt-8'>
        <div className='col-span-4 md:col-span-1 flex items-center'>
          <CustomLabel label='Entry fee*' hintText='Min Rs. 5. Max Rs. 2500' />
        </div>
        <div className='col-span-4 md:col-span-3'>
          <SelectGroup
            id='entryFee'
            placeholder='Select from here '
            selectedOption={fee}
            selectOptionHandler={setFee}>
            {entryFeeArray.map((fee) => (
              <SelectOption value={fee}>{fee}</SelectOption>
            ))}
          </SelectGroup>
        </div>
        <div className='col-span-4 md:col-span-2 p-4 border border-DEDEDE bg-FDFCFF mt-8 rounded-md'>
          <CustomLabel
            label='Your revenue percentage'
            hintText='Please enter the percentage you wish to retain as your fee. Remainder gets awarded to winners.'
          />
          <div className='mt-6 w-1/2 flex items-center space-x-4'>
            <Input
              onChange={updateRevenueAndCashpoints}
              value={commission}
              placeholder='%'
              maxLength={2}
            />
            {commission > 0 && <div className='text-707070 text-base'>%</div>}
          </div>
        </div>
        <div className='col-span-4 md:col-span-2 p-4 border border-DEDEDE bg-FDFCFF mt-8 rounded-md'>
          <CustomLabel
            label='Distribute to participants in form of cash & points'
            hintText='Points can be redeemed as cash'
          />
          <div className='mt-6 w-1/2 flex items-center space-x-4'>
            <Input
              // onChange={setCashPoints}
              value={cashPoints}
              placeholder='%'
              disabled={true}
              maxLength={2}
            />
            {cashPoints > 0 && <div className='text-707070 text-base'>%</div>}
          </div>
        </div>
      </div>
      <div className='mt-2 text-xs text-707070'>
        * 20% of revenue will be reserved by Aankalan
      </div>
      <div className='mt-14'>
        <Button width='w-full lg:w-72' onClick={validateForm}>
          NEXT
        </Button>
      </div>
    </div>
  );
};

export default ContestDetails;

// <div className='col-span-4 sm:col-span-2'>
//                     <Input info="Quiz Date Must Be 24 Hrs After Submission" onChange={setDate} value={date} placeholder='DD/MM/YYYY' title='Date Of Birth' />
//                 </div>
//                 <div className='col-span-4 sm:col-span-2'>
//                     <SelectGroup
//                         id='Time'
//                         placeholder='Select Time For Your Contest'
//                         title='Time'
//                         selectedOption={time}
//                         selectOptionHandler={(value) => {
//                             setTime(value);
//                         }}>
//                         {timeArray.map((state, index) => (
//                             <SelectOption value={state}>{state}</SelectOption>
//                         ))}
//                     </SelectGroup>
//                 </div>
//                 <div className='col-span-4 sm:col-span-2'>
//                     <SelectGroup
//                         id='Duration'
//                         placeholder='Select Time'
//                         title='Duration Of The Quiz'
//                         selectedOption={duration}
//                         selectOptionHandler={(value) => {
//                             setDuration(value);
//                         }}>
//                         {durationArray.map((state, index) => (
//                             <SelectOption value={state}>{state}</SelectOption>
//                         ))}
//                     </SelectGroup>
//                 </div>
//                 <div className='col-span-4 sm:col-span-2'>
//                     <SelectGroup
//                         id='Fee'
//                         placeholder='Min Rs 10 - Mx Rs 1000'
//                         title='Entry Fee*'
//                         selectedOption={fee}
//                         selectOptionHandler={(value) => {
//                             setFee(value);
//                         }}
//                         info="Amount Decided By You Min Rs 10 - Mx Rs 1000">
//                         {feeArray.map((state, index) => (
//                             <SelectOption value={state}>{state}</SelectOption>
//                         ))}
//                     </SelectGroup>
//                 </div>
//                 <div className='col-span-4 sm:col-span-2'>
//                     <SelectGroup
//                         id='Participants'
//                         placeholder='Select Max Number Of Participants'
//                         title='Total Participants Allowed*'
//                         selectedOption={noOfParticipants}
//                         selectOptionHandler={(value) => {
//                             setNoOfParticipants(value);
//                         }}
//                         info="Minimum 50 Partcipants Required">
//                         {participantsArray.map((state, index) => (
//                             <SelectOption value={state}>{state}</SelectOption>
//                         ))}
//                     </SelectGroup>
//                 </div>
//                 <div className='col-span-2 sm:col-span-4 md:col-span-1'>
//                     <SelectGroup
//                         id='Commissiom'
//                         placeholder='Select %'
//                         title='My Commission*'
//                         selectedOption={commission}
//                         selectOptionHandler={(value) => {
//                             setCommission(value);
//                         }}
//                         info="How Much Amount You Want To Keep, accordingly Remaining Amount Will Be Distributed To Participants">
//                         {commissionArray.map((state, index) => (
//                             <SelectOption value={state}>{state}</SelectOption>
//                         ))}
//                     </SelectGroup>
//                 </div>
//                 <div className='col-span-2 sm:col-span-4 md:col-span-1 place-self-end w-full'>
//                     <Input info="Remaining Amount Will Be Distributed To Participants In Form Of Cash Or Points.( Points Can Be Reedemed As Cash)." value={commission} onChange={setCommission} placeholder="___%" />
//                 </div>
