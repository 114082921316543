import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router";

import Button from "./Button";
import Logo from "./Logo/Logo";

// import AankalanData from "../Data/Aankalan.json";
import { handleSelect, isLinkVisible } from "../Utility/Helper";
import ContactUs from "../Containers/Modals/ContactUs";
import Modal from "./Modal";

let AankalanData = require('../Data/Aankalan.json')

const SideNav = ({ visible, close }) => {
  const userTypes = AankalanData["userTypes"];
  const history = useHistory();
  const [showContactModal, setShowContactModal] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  const [selectedUserRoute, setSelectedUserRoute] = useState(
    history.location.pathname.slice(1)
  );

  useEffect(() => {
    if (userTypes[selectedUserRoute]) {
      setCurrentUser(userTypes[selectedUserRoute]);
    } else if (selectedUserRoute === "register-student") {
      setCurrentUser(userTypes["students"]);
    }
  }, [history.location.pathname]);

  const openModal = () => {
    close();
    setShowContactModal(true);
  };

  const onOptionSelect = (value) => {
    setSelectedUserRoute(value);
    close();
  };

  return (
    <div>
      <div
        onClick={close}
        className={`fixed ${
          visible ? "w-full" : "w-0"
        } h-full z-50 left-0 top-0 bg-black opacity-70`}></div>

      <div
        class={`sidebar bg-230D4B text-white w-64 space-y-6 py-7 px-2 fixed inset-y-0 left-0 transform ${
          visible ? "" : "-translate-x-full"
        } lg:hidden transition duration-200 ease-in-out z-50`}>
        <div className='p-4'>
          <Logo />
          <div className='space-y-4 mt-10 flex flex-col'>
            <NavLink
              onClick={() => onOptionSelect("home")}
              activeClassName='font-semibold text-FFA300 capitalize'
              to='/home'>
              Home
            </NavLink>
            <NavLink
              activeClassName='font-semibold text-FFA300 capitalize'
              onClick={() => onOptionSelect("students")}
              to='/students'>
              Students
            </NavLink>
            <NavLink
              activeClassName='font-semibold text-FFA300 capitalize'
              onClick={() => onOptionSelect("parents")}
              to='/parents'>
              Parents
            </NavLink>
            <NavLink
              activeClassName='font-semibold text-FFA300 capitalize'
              onClick={() => onOptionSelect("teachers")}
              to='/teachers'>
              Teachers
            </NavLink>
            {/* <NavLink
              activeClassName='font-semibold text-FFA300 capitalize'
              onClick={() => onOptionSelect("coachinginstitutions")}
              to='/coachinginstitutions'>
              Coaching Institutes
            </NavLink> */}
            <div
              className='text-white capitalize cursor-pointer'
              onClick={() => {
                openModal();
                window.scrollTo(0, 0);
              }}>
              Contact Us
            </div>
            {isLinkVisible("LOGIN", selectedUserRoute) && (
              <Button
                bgColor='white'
                width='w-full'
                height='h-9'
                textColor='black'
                textSize='text-base'
                weight='normal'
                onClick={() => history.push("/login")}>
                Login
              </Button>
            )}
            <Link to='create-contest'>
              <button className='border border-FFA200 rounded-md font-source-sans-pro text-base xl:text-lg text-center text-FFA200 h-9 w-full'>
                Create Your Contest
              </button>
            </Link>
            <Link to='create-test-series'>
              <button className='border border-FFA200 rounded-md font-source-sans-pro text-base xl:text-lg text-center text-FFA200 h-9 w-full'>
                Submit Test Series
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Modal
        visible={showContactModal}
        close={() => setShowContactModal(false)}
        width='w-11/12 md:w-10/12'>
        <ContactUs close={() => setShowContactModal(false)} />
      </Modal>
    </div>
  );
};

export default SideNav;
