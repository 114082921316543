import React from 'react'
import { Avtaar } from '../Avtaar'


const TeacherProfileCard = ({
    showAbout=true
}) => {
    return (
        <div className="p-9 font-source-sans-pro bg-FAFAFA rounded-md border border-D6D5D5">
            <div className="flex lg:justify-between items-end">
                <Avtaar image="https://firebasestorage.googleapis.com/v0/b/aankalan-learning.appspot.com/o/ProfileBoy.png?alt=media&token=9a85a2dc-8b6a-4972-ae97-ebc8a4aa896f" />
                <div className="ml-8 mr-0 sm:ml-6 lg:ml-0 lg:mr-10">
                    <div className="card-heading font-bold">Mr. Ajay Singh</div>
                    <div className="flex justify-between space-x-2">
                        <div className="text-sm text-333333">Msc. Physics</div>
                        <div className="px-2 flex items-center text-2xs 3xl:text-sm text-white bg-461995 opacity-60 rounded">
                            4.8
                            <span className="fa fa-star checked ml-1"></span>
                            
                            </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-between mt-6">
                <div>
                    <div className="text-sm text-black opacity-50">Experience</div>
                    <div className="font-semibold text-333333 card-content">20 years</div>
                </div>
                <div>
                    <div className="text-sm text-black opacity-50">Core Expertise</div>
                    <div className="font-semibold text-461995 card-content">Physics</div>
                </div>
                <div>
                    <div className="text-sm text-black opacity-50 ">City</div>
                    <div className="font-semibold text-333333 card-content">Delhi</div>
                </div>
            </div>
            {showAbout && <div className="mt-6">
                <div className="text-sm text-333333 opacity-50">About</div>
                <div className="font-semibold card-content">Ut enim ad minim veniam, quis nostru.Ut enim ad minim veniam, quis nostru.</div>
            </div>}

        </div>
    )
}

export default TeacherProfileCard
