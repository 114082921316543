import Button from "./Button"

export default ({ close }) => {
    return (
        <div className="container">
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Aankalan Enterprises Pvt Ltd respects the privacy of our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application "AANKALAN". Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION.
                    </p>
                    <br />
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the "Last updated" date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.
                    </p>
                    <br />
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, including any in-game virtual items, which may also collect and use data about you. We are not responsible for any of the data collected by any such third party.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h3 className="font-roboto-slab font-bold text-xl xl:text-2xl mb-2">
                        COLLECTION OF YOUR INFORMATION
                    </h3>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We may collect information about you in a variety of ways. The information we may collect via the Application depends on the content and materials you use, and includes:

                    </p>
                </div>
            </div>
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Personal Data
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Demographic and other personally identifiable information (such as your name, contact number, address and email address) that you voluntarily give to us when choosing to participate in various activities related to the Application, such as learning, Sample test & Live Tests, posting messages in comment sections or in our forums, liking posts, sending feedback, and responding to surveys. If you choose to share data about yourself via your profile, online chat, or other interactive areas of the Application, please be advised that all data you disclose in these areas is public and your data will be accessible to anyone who accesses the Application.

                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Derivative Data
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Information our servers automatically collect when you access the Application, such as your native actions that are integral to the Applications, and other users via server log files.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Financial Data
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date, or any payment gateway) that we may collect when you pay entry fee, fee for explanations of the questions etc, services from the Application. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, (if available at the time of activity) or any payment gateway) and you are encouraged to review their privacy policy and contact them directly for responses to your questions.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Facebook Permissions
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        The Application may by default access your <a href="https://www.facebook.com/about/privacy/">Facebook</a> basic account information, including your name, email, gender, birthday, current city, and profile picture URL, as well as other information that you choose to make public. We may also request access to other permissions related to your account, such as friends, check-ins, and likes, and you may choose to grant or deny us access to each individual permission. For more information regarding <a href="https://developers.facebook.com/docs/facebook-login/permissions">Facebook permissions</a>, refer to the Facebook Permissions Reference page.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Data from Social Networks
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        User information from social networking sites, such as <a href="">Google</a>, <a href="">WhatsApp</a>, <a href="">Facebook</a>, <a href="">Instagram</a> etc], including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks. This information may also include the contact information of anyone you invite to use and/or join the Application.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Geo-Location Information
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using the Application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
                    </p></div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Mobile Device Access
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We may request access or permission to certain features from your mobile device, including your mobile devices <a href="">(one of the eg. bluetooth)</a>]. If you wish to change our access or permissions, you may do so in your device's settings.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Mobile Device Data
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, phone number, country, location, and any other data you choose to provide.

                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Push Notifications
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We may request to send you push notifications regarding your account to update you on the timing of test, latest updates ,new rewards etc or on the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings.
                    </p></div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Third-Party Data
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant the Application permission to access this information.
                    </p></div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Data from Contests, Giveaways, and Surveys
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Personal and other information you may provide when entering Test/ contests or giveaways and/or responding to surveys.
                    </p></div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        USE OF YOUR INFORMATION
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Application to:
                    </p></div>
            </div>
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">

                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Administer sweepstakes, promotions, and contests.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Create and manage your account.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Application to you.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Email you regarding your account or order.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Enable user-to-user communications.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Fulfil and manage purchases, orders, payments, rewards and other transactions related to the Application.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Generate a personal profile about you to make future visits to the Application more personalized.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Increase the efficiency and operation of the Application.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Monitor and analyze usage and trends to improve your experience with the Application.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Notify you of updates to the Application.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Offer new products, services, mobile applications, and/or recommendations to you.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Perform other business activities as needed.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Process payments and refunds.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Request feedback and contact you about your use of the Application.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Resolve disputes and troubleshoot problems.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Respond to product and customer service requests.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Send you a newsletter.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Solicit support for the Application.
                    </li>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h3 className="font-roboto-slab font-bold text-xl xl:text-2xl mb-2">
                        DISCLOSURE OF YOUR INFORMATION
                    </h3>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
                    </p></div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        By Law or to Protect Rights
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.
                    </p></div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Third-Party Service Providers
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
                    </p></div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Marketing Communications
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.
                    </p></div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Interactions with Other Users
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        If you interact with other users of the Application, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, following blogs.


                    </p></div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Online Postings
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        When you post comments, contributions or other content to the Applications, your posts may be viewed by all users and may be publicly distributed outside the Application in perpetuity
                    </p></div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Third-Party Advertisers
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We may use third-party advertising companies to serve ads when you visit the Application. These companies may use information about your visits to the Application and other websites that are contained in web cookies in order to provide advertisements about goods and services of interest to you.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Affiliates
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Business Partners
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We may share your information with our business partners to offer you certain products, services or promotions.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Offer Wall
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        The Application may display a third-party-hosted "offer wall." Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for acceptance and completion of an advertisement offer. Such an offer wall may appear in the Application and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will leave the Application. A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Social Media Contacts
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        If you connect to the Application through a social network, your contacts on the social network will see your name, profile photo, and descriptions of your activity.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Sale or Bankruptcy
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity. If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.
                    </p>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly.
                    </p>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-sm-12">
                    <h3 className="font-roboto-slab font-bold text-xl xl:text-2xl mb-2">
                        TRACKING TECHNOLOGIES
                    </h3>

                </div>
            </div>
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Cookies and Web Beacons
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Application to help customize the Application and improve your experience. When you access the Application, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Application. You may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web browser's settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an individual basis.
                    </p>
                </div>
            </div>

            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Internet-Based Advertising
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Additionally, we may use third-party software to serve ads on the Application, implement email marketing campaigns, and manage other interactive marketing initiatives. This third-party software may use cookies or similar tracking technology to help manage and optimize your online experience with us. For more information about opting-out of interest-based ads, visit the <a href="http://optout.networkadvertising.org/?c=1">Network Advertising Initiative Opt-Out Tool</a> or <a href="http://www.aboutads.info/choices/"> Digital Advertising Alliance Opt-Out Tool</a>.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Website Analytics
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We may also partner with selected third-party vendors, such as <a href="">Google analytics</a> to allow tracking technologies and remarketing services on the Application through the use of first party cookies and third-party cookies, to, among other things, analyse and track users' use of the Application, determine the popularity of certain content, and better understand online activity. By accessing the Application, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors.
                    </p>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser's cookies files may also clear certain opt-out cookies, plug-ins, or settings.
                    </p>
                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        THIRD-PARTY WEBSITES
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        The Application in future may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Application, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Application.
                    </p>

                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        SECURITY OF YOUR INFORMATION
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.
                    </p>

                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        POLICY FOR CHILDREN
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided at the "Contact us" section.
                    </p>

                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        CONTROLS FOR DO-NOT-TRACK FEATURES
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Most web browsers and some mobile operating systems and our mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.
                    </p>

                </div>
            </div>
            <hr className="" />
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        OPTIONS REGARDING YOUR INFORMATION
                    </h4>

                </div>
            </div>
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Account Information
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        You may at any time review or change the information in your account or terminate your account by: using features available in the app itself or the methods mentioned below
                    </p>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Logging into your account settings and updating your account/ or deleting your fields.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Contacting us using the contact information provided at the Contact us field.
                    </li>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
                    </p>
                </div>
            </div>
            <div className="row py-4 lg:py-6">
                <div className="col-sm-12">
                    <h4 className="font-roboto-slab font-bold text-base xl:text-xl mb-2">
                        Emails and Communications
                    </h4>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by Choose from the options below.
                    </p>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Noting your preferences at the time you register your account with the Application


                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Logging into your account settings and updating your preferences.
                    </li>
                    <li className="mt-2 font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        Contacting us using the contact information provided at Contact us field.
                    </li>
                    <p className="font-open-sans-pro text-2xs lg:text-base text-6a6a6a  ">
                        If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.
                    </p>
                </div>
            </div>
        </div>
    )
}
