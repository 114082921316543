import React from 'react'
import { useWindowDimensions } from "../CustomHooks/ScreenH-W";


const TextArea = ({
    title,
    onChange,
    value,
    placeholder,
    transparent,
    autoFocus,
    maxLength,
    // rows = "8"
}) => {

    const {width }=useWindowDimensions()

    let rows;

    if(width<678){
        rows = "10"
    }else{
        rows="5"
    }
    return (
        <div>
            {title && <div
                className="font-soure-sans-pro font-semibold text-base md:text-xl 3xl:text-2xl mb-3"
            >{title}
            </div>}
            <textarea
                rows={rows}
                autoFocus={autoFocus}
                maxLength={maxLength}
                className={`3xl:text-xl w-full mt-3 
                ${transparent
                        ? "bg-transparent shadow-md placeholder-black placeholder-opacity-20"
                        : "bg-FAFAFA focus:border-461995 border border-D3D0D0"}
                focus:outline-none p-4 rounded-md`}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                placeholder={placeholder}
            />
        </div>
    )
}
export default TextArea
