import Slider from "react-slick";
import Brain1 from "../../Assets/Icons/Parent/brain1.svg";
import Button from "../../Components/Button";

export default ({
  heading,
  description,
  leftSectionData,
  rightSectionData,
}) => {
  const ContainerBox = ({ data }) => {
    return (
      <div className='bg-FAFAFA p-4 lg:p-10 font-source-sans-pro w-1/2'>
        <div className='text-3B157D text-xs sm:text-xl 3xl:text-2xl'>
          {data.description1}
        </div>
        <div className='mt-8 '>
          <div className='font-semibold text-sm lg:text-2xl'>
            {data.description2}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='bg-white'>
      <div className='container w-8/10-center'>
        <div className='font-source-sans-pro text-center space-y-8 text-black'>
          <div
            dangerouslySetInnerHTML={{ __html: heading }}
            className='heading  font-semibold text-center'
          />
          <div className='h-1 lg:h-2 bg-black rounded-md  w-5/12 lg:w-1/12 mx-auto'></div>
        </div>

        <div className='mt-16'>
          <img src={Brain1} className='h-auto w-9/10 mx-auto' />
        </div>
        <div className='flex w-full sm:w-2/3 space-x-8 mx-auto'>
          <ContainerBox data={leftSectionData} />
          <ContainerBox data={rightSectionData} />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className='font-source-sans-pro text-lg sm:text-2xl 3xl:text-3xl text-center mt-8 lg:mt-12'
        />
        {/*  <div className="flex md:justify-center">
                    <Button
                        className="mt-8"
                        width='w-full md:w-64'
                        textTransform='uppercase'>
                        Register My Child
                    </Button>
                </div> */}
      </div>
    </div>
  );
};
