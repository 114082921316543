import React, { useState, useContext, useEffect } from "react";
import PhoneInput from "../../../../Components/PhoneInput";
import PhoneOtp from "./PhoneOtp";
import { ProfileContext } from "../../../../Context/ProfileContext";
import { auth } from "../../../../Firebase/firebaseConfig";

import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import toast from "react-hot-toast";
import { validPhone } from "../../../../Utility/Helper";
import { getAnalytics, logEvent } from "firebase/analytics";


const ContactDetails = () => {
  const { phoneNumber, setPhoneNumber, setVerifiedPhoneNo } =
    useContext(ProfileContext);
  // const [otp, setotp] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [final, setfinal] = useState("");
  const [reCaptcha, setReCaptcha] = useState(false)

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
  }, [phoneNumber]);

  // Sent OTP
  const signin = () => {
    const appVerifier = window.recaptchaVerifier;
    setReCaptcha(true)
    console.log(appVerifier);
    if (validPhone(phoneNumber)) {
      signInWithPhoneNumber(auth, "+91 " + phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          setShowOTP(true);
          setfinal(confirmationResult);
          // ...
        })
        .catch((error) => {
          console.error(error);
          toast.error("Some error Occured , reload");
          // Error; SMS not sent
          // ...
        });
    } else {
      toast.error("Invalid Phone Number");
    }
  };

  const changeNumber = () => {
    setShowOTP(false)
    setReCaptcha(false)
  }

  // Validate OTP
  const ValidateOtp = (otp) => {
    console.log(otp);
    final
      .confirm(otp)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log(user);
        setVerifiedPhoneNo(true);
        
        const analytics = getAnalytics();
        logEvent(analytics, 'CREATE_PROFILE_PHONE', {
          created: "true"
        });

        // ...
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        toast.error("Wrong Otp");
        // window.location.reload()
      });
  };

  return (
    <div>
      {showOTP ? (
        <div>
          <PhoneOtp
            setShowOTP={setShowOTP}
            reSendOTP={signin}
            phoneNumber={phoneNumber}
            verifyOTP={ValidateOtp}
            edit={changeNumber}
          />
        </div>
      ) : (
        <div>
          <PhoneInput
            title='You can call me on'
            transparent
            large
            disabled={reCaptcha}
            value={phoneNumber}
            onChange={setPhoneNumber}
            sendOTP={signin}
          />
          <div id='recaptcha-container'></div>
        </div>
      )}
    </div>
  );
};
export default ContactDetails;
