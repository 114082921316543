import React, { useState } from "react";
import HeroSection from "../Sections/HeroSection";
import Jumbotron from "../../Components/Jumbotron/Jumbotron";
// import JumbotronData from "../../Data/Jumbotron.json";
// import CoachingData from "../../Data/CoachingInstitutions.json";
// import teacherData from "../../Data/TeachersData.json";
import ScoreAnalysis from "../Sections/ScoreAnalysis";
import Stepper from "../Sections/StepSection/Stepper";
import CoachingInstituteFeatureSection from "../Sections/CoachingInstituteFeatureSection";
import CoachingInstituteSection from "../Sections/CoachingInstituteSection";

import Cloud from "../../Assets/Icons/Cloud.svg";
// import YoutubeWhite from "../../Assets/Icons/YoutubeWhite.svg";
import FeatureSection from "../Sections/FeatureSection";
import CoachingFeatureSection from "./FeatureSection";

// import FunSection from "../Sections/FunSection";
import OfferSection from "../Sections/OfferSection";
import DownloadAppSection from "../Sections/DownloadAppSection";
import Modal from "../../Components/Modal";
import ContactUs from "../Modals/ContactUs";
import { useHistory } from "react-router";
// import FeatureFrame from "../Sections/FeatureFrame";
// import AankalanData from "../../Data/Aankalan.json";
import graduateUser from "../../Assets/Icons/graduateUser.svg";
import setting from "../../Assets/Icons/Teacher-Coaching-Flow/setting.svg";
import school from "../../Assets/Icons/Teacher-Coaching-Flow/school.svg";
import higherStudies from "../../Assets/Icons/Teacher-Coaching-Flow/higher-studies.svg";
import DataScience from "../../Assets/Images/DataScience.svg";
import MockTest from "../../Assets/Images/MockTest.svg";
import NationalRankPrediction from "../../Assets/Images/NationalRankPrediction.svg";
import Scholarship from "../../Assets/Images/Scholarship.svg";
import digital from "../../Assets/Icons/Teacher-Coaching-Flow/online-icon.png";
import migrating from "../../Assets/Icons/Teacher-Coaching-Flow/migrating-icon.png";
import notFound from "../../Assets/Icons/Teacher-Coaching-Flow/not-found-icon.png";
import contest from "../../Assets/Icons/Teacher-Coaching-Flow/online-test-icon.png";
import Competing from "../../Assets/Icons/Teacher-Coaching-Flow/competing-icon.png";
import Stop from "../../Assets/Icons/Teacher-Coaching-Flow/stop-icon.png";


let AankalanData = require('../../Data/Aankalan.json')
let JumbotronData = require('../../Data/Jumbotron.json')
let CoachingData = require('../../Data/CoachingInstitutions.json')
let teacherData = require('../../Data/TeachersData.json')



const CoachingInstitute = () => {
  const history = useHistory();
  const [showContactModal, setShowContactModal] = useState(false);
  const {
    heading: headingFAQ,
    coachingDescription: descriptionFAQ,
    btnText: btnTextFAQ,
  } = JumbotronData["FAQ"];
  const {
    heading: headingCU,
    coachingDescription: descriptionCU,
    btnText: btnTextCU,
  } = JumbotronData["CONTACT_US"];
  const {
    heading: headingRTC,
    description: descriptionRTC,
    statsArray: statsArrayRTC,
  } = CoachingData["REASON_TO_CHOOSE"];

  const { text: textHS, statsArray: statsarrayHS } =
    CoachingData["HERO_SECTION"];
  const {
    heading: headingSS,
    stepsArray,
    description: descriptionSS,
  } = CoachingData["STEP_SECTION"];
  // const {
  //   heading: headingYM,
  //   mentorsList,
  //   description: descriptionYM,
  // } = YoutuberData;
  const {
    coachingHeading: headingDA,
    coachingDescription: descriptionDA,
    adImage,
    qrImage,
  } = AankalanData["DOWNLOAD_APP"];
  const {
    heading: headingUSP,
    description: descriptionUSP,
    statsArray: statsArrayUSP,
  } = CoachingData["USP_SECTION"];

  const {
    heading: headingCS,
    description: descriptionCS,
    statsArray: statsArrayCS,
  } = CoachingData["CHALLENGES_SECTION"];
  const {
    heading: headingCP,
    description: descriptionCP,
    partners,
  } = CoachingData["COACHING_PARTNER"];

  const {
    heading: headingFS,
    description: descriptionFS,
    image: imageFS,
  } = CoachingData["FEATURE_SECTION"];

  // const percentageColorArray = ["EADA7CE5", "FD757D", "77DDB9"];
  const uspImageArray = [graduateUser, school, setting, higherStudies];
  const statsImagesRTC = [
    MockTest,
    DataScience,
    NationalRankPrediction,
    Scholarship,
  ];

  const statsImagesCS = [
    digital,
    migrating,
    Stop,
    contest,
    Competing,
    notFound,
  ];

  return (
    <div className='mt-16 lg:mt-20 3xl:mt-24'>
      <HeroSection text={textHS} data={statsarrayHS} />
      <CoachingInstituteFeatureSection
        heading={headingUSP}
        description={descriptionUSP}
        statsArray={statsArrayUSP}
        uspImageArray={uspImageArray}
      />
      <CoachingFeatureSection
        heading={headingCS}
        description={descriptionCS}
        statsArray={statsArrayCS}
        statsImages={statsImagesCS}
      />
      <Stepper
        heading={headingSS}
        stepsArray={stepsArray}
        description={descriptionSS}
        teacherData={teacherData}
      />
      <ScoreAnalysis
        heading={headingRTC}
        description={descriptionRTC}
        isDividerVisibile={false}
        statsArray={statsArrayRTC}
        statsImages={statsImagesRTC}
        bgColor='gradient'
        textColor='FFFFFF'
      />
      <FeatureSection
        heading={headingFS}
        image={imageFS}
        description={descriptionFS}
      />

      {/* <FeatureFrame
        id='mentorsList'
        heading={headingYM}
        description={descriptionYM}
        cardsList={mentorsList}
        btnTextArr={["Our Partner Youtubers"]}
        theme='PURPLE'
        bgImage={true}
        image={YoutubeWhite}
        imgAlt='Youtube'
        btnOnClickArr={[() => history.push("find-youtuber")]}
      /> */}

      <CoachingInstituteSection
        heading={headingCP}
        description={descriptionCP}
        partners={partners}
      />

      {/*  <OfferSection type='COACHINGINSTITUTIONS' /> */}
      <DownloadAppSection
        heading={headingDA}
        description={descriptionDA}
        adImage={adImage}
        qrImage={qrImage}
      />
      <Jumbotron
        bgColor='F5F5F5'
        heading={headingFAQ}
        description={descriptionFAQ}
        btnText={btnTextFAQ}
        headingColor='000000'
        descriptionColor='000000'
        src={Cloud}
        alt='cloud'
        height='11'
        width='11'
        position=''
        buttonOnclick={() => {
          window.scrollTo(0, 0);
          history.push("/faq");
        }}
      />
      <Jumbotron
        bgColor='230D4B'
        heading={headingCU}
        description={descriptionCU}
        btnText={btnTextCU}
        headingColor='FFFFFF'
        descriptionColor='FFFFFF'
        btnColor='FFFFFF'
        btnTextColor='000000'
        src={""}
        alt=''
        height=''
        width=''
        position=''
        buttonOnclick={() => {
          window.scrollTo(0, 0);
          setShowContactModal(true);
        }}
      />
      <Modal
        visible={showContactModal}
        close={() => setShowContactModal(false)}
        width='w-11/12 md:w-10/12'>
        <ContactUs close={() => setShowContactModal(false)} />
      </Modal>
    </div>
  );
};

export default CoachingInstitute;
