import ColoredFeatureCard from "../../Components/Card/ColoredFeatureCard";

export default ({ heading, description, statsArray, statsImages }) => {
  return (
    <div className='bg-gradient'>
      <div className='container w-8/10-center'>
        <div className='font-source-sans-pro text-center space-y-8 text-white'>
          <div dangerouslySetInnerHTML={{ __html:heading }} className='heading  font-semibold text-center'/>
          <div className='border-2 lg:border-4 border-white rounded-md  w-5/12 lg:w-1/12 mx-auto'></div>
          <div dangerouslySetInnerHTML={{ __html:description }} className='sub-heading '/>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8 lg:mt-14'>
          {statsArray.map((item, index) => (
            <ColoredFeatureCard
              key={index}
              image={statsImages[index]}
              bgcolor={index % 2 == 0 ? "bg-E6D8FF" : "bg-FFF3DE"}
              textColor='text-black'
              border={
                index % 2 === 0
                  ? "border border-461995"
                  : "border border-DB8B00"
              }
              text={item.text}
              stat={item.stat}
              type={item.type}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
