export default ({ rank, image, name, date, city, examTaken, scholarship }) => (
  <div className='grid grid-cols-12  px-2 py-5 text-base border-b-2 border-F1F1F1'>
    <div className='col-span-1 flex justify-center text-xs'>
      <div className='bg-461995 rounded-full text-white h-6 w-6 flex-centerXY'>
        {rank}
      </div>
    </div>
    {/* <div className='col-span-2 text-center flex-centerXY'>{date}</div> */}
    <div className='col-span-4 space-x-2 items-center flex justify-start ml-4'>
      <div className='h-6 w-6 rounded-full overflow-hidden '>
        <img src={image?image:"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} className='h-full w-full' />
      </div>
      <div>{name}</div>
    </div>
    <div className='col-span-2 flex-centerXY'>{city}</div>
    <div className='col-span-2 flex-centerXY'>{examTaken}</div>
    <div className='col-span-3 text-FFA300 flex-centerXY'>
      &#8377;{scholarship}
    </div>
  </div>
);
