// import { initializeApp  } from 'firebase/app'
// import {getAuth} from 'firebase/auth'

// const firebase = initializeApp(firebaseConfig);
// const auth = getAuth()
// export { auth, firebase };

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDqTyUhxxsX9K-88zdBz6CoJ6sPuFwwfto",
  authDomain: "aankalan.firebaseapp.com",
  databaseURL: "https://aankalan.firebaseio.com",
  projectId: "aankalan",
  storageBucket: "aankalan.appspot.com",
  messagingSenderId: "114633911662",
  appId: "1:114633911662:web:e33b5d17a0693d2612657f",
  measurementId: "G-J3KDBC7B35",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCSJF1p3lkt0Eew_nTR2BGypa-UMDCulgY",
//   authDomain: "aankalan-learning.firebaseapp.com",
//   projectId: "aankalan-learning",
//   storageBucket: "aankalan-learning.appspot.com",
//   messagingSenderId: "353989358642",
//   appId: "1:353989358642:web:557d3805166c9ecaf9b82b",
// };

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const auth = getAuth();
export { auth, firebase };
