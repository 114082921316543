import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExamCard from "../../Components/Card/ExamCard";
import YellowArrowUp from "../../Assets/Icons/YellowArrowUp.svg";
import YellowArrowDown from "../../Assets/Icons/YellowArrowDown.svg";
import DropDown from "../../Components/CustomSelect/DropDown";
import SelectOption from "../../Components/CustomSelect/SelectOption";
import { handleSelect } from "../../Utility/Helper";
import { useWindowDimensions } from "../../CustomHooks/ScreenH-W";
import { userActions } from "../../Store/Actions";

const ExamsSection = ({ heading, exams, examMap }) => {
  const { width, height } = useWindowDimensions();
  const { category } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [selectedExamination, setSelectedExamination] = useState(category);

  const [dropdownActive, setDropdownActive] = useState(false);

  const onOptionSelect = (value) => {
    setSelectedExamination(examMap[value]);
    setDropdownActive(false);
    dispatch(userActions.updateUser({ category: examMap[value] }));
  };

  const domainList = Object.keys(exams);

  return (
    <div className='md:w-9/12 xl:w-7/12 md:mx-auto py-10 sm:py-24'>
      <div className='heading text-center relative space-y-1 xl:space-y-4'>
        <div dangerouslySetInnerHTML={{ __html: heading }} />
        <div
          className='flex items-baseline justify-center space-x-4 cursor-pointer'
          onClick={(e) => handleSelect(e, dropdownActive, setDropdownActive)}>
          <div className='text-FFA300 font-normal'>
            {exams[selectedExamination].name}
          </div>
          <div className='h-3 w-3 sm:h-4 sm:w-4 lg:h-5 lg:w-5'>
            {dropdownActive ? (
              <img
                src={YellowArrowUp}
                alt='up arrow'
                className='h-full w-full'
              />
            ) : (
              <img
                src={YellowArrowDown}
                alt='down arrow'
                className='h-full w-full'
              />
            )}
          </div>
        </div>
        {dropdownActive && (
          <DropDown
            leftRightTopBottom='-bottom-84 left-16 sm:left-24 lg:left-1/3 w-2/3 lg:w-1/3'
            onOptionSelect={onOptionSelect}
            selectedOption={exams[selectedExamination].name}
            dropdownActive={dropdownActive}
            setDropdownActive={setDropdownActive}>
            {domainList.map((domain, index) => (
              <SelectOption key={index} value={exams[domain].name}>
                {exams[domain].name}
              </SelectOption>
            ))}
          </DropDown>
        )}
      </div>
      <div
        className={`${
          width >= 768 ? "mt-14" : "w-9/10-left-without-py mt-8"
        } flex md:grid md:grid-cols-3 gap-6 overflow-without-scrollbar pb-1`}>
        {exams[selectedExamination]?.statsArray.length > 0 &&
          exams[selectedExamination].statsArray.map((exam, index) => (
            <div className='md:col-span-1'>
              <div
                key={index}
                className='w-44 sm:w-56 md:w-full h-full md:h-64 lg:h-68 xl:h-72 2xl:h-80 3xl:h-88'>
                <ExamCard
                  width='full'
                  image={exam.image}
                  title={exam.heading}
                  description={exam.description}
                  isAvailable={exam.isAvailable}
                  tag={exam.daysLeft ? `${exam.daysLeft} days left*` : ""}
                  moreInfo={exam.moreInfo}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default ExamsSection;
