import React, { useState, useContext, useEffect } from "react";
import Jumbotron from "../../Components/Jumbotron/Jumbotron";
import Modal from "../../Components/Modal";
import ContactUs from "../Modals/ContactUs";
import DownloadAppSection from "../Sections/DownloadAppSection";
import JumbotronData from "../../Data/Jumbotron.json";
// import AankalanData from "../../Data/Aankalan.json";
import Cloud from "../../Assets/Icons/Cloud.svg";
import { Link, useHistory } from "react-router-dom";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import celebration from "../../Assets/Icons/Create-Contest/celebration-1.svg";
import OurFeatures from "../Aankalan/OurFeatures";
import SoftwareEnggWithBg from "../../Assets/Icons/SoftwareEnggWithBg.svg";
import CognitivePhysc from "../../Assets/Icons/CognitivePhysc.svg";
import CirriculumTheorist from "../../Assets/Icons/CirriculumTheorist.svg";
import { ProfileService } from "../../Services/profile.service";
import toast from "react-hot-toast";
import { ProfileContext } from "../../Context/ProfileContext";
import Group from "../../Assets/Icons/Group.svg";
import { getAnalytics, logEvent } from "firebase/analytics";

let AankalanData = require('../../Data/Aankalan.json')

const CreateContest = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { setName, setEmail, setUserID } = useContext(ProfileContext);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'CREATE_PROFILE', {
      landed: "true"
    });


  }, [])


  const {
    heading: headingFAQ,
    createContestDescription: descriptionFAQ,
    btnText: btnTextFAQ,
  } = JumbotronData["FAQ"];
  const {
    heading: headingCU,
    createContestDescription: descriptionCU,
    btnText: btnTextCU,
  } = JumbotronData["CONTACT_US"];
  const {
    teacherHeading: headingDA,
    teacherDescription: descriptionDA,
    adImage,
    qrImage,
  } = AankalanData["DOWNLOAD_APP"];

  const { heading: headingCC, statsArray: statsArrayCC } =
    AankalanData["CREATE_CONTEST"];

  const statsImagesCC = [
    SoftwareEnggWithBg,
    CognitivePhysc,
    CirriculumTheorist,
  ];

  const validateCode = () => {
    setLoading(true);
    ProfileService.validateTeacherCode(code)
      .then((res) => {
        setName(res.name);
        setEmail(res.email);
        setUserID(res.id);
        history.push("/create-contest/form");
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Wrong Code");
        setLoading(false);
      });
  };

  return (
    <div className='mt-16 lg:mt-20 3xl:mt-24 relative overflow-hidden'>
      <div className='text-center lg:text-left relative flex flex-col justify-center bg-461995  bg-opacity-75 xl:bg-transparent xl:opacity-100  px-9 sm:px-16 xl:px-28 2xl:px-36 3xl:px-44 h-80vh sm:h-60vh '>
        <div className='hidden xl:block absolute bottom-0 right-0 left-0 create-contest-atf-bg h-60vh'></div>
        <div className='text-4.5xl lg:text-6xl 3xl:text-7xl text-white uppercase font-bold'>
          CREATE YOUR CONTEST
        </div>
        <div className='text-sm sm:text-base 3xl:text-xl text-white mt-9'>
          Enter your <span className='font-bold'>unique aankalan code</span>
        </div>
        <div className='flex flex-col lg:flex-row justify-center lg:justify-start space-y-4  lg:space-y-0 lg:space-x-4 mt-3'>
          <div className='w-48 lg:w-3/12 mx-auto lg:mx-0'>
            <Input
              onChange={setCode}
              value={code}
              placeholder='Enter Your Code'
            />
          </div>
          <div className='mx-auto'>
            <Button
              onClick={validateCode}
              loading={loading}
              width='w-48'
              outline={true}
              buttonColor='FFFFFF'>
              SUBMIT
            </Button>
          </div>
        </div>
        <div className='text-sm sm:text-base 3xl:text-xl text-white mt-6'>
          Don't have your unique aankalan code?
        </div>
        <div className='mt-2'>
          <Link to='/create-contest/welcome'>
            <Button width='w-48'>Create your code</Button>
          </Link>
        </div>

        <div className='text-sm sm:text-base 3xl:text-xl text-white 4xl:text-xl mt-2 sm:inline-flex'>
          If you are facing any problem then contact us at
          <a href='mailto:test@aankalan.app'>
            <div className='text-FFA200 underline sm:ml-1 font-bold'>
              {" "}
              contest@aankalan.app
            </div>
          </a>
        </div>
      </div>
      <div className='bg-F7F7F7 px-9 sm:px-16 xl:px-28 2xl:px-36 3xl:px-44'>
        <div className='py-12 text-393939'>
          <div>WHY SETUP YOUR CONTEST AT AANKALAN?</div>
          <div className='grid grid-cols-12 xl:flex 2xl:space-x-6 3xl:space-x-8 mt-6 text-sm 2xl:text-base 3x:text-lg'>
            <div className='col-span-12 md:col-span-6 flex space-x-4  justify-start sm:justify-center lg:justify-start items-center flex-grow'>
              <img src={Group} className='h-14 w-14 rounded-full' alt='group' />
              <div>24X7 Aankalan team support</div>
            </div>
            <div className='col-span-12 md:col-span-6 flex space-x-4  justify-start sm:justify-center lg:justify-start items-center flex-grow'>
              <img src={Group} className='h-14 w-14 rounded-full' alt='group' />
              <div>Zero joining fees</div>
            </div>
            <div className='col-span-12 md:col-span-6  flex space-x-4 justify-start sm:justify-center lg:justify-start items-center flex-grow'>
              <img src={Group} className='h-14 w-14 rounded-full' alt='group' />
              <div>Reachout to nationwide students/followers</div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='hidden xl:block'>
        <div className='absolute top-0 3xl:top-24 -left-2/3 lg:-left-full xl:-left-96'>
          <img src={celebration} alt='celebration' width='auto' height='auto' />
        </div>
        <div className='absolute top-0 3xl:top-24 -right-2/3 lg:-right-112 xl:-right-96'>
          <img src={celebration} alt='celebration' width='auto' height='auto' />
        </div>
      </div>
      <div className='bg-gradient '>
        <div className='flex justify-center items-center h-90vh'>
          <div className='w-full xl:w-1/2 font-source-sans-pro text-center'>
            <div className='text-white text-lg lg:text-2xl 4xl:text-3xl'>
              Teachers And Youtubers,
            </div>
            <div className='uppercase text-5xl lg:text-7xl 4xl:text-8xl font-black mt-5'>
              <div className='text-FFA200'>create a contest</div>
              <div className='text-white'>on aankalan</div>
            </div>
            <div className='text-white text-lg lg:text-2xl 4xl:text-3xl mt-4'>
              Enter your Aankalan's unique code
            </div>
            <div className='flex flex-col xl:flex-row justify-center space-y-4  xl:space-y-0 xl:space-x-4 mt-3'>
              <div className='w-48 xl:w-auto mx-auto xl:mx-0'>
                <Input
                  onChange={setCode}
                  value={code}
                  placeholder='Your Code'
                />
              </div>
              <div className='mx-auto'>
                <Button onClick={validateCode} loading={loading} width='w-48'>
                  LET'S GET STARTED
                </Button>
              </div>
            </div>
            <div className='text-sm lg:text-lg 4xl:text-xl mt-10 flex justify-center'>
              <div className='text-white'>
                To get your unique code, set up your profile by
              </div>
              <Link to='/create-contest/welcome'>
                <div className='text-FFA200 ml-1'> clicking here.</div>
              </Link>
            </div>
            <div className='text-sm lg:text-lg text-white 4xl:text-xl mt-2 text-center'>
              If you are facing any problem then contact us at 
              <a href="mailto:contest@aankalan.app">
                <div className='text-FFA200'>contest@aankalan.app</div>
              </a>
            </div>
          </div>
        </div>
      </div> */}
      <div className='font-source-sans-pro py-10 sm:py-24 text-center px-6'>
        <div className='heading'>Welcome to WiseMagnet,</div>
        <div className='heading'>Aankalan's Exclusive Contest Club!</div>
        <div className='text-461995 px-8 py-1 bg-EDE3FF rounded-full mt-4 inline-block sub-heading font-bold'>
          Aankalan is thrilled to announce a club for its premium contest
          creators.
        </div>

        <div className='text-5A5A5A text-sm sm:text-xl lg:text-2xl mt-10 w-10/12 sm:w-2/3 lg:w-1/2 mx-auto'>
          At WiseMagnet, we bring together experts who have not only achieved
          significant success in their own lives but have also contributed to
          others’ success.
          <br /> <br />
          Thank you for propelling your students/ subscribers in the forward
          direction! Thank you for bringing an impact into their lives! It is
          the honour of WiseMagnet to have you amidst its fold.
          <br /> <br />
          The objective of this club is to test students/ subscribers & expand
          their knowledge as well as to increase the engagement our esteemed
          club members have with their students/ subscribers. And, of course,
          the best part of WiseMagnet is the supplemental income our members
          earn
          <br /> <br />
          We doubt we can put into words just how very excited we are! We always
          wanted to bring earning, fun & learning together. And WiseMagnet is a
          step in that direction!
        </div>
      </div>
      <OurFeatures
        heading={headingCC}
        statsArray={statsArrayCC}
        statsImages={statsImagesCC}
        bordered={true}
      />
      <DownloadAppSection
        heading={headingDA}
        description={descriptionDA}
        adImage={adImage}
        qrImage={qrImage}
      />
      <Jumbotron
        bgColor='F5F5F5'
        heading={headingFAQ}
        description={descriptionFAQ}
        btnText={btnTextFAQ}
        headingColor='000000'
        descriptionColor='000000'
        src={Cloud}
        alt='cloud'
        height='11'
        width='11'
        position=''
        buttonOnclick={() => {
          window.scrollTo(0, 0);
          history.push("/faq");
        }}
      />
      <Jumbotron
        bgColor='230D4B'
        heading={headingCU}
        description={descriptionCU}
        btnText={btnTextCU}
        headingColor='FFFFFF'
        descriptionColor='FFFFFF'
        btnColor='FFFFFF'
        btnTextColor='000000'
        src={""}
        alt=''
        height=''
        width=''
        position=''
        buttonOnclick={() => {
          window.scrollTo(0, 0);
          setShowContactModal(true);
        }}
      />
      <Modal
        visible={showContactModal}
        close={() => setShowContactModal(false)}
        width='w-11/12 md:w-10/12'>
        <ContactUs close={() => setShowContactModal(false)} />
      </Modal>
    </div>
  );
};

export default CreateContest;
