import React from "react";
import Aankalan from "../../Assets/Icons/Aankalan.png";
import Button from "../../Components/Button";
import Image from "../../Components/Image";
import Input from "../../Components/Input";
import PhoneInput from "../../Components/PhoneInput";
// import aankalanData from "../../Data/Aankalan.json";

let aankalanData = require('../../Data/Aankalan.json')

const Step1 = ({ changeMode }) => {
  const { companyName, tagLine } = aankalanData;

  return (
    <div className='w-10/12 sm:w-2/3 mx-auto'>
      <div className='py-7 md:py-10'>
        <div className='flex items-center'>
          <Image
            src={Aankalan}
            alt='logo'
            height='h-8 md:h-12'
            width='w-8 md:w-12'
          />
          <div className='font-roboto-slab font-bold text-left text-xl md:text-3xl text-black'>
            {companyName.slice(1)}
          </div>
        </div>
        <div className='font-source-sans-pro font-semibold  text-3xs md:text-sm  uppercase tracking-wider'>
          {tagLine}
        </div>
      </div>
      <div>
        <div className='mt-3'>
          <div className='heading2 font-bold'>Student Registration</div>
          <div className='sub-heading'>Register with aankalan</div>
        </div>
        <div className='py-10'>
          <div className=''>Register via Phone Number</div>
          <form>
            <div className='mt-4'>
              <PhoneInput
                placeholder='Enter Your Mobile Number'
                title='Mobile Number'
              />
            </div>
            <div className='mt-8'>
              <Input placeholder='Enter Your Email Id' title='Email' />
            </div>
            <div className='mt-11'>
              <Button onClick={() => changeMode("STEP2")} width='w-full'>
                Register Me
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Step1;
