import googlePlay from "../Assets/Images/GooglePlay-icon.png";
// import aankalanData from "../Data/Aankalan.json" 

let aankalanData = require('../Data/Aankalan.json')

const GooglePlayButton = () => {
  return (
    <a
      href={aankalanData.appLink}
      target='_blank'
      rel='noopener noreferrer'>
      <img src={googlePlay} className='h-10 w-36 4xl:h-16 4xl:w-56' alt='google playstore' />
    </a>
  );
};

export default GooglePlayButton;
