import React from "react";
import CorrectAns from "../../Assets/Icons/Create-Contest/CorrectAns.svg";
import Option from "../../Assets/Icons/Create-Contest/Option.svg";
import Delete from "../../Assets/Icons/Create-Contest/Delete.svg";

const Question = ({
  index,
  numberOfQuestions,
  question,
  options,
  correctAnswer,
  explanation,
  changeQuestionHandler,
  changeExplanationHandler,
  changeOptionHandler,
  changeAnswerHandler,
  deleteQuestionHandler,
}) => {
  const showDeleteButton = () => {
    if (index === 0 && numberOfQuestions > 1) {
      return true;
    } else if (index === 0 && numberOfQuestions <= 1) {
      return false;
    }
    return true;
  };
  return (
    <div className='flex space-x-4 justify-between mb-8'>
      <div className='font-source-sans-pro w-11/12 space-y-3'>
        <div className='font-semibold text-2xs md:text-sm xl:text-base 3xl:text-lg uppercase'>
          QUESTION {index + 1}
        </div>
        <input
          className='text-sm w-full h-12 sm:h-14 3xl:h-16 p-4 bg-FAFAFA focus:outline-none  focus:border-461995  border-2 border-D3D0D0 rounded-md'
          placeholder='Type your question here...'
          value={question}
          type='text'
          onChange={(e) => changeQuestionHandler(e, index)}></input>
        <div className='font-semibold sub-heading uppercase'>ANSWERS</div>
        <div className='grid grid-cols-2 gap-2'>
          {options.map((option, optionIndex) => (
            <div className='col-span-2  flex items-center space-x-4'>
              <div
                onClick={() => changeAnswerHandler(index, optionIndex)}
                className='cursor-pointer'>
                <img
                  src={
                    correctAnswer.includes(optionIndex + 1)
                      ? CorrectAns
                      : Option
                  }
                  alt='option'
                  className='h-5 w-5'
                />
              </div>
              <input
                className='3xl:text-xl w-full  h-8 sm:h-10 3xl:h-12 bg-FAFAFA  focus:outline-none  focus:border-461995  border-2 border-D3D0D0 rounded-md px-4 text-sm'
                onChange={(e) => changeOptionHandler(e, index, optionIndex)}
                value={option}
                placeholder={`Type option ${String.fromCharCode(
                  97 + optionIndex
                ).toUpperCase()}`}
              />
            </div>
          ))}
        </div>
        <div className='flex space-x-4'>
          <div className='w-5'></div>
          <input
            className='3xl:text-xl w-full  h-8 sm:h-10 3xl:h-12 bg-FAFAFA  focus:outline-none  focus:border-461995  border-2 border-D3D0D0 rounded-md px-4 text-sm'
            onChange={(e) => changeExplanationHandler(e, index)}
            value={explanation}
            placeholder='Add Explanation'
          />
        </div>
      </div>
      {showDeleteButton() && (
        <div
          onClick={() => deleteQuestionHandler(index)}
          className='cursor-pointer mt-11'>
          <img src={Delete} alt='delete question' className='h-7 w-7' />
        </div>
      )}
    </div>
  );
};

export default Question;
