import React from "react";
import Verified from "../../Assets/Icons/Green-Verified.svg";
import Youtube from "../../Assets/Icons/Youtube.svg";
import { Avtaar } from "../Avtaar";

const theme = {
  PINK: {
    bgColor: "bg-FAD6D6",
    borderColor: "border-FFB5B5",
  },
  BLUE: {
    bgColor: "bg-B5DCEA",
    borderColor: "border-B5DCEA",
  },
  PURPLE: {
    bgColor: "bg-EBCFEB",
    borderColor: "border-786AB5",
  },
};

const ProfileCard = ({
  name,
  description,
  text,
  verified,
  image,
  organization,
  isOrganization = false,
  index,
}) => {
  let selectedTheme;

  if (index % 3 === 0) selectedTheme = theme["PINK"];
  else if (index % 3 === 1) selectedTheme = theme["BLUE"];
  else if (index % 3 === 2) selectedTheme = theme["PURPLE"];

  return (
    <div
      className={`w-full h-full rounded p-4 xl:p-7 relative ${selectedTheme.bgColor} border ${selectedTheme.borderColor}`}>
      <div className='flex items-center'>
        <Avtaar image={image} />
        <div className='font-source-sans-pro ml-3'>
          <div className='text-2C2C2C card-heading font-semibold'>{name}</div>
          {isOrganization && (
            <div className='flex space-x-2'>
              <img src={Youtube} alt='youtube' />
              <div className='text-2C2C2C text-xs 3xl:text-sm'>
                {organization}
              </div>
            </div>
          )}
          <div className='text-8E8E8E text-xs 3xl:text-sm'>{description}</div>
        </div>
      </div>
      <div className='font-source-sans-pro text-1A1A1A card-content mt-6'>
        {text}
      </div>
      {verified && (
        <div className='absolute top-4 right-4'>
          <img className='w-4 h-4 ' alt='tick' src={Verified} />
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
