import React from "react";
import GreenTick from '../../Assets/Icons/Create-Contest/green-tick.svg'

const RadioTabs = (props) => {

  let selected = props.selectedValue === props.value
  return (
    <div
      className={`cursor-pointer relative`}>
      <div
        className={`flex justify-center items-center ${props.large ? "h-24 w-24 sm:w-32 sm:h-32" : "w-24 h-24"} shadow-md  border rounded-md ${selected
            ? "bg-white"
            : "bg-transparent"
          }`}
        onClick={() => props.onClick(props.value)}>
        <img src={props.icon} className={`${props.large ? "h-2/3 w-auto" : "h-auto w-16"}`} />
        {selected && <span className="absolute top-2 right-2 h-6 w-6 text-white flex-centerXY">
          <img src={GreenTick} className="w-full h-full"/>
        </span>}
      </div>
      <div className='text-center text-646464 mt-2'>{props.children}</div>
    </div>
  );
};
export default RadioTabs;

// ${selected ? "opacity-100" : "opacity-40"}
