import React, { useState, useEffect } from "react";
import RightArrowBorder from "../../Assets/Icons/RightArrowBorder.svg";
import LeftArrowBorder from "../../Assets/Icons/LeftArrowBorder.svg";
import RightArrowBgWhite from "../../Assets/Icons/RightArrowBgWhite.svg";
import LeftArrowBgWhite from "../../Assets/Icons/LeftArrowBgWhite.svg";
import Button from "../../Components/Button";
import YoutubeIcon from "../../Assets/Icons/RedYoutube.png";
import { getCardWidth, sideScroll } from "../../Utility/Helper";

const ScrollableFrame = ({
  containerId,
  children,
  theme,
  isButtonVisible = false,
  btnOnClickArr,
  btnTextArr,
}) => {
  const [scrollableWidth, setScrollableWidth] = useState(0);
  const [contentScolled, setContentScolled] = useState(704);
  const [disableLeftArrow, setDisableLeftArrow] = useState(true);

  useEffect(() => {
    let element = document.getElementById(containerId);
    let scrollableContent = element.scrollWidth;
    let cardWidth = getCardWidth(`${containerId}-card-0`);

    setScrollableWidth(scrollableContent);
    setContentScolled(contentScolled + cardWidth);
  }, []);

  const scrollLeft = () => {
    sideScroll("LEFT", 50, containerId);

    let cardWidth = getCardWidth(`${containerId}-card-0`);
    let temp = contentScolled - cardWidth;
    setContentScolled(temp);
    if (temp <= 704 + cardWidth) {
      setDisableLeftArrow(true);
    }
  };

  const scrollRight = () => {
    sideScroll("RIGHT", 50, containerId);

    let cardWidth = getCardWidth(`${containerId}-card-0`);
    let temp = contentScolled + cardWidth;
    setContentScolled(temp);
    if (temp > 704 + cardWidth) {
      setDisableLeftArrow(false);
    }
  };

  return (
    <div className='flex flex-col '>
      <div>{children}</div>
      <div
        className={`flex items-center mt-0 lg:mt-8  ${
          isButtonVisible
            ? "-ml-10 sm:-ml-14 md:-ml-20 lg:-ml-0  justify-center lg:justify-between"
            : "justify-center"
        }`}>
        <div className='flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0'>
          {isButtonVisible &&
            btnTextArr.map((btn, index) =>
              index === 0 ? (
                <Button
                  onClick={btnOnClickArr[index]}
                  width='w-52 lg:w-48 2xl:w-60'>
                  {btn}
                </Button>
              ) : (
                <div
                  className='flex items-center justify-center space-x-2 border-2 border-FF1818 rounded-lg w-52 lg:w-52 2xl:w-60 h-12 sm:h-14 3xl:h-16'
                  onClick={btnOnClickArr[index]}>
                  <div className='w-5 h-auto'>
                    <img
                      src={YoutubeIcon}
                      alt='youtube'
                      height='h-full'
                      width='w-full'
                    />
                  </div>
                  <div className='font-source-sans-pro font-semibold text-center text-black text-base capitalize'>
                    {btn}
                  </div>
                </div>
              )
            )}
        </div>
        <div className='hidden lg:flex items-center justify-end space-x-4 pr-16 4xl:pr-32 w-full'>
          <div
            id='leftArrow'
            className={`h-9 w-9 4xl:h-12 4xl:w-12 ${
              !disableLeftArrow ? "cursor-pointer" : "opacity-50"
            }`}
            onClick={scrollLeft}>
            <img
              src={theme === "LIGHT" ? LeftArrowBorder : LeftArrowBgWhite}
              alt='left arrow'
              className={`w-full h-full`}
            />
          </div>
          <div
            id='rightArrow'
            className={`h-9 w-9 4xl:h-12 4xl:w-12 ${
              contentScolled < scrollableWidth ? "cursor-pointer" : "opacity-50"
            }`}
            onClick={scrollRight}>
            <img
              src={theme === "LIGHT" ? RightArrowBorder : RightArrowBgWhite}
              alt='right arrow'
              className='w-full h-full'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollableFrame;
