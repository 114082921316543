import React, { useState } from "react";
import Button from "../../../Components/Button";
import QuestionsList from "./QuestionsList";
import toast from "react-hot-toast";

const QuizEditor = ({ quizQuestions, updateQuestionSet, updateStage }) => {
  const [questions, setQuestions] = useState(quizQuestions);

  const questionStructure = {
    title: "",
    answer_1: "",
    answer_2: "",
    answer_3: "",
    answer_4: "",
    explaination: "",
    correct_answer: [],
  };

  const addQuestion = () => {
    if (isQuestionValid()) {
      let questionArray = questions;
      questionArray.push(questionStructure);
      setQuestions([...questionArray]);
    }
  };

  const onQuestionChange = (e, index) => {
    let questionArray = questions;
    questionArray[index].title = e.target.value;
    setQuestions([...questionArray]);
  };

  const onOptionChange = (e, index, optionIndex) => {
    let questionArray = questions;
    switch (optionIndex) {
      case 0: {
        questionArray[index].answer_1 = e.target.value;
        break;
      }
      case 1: {
        questionArray[index].answer_2 = e.target.value;
        break;
      }
      case 2: {
        questionArray[index].answer_3 = e.target.value;
        break;
      }
      case 3: {
        questionArray[index].answer_4 = e.target.value;
        break;
      }
      default: {
      }
    }
    setQuestions([...questionArray]);
  };

  const onExplanationChange = (e, index) => {
    let questionArray = questions;
    questionArray[index].explanation = e.target.value;
    setQuestions([...questionArray]);
  };

  const onOptionSelect = (questionIndex, optionIndex) => {
    let questionArray = questions;
    let { correct_answer } = questionArray[questionIndex];
    // Deselect if already present option
    if (correct_answer.includes(optionIndex + 1)) {
      for (var i = 0; i < correct_answer.length; i++) {
        if (correct_answer[i] === optionIndex + 1) {
          correct_answer.splice(i, 1);
        }
      }
    }
    // Adds new option
    else {
      questionArray[questionIndex].correct_answer.push(optionIndex + 1);
    }
    setQuestions([...questionArray]);
  };

  const onQuestionDelete = (index) => {
    let questionArray = questions;
    questionArray.splice(index, 1);
    setQuestions([...questionArray]);
  };

  const onNext = () => {
    if (isQuestionValid()) {
      updateQuestionSet(questions);
      window.scrollTo(0, 0);
      updateStage(2);
    }
  };

  const isQuestionValid = () => {
    let lastQuestion = questions[questions.length - 1];
    // Checks for empty questions
    if (!lastQuestion.title) {
      toast.error(`Question ${questions.length} incomplete`);
      return false;
    }
    // Check for empty options
    const { answer_1, answer_2, answer_3, answer_4 } = lastQuestion;
    if (!(answer_1 && answer_2 && answer_3 && answer_4)) {
      toast.error(`Question ${questions.length} options incomplete`);
      return false;
    }
    // Checks for correct option marked
    if (lastQuestion.correct_answer.length === 0) {
      toast.error(`Mark correct answer for Question ${questions.length}`);
      return false;
    }
    return true;
  };

  return (
    <div className='sm:py-4 2xl:px-12 font-source-sans-pro'>
      <div className='mb-4'>
        <div className='font-semibold heading3 capitalize mt-11'>
          Enter Question, Options And Tick The Correct Answer
        </div>
        <div className='text-sm text-898989'>
          If you are unable to enter the type of question you want to, please
          email us at support@aankalan.in
        </div>
      </div>
      <QuestionsList
        step={1}
        questions={questions}
        changeQuestionHandler={onQuestionChange}
        changeExplanationHandler={onExplanationChange}
        changeOptionHandler={onOptionChange}
        changeAnswerHandler={onOptionSelect}
        deleteQuestionHandler={onQuestionDelete}
      />
      <div
        className='w-11/12 flex space-x-4 items-center bg-E6D8FF px-4 py-2 mt-14 cursor-pointer rounded-md'
        onClick={addQuestion}>
        <div className='font-semibold flex-centerXY  text-base h-4 w-4 bg-461995 text-white rounded-full'>
          +
        </div>
        <div className='font-semibold text-sm text-black uppercase'>
          Add a new question
        </div>
      </div>
      <div className='flex space-x-6 mt-14 w-full xl:w-1/2'>
        <Button width='w-full lg:w-72' onClick={onNext}>
          NEXT
        </Button>
      </div>
    </div>
  );
};

export default QuizEditor;
