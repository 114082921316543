import React from "react";
import Button from "../../Components/Button";
import Image from "../../Components/Image";
import HeroImage from "../../Assets/Images/Hero-image.svg";
import TecherHeroImage from "../../Assets/Images/Teacher-hero-section.svg";
import ParentHeroImage from "../../Assets/Images/Parent-hero-image.png";
import coachingHeroImage from "../../Assets/Images/coaching-hero-image.svg";
import bgImage from "../../Assets/Images/OvalGradient.svg";
import GooglePlayButton from "../../Components/GooglePlayButton";

import Typewriter from "../../Components/Typewriter";

import { useHistory } from "react-router";

const HeroSection = ({ text, data }) => {
  const history = useHistory();

  let heroImage;
  let HeroImageWidth;
  let HeroImageDivClass;

  switch (history.location.pathname) {
    case "/students":
      heroImage = HeroImage;
      HeroImageDivClass = "justify-center xl:justify-start  2xl:justify-center";
      HeroImageWidth = "w-10/12 2xl:w-9/12";
      break;
    case "/teachers":
      heroImage = TecherHeroImage;
      HeroImageDivClass = "justify-center xl:justify-start  2xl:justify-center";
      HeroImageWidth = "w-10/12 2xl:w-9/12";
      break;
    case "/parents":
      heroImage = ParentHeroImage;
      HeroImageDivClass = "justify-end";
      HeroImageWidth = "w-10/12 xl:w-11/12";

      break;
    case "/coachinginstitutions":
      heroImage = coachingHeroImage;
      HeroImageDivClass = "justify-end";
      HeroImageWidth = "w-10/12 xl:w-full";
      break;
    default:
      heroImage = HeroImage;
  }

  return (
    <div className='xl:h-90vh bg-gradient overflow-hidden text-center xl:text-left'>
      <div className='relative grid grid-cols-10 w-full h-full'>
        <div className='col-span-10 xl:col-span-5 2xl:col-span-4 px-9 xl:px-0 sm:pl-16 xl:pl-28 2xl:pl-36 3xl:pl-44  py-16 xl:py-0 my-auto xl:flex items-center z-20 '>
          <div className='text-white'>
            <div className='font-roboto-slab font-bold text-base sm:text-xl 3xl:text-2xl'>
              Aankalan - The Assessment
            </div>
            <div>
              <div className='font-source-sans-pro text-sm sm:text-xl lg:text-2xl 3xl:text-4xl mt-8 lg:mt-5 '>
                India’s <span className='text-FFA300'> first</span> and only{" "}
                <span className='text-FFA300'>digital assessment platform</span>{" "}
                powered by
              </div>

              <div className='mt-2 lg:mt-5 h-14 md:h-10 lg:h-20 3xl:h-32 text-center xl:text-left'>
                <div className='font-source-sans-pro text-4.5xl lg:text-6xl 3xl:text-7xl text-FFA300 font-black capitalize xl:uppercase '>
                  <Typewriter words={data} />
                </div>
              </div>
              <div className='font-source-sans-pro text-sm sm:text-xl lg:text-2xl 3xl:text-4xl text-white mt-14'>
                {text}
              </div>
              <div className='mt-5'>
                <Button
                  className=''
                  onClick={() => history.push("/home")}
                  bgColor='FFA300'
                  textColor='black'
                  width='w-36 xl:w-60'
                  height='h-12 xl:h-14 3xl:h-16'
                  textTransform='uppercase'>
                  Know More
                </Button>
              </div>
              <div className='mt-8 xl:mt-12 relative z-50 inline-block xl:block'>
                <GooglePlayButton />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-span-10 row-start-2 xl:row-start-auto  xl:col-span-5  2xl:col-span-6 flex items-end ${HeroImageDivClass} z-20`}>
          <Image src={heroImage} height='h-auto' width={HeroImageWidth} />
        </div>
        <div className='hidden lg:block absolute inset-x-1/3 inset-y-1/4 z-10 w-full'>
          <Image
            src={bgImage}
            alt='hero background'
            height='h-auto'
            width='w-5/12'
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
