import React from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "./Logo/Logo";

export const CreateContestHeader = ({
  bgColor = "bg-230D4B",
  logoColor = "FFA200",
  buttonColor = "FFA200",
}) => {
  return (
    <div
      className={`fixed z-30 top-0 left-0 right-0   py-6 md:py-6 4xl:py-10 ${bgColor}  border-b border-FFFFFF lg:border-0  `}>
      <div className='flex items-end justify-between w-full px-9  sm:px-16 xl:px-28 2xl:px-36 3xl:px-44'>
        <Logo color={logoColor} />
        <Link to=''>
          <button
            className={`hidden lg:block border border-${buttonColor} rounded-md font-source-sans-pro text-base xl:text-lg text-center text-${buttonColor} h-9 lg:h-10 w-40 xl:w-44`}>
            Home
          </button>
        </Link>
      </div>
    </div>
  );
};
