import React, { useState } from "react";
import Image from "../../Components/Image";
import regimg from "../../Assets/Images/final-login-Image.png";
import { useHistory } from "react-router";
import Step1 from "./Step1";
import Step2 from "./Step2";

const Loginlayout = () => {
  const [mode, setMode] = useState("STEP1");

  const content = (function contentSelection() {
    switch (mode) {
      case "STEP1":
        return <Step1 changeMode={setMode} />;
      case "STEP2":
        return <Step2 />;
      default:
        return null;
    }
  })();

  return (
    <div className='grid grid-cols-2 h-screen'>
      <div className='xl:block hidden col-span-1'>
        <Image src={regimg} width='w-full' height='h-full' alt='login' />
      </div>
      <div className='xl:col-span-1 col-span-2 flex'>{content}</div>
    </div>
  );
};

export default Loginlayout;
