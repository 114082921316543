import React from "react";
import TableRowLarge from "../../../../Components/Table/TableRowLarge";
import TableHeadLarge from "../../../../Components/Table/TableHeadLarge";
import TableRowSmall from "../../../../Components/Table/TableRowSmall";
// import ScholarshipData from "../../../../Data/Scholarship.json";

let ScholarshipData = require('../../../../Data/Scholarship.json')

const Step3 = ({
  description, data
}) => {
  return (
    <React.Fragment>
      {/* for desktop ------------------------------------------------------------------------------------------------  */}
      <div className='lg:block hidden bg-FAFAFA'>
        <div className='w-8/10-center'>
        <div className='heading3 text-center lg:w-1/2 mx-auto '>
          {description}
        </div>
          <div className='heading text-center mt-24'>
            {data.heading}
          </div>
          <div className='mt-24'>
            <div className='grid grid-cols-12 gap-5'>
              <div className='col-span-4 mt-8'>
                <div className='text-2xl font-roboto-slab font-bold'>
                  {data.description}
                </div>
                
                  {data.statsArray.map((item, index) => {
                    return <div key={index} className='flex items-center mt-6 '>
                      <i class='fas fa-check-circle mr-4 text-461995 text-xl'></i>
                      <div>
                        <div className='text-lg font-source-sans-pro'>
                          {item.heading}
                        </div>
                        <div className='text-xs'>
                          <span className='text-461995'>
                            {item.description}
                          </span>{" "}
                        </div>
                      </div>
                      </div>
                  })}
              </div>
              <div className='col-span-8 bg-white'>
                <div className='text-xl font-roboto-slab font-bold text-center mt-8 uppercase'>
                  Scholarship LEADERSHIPBOARD
                </div>
                <div className='p-6 3xl:p-12'>
                  {/* table-heading */}
                  <TableHeadLarge />
                  {ScholarshipData["data"].map((student, index) => (
                    <TableRowLarge
                      image={student.image}
                      key={index}
                      index={index}
                      name={student.name}
                      examTaken={student.examTaken}
                      scholarship={student.cash}
                      city={student.city}
                      rank={student.rank}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full h-60 bg-E9E9FF -mt-48 lg:block hidden'></div>
      {/* for phone ------------------------------------------------------------------------------------------------  */}
      <div className='lg:hidden block bg-E9E9FF '>
        <div className=' w-8/10-center'>
          <div className='heading3 text-center'>
            {description}
          </div>
          <div className='mt-8'>
            <div className='heading text-center uppercase'>
              CASH AWARDS LEADERSHIPBOARD
            </div>
            <div className='mt-6'>
              {ScholarshipData["data"].map((student, index) => (
                <TableRowSmall
                  image={student.image}
                  key={index}
                  index={index}
                  date={student.date}
                  name={student.name}
                  examTaken={student.examTaken}
                  scholarship={student.cash}
                  city={student.city}
                  rank={student.rank}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default Step3;
